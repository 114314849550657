import React from 'react';
import styled from 'styled-components';
import { isEqual, set } from 'lodash-es';
import { useAppSelector, useAppDispatch } from '../../../store';

import { DataElementContext } from '../../../page-components/common/DataElementContext';
import { getStateValue } from '../../../page-components/utils/getStateValue';
import { claimBonus, getBonusAvailable } from '../../../modules/casino/store/actions/application';
import { requestRemoveBonus, requestWallet } from '../../../modules/casino/store/actions/wallet';
import { freeBetRemove, freeBetsFetch } from '../../../modules/casino/store/actions/free_bets';
import { freeSpinsFetch } from '../../../modules/casino/store/actions/free_spins';
import { loadPrizes, claimPrizeById, claimPrizeByIdLoaded } from '@/modules/tournaments-missions/store/actions/history';
import { getMomentumBonuses, forfeitMomentumBonus } from '@/modules/momentum/store/actions/momentum';
import * as actionTypes from '@/modules/casino/store/actions/actionTypes.js';

import './index.scss';

type WalletBonusesProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: {
    dsType: string;
  };
};

const defaultProps = {
  className: '',
  styleText: '',
  properties: {
    dsType: '',
  },
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const WalletBonuses = (componentProps: WalletBonusesProps) => {
  const tmpProps = { ...defaultProps, ...componentProps };
  delete tmpProps.children;
  const props = JSON.parse(JSON.stringify(tmpProps));
  const { children } = componentProps;

  const dispatch = useAppDispatch();

  const {
    activeBonuses,
    pendingBonuses,
    availableBonuses,
    pendingBonusesLoader,
    bonusClaimError,
    activeBonusesLoader,
    loadingClaimBonus,
    prizes,
    loadingPrizes,
    claimPrizeByIdError,
  } = useAppSelector<{
    activeBonuses: any[];
    pendingBonuses: any[];
    availableBonuses: any | undefined | null;
    pendingBonusesLoader: any;
    activeBonusesLoader: any;
    bonusClaimError: any;
    loadingClaimBonus: boolean;
    prizes: any[];
    loadingPrizes: boolean;
    claimPrizeByIdError: boolean;
  }>(
    (state) => ({
      activeBonuses: getStateValue(state, 'state.activeBonuses'),
      pendingBonuses: getStateValue(state, 'state.pendingBonuses'),
      availableBonuses: getStateValue(state, 'state.application.availableBonuses'),
      pendingBonusesLoader: getStateValue(state, 'state.pendingBonuses.loader'),
      activeBonusesLoader: getStateValue(state, 'state.activeBonuses.loader'),
      bonusClaimError: state.application.bonusClaimError,
      loadingClaimBonus: state.application.loadingClaimBonus,
      prizes: state.tournamentsMissions.history.prizes,
      loadingPrizes: state.tournamentsMissions.history.loadingPrizes,
      claimPrizeByIdError: state.tournamentsMissions.history.claimPrizeByIdError,
    }),
    isEqual,
  );

  const momentumPrize = useAppSelector<any>((state) => state.momentum.prize.value);
  const momentumLoaded = useAppSelector((state) => state.momentum.prize.loaded);
  const authentication = useAppSelector((state) => state.authentication);

  const [disableButtons, setDisableButtons] = React.useState(true);

  React.useEffect(() => {
    dispatch(getBonusAvailable());
    dispatch(loadPrizes(null));
    dispatch(requestWallet());
    dispatch(freeSpinsFetch());
    dispatch(freeBetsFetch());
    setDisableButtons(false);
    if (!momentumLoaded && ['user', 'token'].indexOf(authentication.auth_type) > -1) {
      dispatch(getMomentumBonuses());
    }
  }, []);

  const [isDialogOpen, setIsDialogOpen] = React.useState({
    open: false,
    deleteId: '',
    deleteType: '',
  });

  const [isActivateDialogOpen, setIsActivateDialogOpen] = React.useState(false);

  const getElementToActivate = (e: any) => {
    if (!e) return;
    e.preventDefault();
    e.stopPropagation();

    const bonus = e.target.closest('.activate-pending-bonus-action-button');

    if (bonus && bonus.dataset) {
      dispatch({
        type: actionTypes.application.RESET_BONUS_CLAIM_STATUS,
      });
      // console.log('bonus.dataset', bonus.dataset);

      setIsActivateDialogOpen(true);
      switch (bonus.dataset.type.toString()) {
        case 'bonus':
          dispatch(claimBonus(bonus.dataset.bonusid, true));
          break;
        case 'prize':
          // console.log('DISPATCH CLAIM PRIZE', bonus.dataset.bonusid);
          dispatch(claimPrizeById({ id: bonus.dataset.bonusid }));
          break;
        // TODO: add more cases if needed
        default:
          console.error('Unknown delete type', bonus);
      }
    } else {
      setIsActivateDialogOpen(false);
      dispatch(claimPrizeByIdLoaded());
    }
  };

  const getElementToRemove = (e: any) => {
    if (!e) return;
    e.preventDefault();

    // const testElement = getDOMElementByClassName('delete-active-bonus-action-button');
    const bonus = e.target.closest('.delete-active-bonus-action-button');
    // @ts-ignore
    if (bonus && bonus.dataset) {
      // @ts-ignore
      setIsDialogOpen((ps) => ({
        ...ps,
        open: !ps.open,
        deleteId: bonus.dataset.bonusid,
        deleteType: bonus.dataset.type,
      }));
    } else {
      setIsDialogOpen((ps) => ({ ...ps, open: false, deleteId: '', deleteType: '' }));
    }
  };

  const onDeleteBonus = (e: any) => {
    e.preventDefault();

    if (isDialogOpen.deleteType && isDialogOpen.deleteId) {
      setDisableButtons(true);
      switch (isDialogOpen.deleteType.toString()) {
        case '2':
          dispatch(requestRemoveBonus(isDialogOpen.deleteId));
          break;
        case '9':
        case '5':
          dispatch(freeBetRemove(isDialogOpen.deleteId));
          break;
        case '1000':
          console.log('delete bonus', isDialogOpen.deleteId);
          dispatch(forfeitMomentumBonus(isDialogOpen.deleteId ?? 0));
          break;
        // TODO: add more cases if needed
        default:
          console.error('Unknown delete type', isDialogOpen.deleteType);
        // setLoading(true);
      }
    }

    setIsDialogOpen((ps) => ({ ...ps, open: false, deleteId: '', deleteType: '' }));
  };

  React.useEffect(() => {
    setDisableButtons(false);
  }, [activeBonusesLoader.loading]);

  const contextValue = React.useMemo(() => {
    let ab = activeBonuses;
    if (momentumPrize?.active) {
      let prizeExpired = false;
      if (momentumPrize?.bonus_expires_at) {
        const now = new Date().valueOf();
        const prizeExpiresAt = new Date(momentumPrize?.bonus_expires_at).valueOf();
        if (now - prizeExpiresAt > 0) prizeExpired = true;
      }
      if (!prizeExpired) {
        let wager_target = momentumPrize?.wager_target ?? 0;
        wager_target = wager_target / 100;
        let progress = momentumPrize?.wager_progress ?? 0;

        progress = (progress * wager_target) / 100;
        progress = parseFloat(progress.toFixed(2));

        if (progress > wager_target) progress = wager_target;

        ab = [
          {
            type: 'bonus',
            bonus: {
              element_type: 'inner',
              data: {
                logo: 'https://micros-t.b-cdn.net/turbo-wins-1715922446297.png',
                id: momentumPrize?.uuid,
                eligibleProducts: [],
                amountGranted: (momentumPrize?.reward?.value ?? 0) / 100,
                amount: (momentumPrize?.reward?.value ?? 0) / 100,
                name: momentumPrize?.display_name,
                wager: progress,
                wagerTarget: momentumPrize?.wager_target / 100,
                startDate: new Date(momentumPrize?.created_at).valueOf() / 1000,
                endDate: new Date(momentumPrize?.bonus_expires_at).valueOf() / 1000,
                type: 1000,
                cms_data: {
                  terms_url: '/promotions/7f712d82-831d-48c7-b6e8-bb2a03afa536',
                },
                delete_action: 'activeBonuses.deleteBonus',
              },
            },
          },
          ...ab,
        ];
      }
    }

    return {
      activeBonuses: ab,
      pendingBonuses,
      availableBonuses,
      isDialogOpen: isDialogOpen.open,
      deleteId: isDialogOpen.deleteId,
      deleteType: isDialogOpen.deleteType,
      toggleDialog: getElementToRemove,
      onDeleteBonus,
      bonusClaimError: bonusClaimError, //|| claimPrizeByIdError,

      // isActivateDialogOpen: isActivateDialogOpen && !loadingClaimBonus,
      isActivateDialogOpen: isActivateDialogOpen,
      toggleActivateDialog: getElementToActivate,
      loading: loadingClaimBonus || loadingPrizes,
      pendingBonusesLoading: pendingBonusesLoader.loading,
      activeBonusesLoading: activeBonusesLoader.loading,
      disableRemoveBonus: disableButtons || activeBonusesLoader.loading,

      // prizes,
      loadingPrizes,
      claimPrizeByIdError,
    };
  }, [
    momentumPrize,
    activeBonuses,
    pendingBonuses,
    availableBonuses,
    isDialogOpen,
    onDeleteBonus,
    bonusClaimError,
    isActivateDialogOpen,
    getElementToActivate,
    getElementToRemove,
    loadingClaimBonus,
    pendingBonusesLoader.loading,
    activeBonusesLoader.loading,
    disableButtons,

    prizes,
    loadingPrizes,
    claimPrizeByIdError,
  ]);

  console.log('WalletBonuses', {
    contextValue,
    prizes,
    pendingBonuses,
    // loadingPrizes,
    // claimPrizeByIdError,
    // loading: loadingClaimBonus || loadingPrizes,
    // bonusClaimError,
    // loadingClaimBonus,
    // pBL: pendingBonusesLoader.loading,
    // aBL: activeBonusesLoader.loading,
    // disableRemoveBonus: disableButtons || pendingBonusesLoader.loading,
    // formatedPrizes,
  });
  // console.log('WalletBonuses[contextValue]', contextValue);

  return (
    <ModuleElementDiv className={props.className ?? ''} $styleText={props.styleText}>
      <DataElementContext.Provider value={contextValue}>{children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default WalletBonuses;

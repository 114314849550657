import * as actionTypes from './../actions/actionTypes';
import { listsMap } from './../../utils/GamesListTypes';

const initialState = {
  listType: 0,
  items: {
    all: [],
    new: [],
    hot: [],
    favorites: [],
    promoted: [],
    jackpot: [],
    recent: [],
    empty: [],
  },
  showLoading: false,
  showGames: false,
  openGameId: null,
  gameLauncher: false,
  gameURL: null,
  activeGame: false,
  gameCollections: null,
  loadingGameCollections: false,
  gameLaunchError: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.games.SHOW_LOADING:
      return {
        ...state,
        showLoading: true,
      };
    case actionTypes.games.HIDE_LOADING:
      return {
        ...state,
        showLoading: false,
      };
    case actionTypes.games.REQUEST_GAMES_LIST:
      return {
        ...state,
        showGames: false,
        listType: 0,
        loadingGamesList: true,
      };
    case actionTypes.games.RECEIVED_GAMES_LIST:
      let items = state.items;
      let itemsKey = listsMap[action.listType];
      items[itemsKey.param] = action.games;
      return {
        ...state,
        listType: action.listType,
        items: items,
        showGames: true,
        loadingGamesList: false,
      };
    case actionTypes.games.SET_LIST_TYPE:
      return {
        ...state,
        listType: action.listType,
      };
    case actionTypes.games.SET_GAME_OBJECT:
      return {
        ...state,
        activeGame: action.value,
      };
    case actionTypes.games.SET_GAME_LAUNCHER:
      let gameId = state.openGameId;
      // let gameUrl = state.gameURL;
      if (!action.gameLauncher) {
        gameId = null;
        // gameUrl = null;
      }
      return {
        ...state,
        gameLauncher: action.gameLauncher,
        openGameId: gameId,
        // gameURL: gameUrl,
      };
    case actionTypes.games.SET_GAME_ID:
      return {
        ...state,
        openGameId: action.gameId,
      };
    case actionTypes.games.RECEIVED_GAME_URL:
      return {
        ...state,
        gameURL: action.gameURL,
        gameLaunchError: action.gameLaunchError,
      };
    case actionTypes.games.REQUEST_GAME_COLLECTIONS:
      return {
        ...state,
        loadingGameCollections: true,
      };
    case actionTypes.games.RECEIVED_GAME_COLLECTIONS:
      let data = [];
      if (action.data && Object.keys(action.data).length !== 0 && action.data.constructor === Object) {
        Object.values(action.data).forEach((item, index) => {
          data[index] = item;
        });
      }
      return {
        ...state,
        loadingGameCollections: false,
        gameCollections: data,
      };
    default:
      return state;
  }
};

export default reducer;

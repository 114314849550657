/*
type CurrencyArtBundleImage = {
  name: string;
  value: string;
};

type CurrencyArtBundleTexts = {
  text_display_name: string | { [key: string]: string };
  text_display_name_short: string | { [key: string]: string };
};

type CurrencyArtBundle = {
  attributes?: any[];
  images?: CurrencyArtBundleImage[];
  texts?: CurrencyArtBundleTexts[];
  text_display_name: CurrencyArtBundleTexts;
  text_display_name_short: CurrencyArtBundleTexts;
  small_square_ar11_icon_transparent: string;
};

type CurrencyProps = {
  description: string;
  currency_code: string;
  metadata: any | null | undefined;
  updated_at: string;
  icon_position: string;
  format: string;
  class_type: string;
  art_bundle: CurrencyArtBundle[];
};

type PrizeProps = {
  id: number;
  claim_end_time: string;

  reward_class_type: string;
  reward_type: string;
  reward_currency: string;
  reward_value: number;

  reward_metadata: any | null | undefined;
  meta: any | null | undefined;

  art_bundle: any[];
  currency: CurrencyProps;
};
*/

const prizeCurrencyFormat = (currency, reward_value) => {
  // const currency = prize.currency;

  if (!currency?.format) return '';

  const arb = currency?.art_bundle[0];

  if (!arb) return '';

  // console.log('prizeCurrencyFormat', { currency, arb, prize });

  switch (currency.format) {
    case '%v %n': {
      return {
        currency: arb.text_display_name,
        value: reward_value,
        valueOrder: 1,
        currencyOrder: 2,
      };
    }
    case '%n %v': {
      return {
        currency: arb.text_display_name,
        value: reward_value,
        valueOrder: 2,
        currencyOrder: 1,
      };
    }

    case '%v%n': {
      return {
        currency: arb.text_display_name_short,
        value: reward_value,
        valueOrder: 1,
        currencyOrder: 2,
      };
    }
    case '%n%v': {
      return {
        currency: arb.text_display_name_short,
        value: reward_value,
        valueOrder: 2,
        currencyOrder: 1,
      };
    }

    default:
      return {
        currency: '',
        value: reward_value,
        valueOrder: 2,
        currencyOrder: 1,
      };
  }
};

export default prizeCurrencyFormat;

import React from 'react';
import styled from 'styled-components';
import { useMatches, useNavigate } from 'react-router-dom';

import { useAppSelector, useAppDispatch } from '../../../store';
import { DataElementContext } from '../../../page-components/common/DataElementContext';

import {
  Challenge,
  fetchChallenges,
  claimChallenge,
  enrollChallenge,
  cancelChallenge,
  resetChallengeState,
} from '@/store/slices/challenges';

import { gameIdsToObjects, stringToSlug } from '@/utils/gameIdsToObjects';
import fetchGamesInfo from '@/utils/games-info-api-cache';
import { filtersToLinks } from '@/components/modules/tournament-group/assets/utils';

type ChallengesProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: any;
};

type ModuleMissionProps = {
  id: string;
  playerMissionId: string;
  enrolled: boolean;
  name: { [lang: string]: string };
  description: { [lang: string]: string };
  currentProgress: string | null | undefined;
  targetProgress: string | null | undefined;
  displayCurrency: string | null | undefined;
  startDate: number;
  endDate: number;
  status: string;
  missionName: string;
  prizeValue: number | string;
  cornerImage: string;
  rules: { [lang: string]: string };
  extendedRules: { [lang: string]: string };
  objectivesImage: string;

  challengeScope: any[string];
  displayProgressAs: string;

  games?: any;
  recommended?: any;
  sportFilters?: any;

  progressString?: { [progressVal: string]: string; progressCurrency: string };
};

type ModuleStatePropsList = ModuleMissionProps[];

const defaultProps = {
  className: '',
  styleText: '',
  properties: '',
};

type DialogOpen = {
  open: boolean;
  missionId: string | undefined | null;
};

type ClaimDialogOpen = {
  open: boolean;
  missionId: string | undefined | null;
  prizeName: string | undefined | null;
  prizeValue: string | undefined | null;
  prizeCurrency: string | undefined | null;
  prizeCornerImage: string | undefined | null;
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const formatProgressString = (
  current: string | undefined,
  target: string | undefined,
  displayAs: string,
  currency: string,
) => {
  let cp = 0;
  let tp = 100;
  if (current) cp = parseFloat(current);

  if (target) tp = parseFloat(target);

  // const percent = Math.round((cp / tp) * 100);
  const percent = (cp / tp) * 100;

  if (isNaN(percent)) {
    return { progressVal: '0', progressCurrency: ` / ${tp} ${currency}` };
  }

  // console.log('DEBUG formatProgressString:', { current, target, displayAs, percent, cp, tp });

  switch (displayAs) {
    case 'PERCENT_0':
      return { progressVal: `${Math.floor(percent).toFixed(0)}`, progressCurrency: '%' };
    case 'PERCENT_1':
      return {
        progressVal: `${Math.floor(percent).toFixed(1)}`,
        progressCurrency: '%',
      };

    case 'PERCENT_2':
      return {
        progressVal: `${Math.floor(percent).toFixed(2)}`,
        progressCurrency: '%',
      };
    case 'ABSOLUTE':
      return { progressVal: `${cp}`, progressCurrency: ` / ${tp} ${currency ?? ''}` };
    default:
      return { progressVal: `${cp}`, progressCurrency: ` / ${tp} ${currency ?? ''}` };
  }
};

const formatChallenge = (mission: Challenge): ModuleMissionProps => {
  const id = mission.id + '';
  // if (mission.player_mission_id) id = mission.player_mission_id + '';

  return {
    id,
    playerMissionId: String(mission.player_mission_id ?? ''),
    enrolled: mission.enrolled,
    name: mission.meta.ui.title.text,
    description: mission.meta.ui.description.text,
    currentProgress: mission.value,
    targetProgress: mission.max_value,
    displayCurrency: mission.prize.prize_display_currency,
    startDate: new Date().getTime(),
    endDate: mission.complete_date_limit
      ? new Date(mission.complete_date_limit).getTime()
      : mission.end_date
        ? new Date(mission.end_date).getTime()
        : 0,
    status: mission.status,
    missionName: mission.name ?? '',
    prizeValue: mission.prize.prize_value ?? '',
    cornerImage: mission.meta.ui.corner_image.url ?? '',
    rules: mission.meta.ui.rules.text ?? '',
    extendedRules: mission.meta.ui.extended_rules.text ?? '',
    objectivesImage: mission.meta.ui.objectives_image.url ?? '',

    challengeScope: mission.challenge_scope ?? [],
    displayProgressAs: mission.display_progress_as ?? '',
    games: mission.meta.games ?? [],
    recommended: mission.meta.recommended ?? [],
    sportFilters: mission.meta.sport_filters ?? {},

    progressString: formatProgressString(
      mission.value,
      mission.max_value,
      mission.display_progress_as ?? 'ABSOLUTE',
      mission.prize.prize_display_currency,
    ),
  };
};

const Challenges = (componentProps: ChallengesProps) => {
  const tmpProps = { ...defaultProps, ...componentProps };
  delete tmpProps.children;
  const props = JSON.parse(JSON.stringify(tmpProps));

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const uriMatches = useMatches();

  const authentication = useAppSelector((state) => state.authentication);
  const challenges = useAppSelector((state) => state.challenges.challenges);
  // const loading = useAppSelector((state) => state.challenges.loading);
  // const error = useAppSelector((state) => state.challenges.error);
  // const claimedChallengeActivated = useAppSelector((state) => state.challenges.claimedChallengeActivated);

  const { loading, error, claimedChallengeActivated } = useAppSelector((state) => state.challenges);

  const [state, setState] = React.useState<ModuleStatePropsList>([]);

  // confirm cancel mission dialog
  const [isDialogOpen, setIsDialogOpen] = React.useState<DialogOpen>({
    open: false,
    missionId: '',
  });
  // claim prize dialog
  const [isClaimDialogOpen, setIsClaimDialogOpen] = React.useState<ClaimDialogOpen>({
    open: false,
    missionId: '',
    prizeName: '',
    prizeValue: '',
    prizeCurrency: '',
    prizeCornerImage: '',
  });

  React.useEffect(() => {
    if (window?.config?.challengesEnabled === '1') {
      dispatch(fetchChallenges());
    }
  }, [isDialogOpen, isClaimDialogOpen]);

  let challengeId = props.properties.targetId;

  if (props.properties.targetIdFromPath && props.properties.pathParamKey) {
    if (uriMatches && uriMatches.length) {
      const match = uriMatches[0];
      if (match.params && match.params[props.properties.pathParamKey] != null) {
        challengeId = match.params[props.properties.pathParamKey];
      }
    }
  }

  const [detailsMission, setDetailsMission] = React.useState<ModuleMissionProps | null>(null);

  React.useEffect(() => {
    if (challengeId) {
      const mission = state.find((m) => m.id === challengeId) ?? null;

      if (mission && (!detailsMission || mission.id !== detailsMission?.id)) setDetailsMission(mission);
      if (
        mission &&
        (!detailsMission ||
          mission.id !== detailsMission?.id ||
          (mission.id === detailsMission?.id && !detailsMission.playerMissionId))
      )
        setDetailsMission(mission);
    }
  }, [challengeId, state, detailsMission, uriMatches, challenges, loading]);

  React.useEffect(() => {
    setState(challenges.map(formatChallenge));
  }, [challenges, loading]);

  const checkIfLogin = () => {
    // check if uer is logged in. if not redirect to the login page (with redirect back configured)
    if (['user', 'token'].indexOf(authentication.auth_type) === -1 || authentication.access_token === null) {
      navigate('/login');
      return true;
    }
    return false;
  };

  // get all possible games
  const gameIds: any[] = React.useMemo(() => {
    const gameIds: any = {};
    challenges.forEach((c: any) => {
      c.meta?.games?.forEach?.((g: any) => {
        gameIds[g] = true;
      });

      c.meta?.recommended?.forEach?.((g: any) => {
        gameIds[g] = true;
      });
    });

    return Object.keys(gameIds).sort();
  }, [challenges]);

  // ALL GAMES
  const {
    data: { data: allChallengesGames },
  } = fetchGamesInfo({
    dsId: window.config.dataSourceAllGames,
    gameIds: gameIds,
    authenticationToken: authentication?.access_token,
  });

  const allTournamentRecommandedGames: any = [];
  if (allChallengesGames?.length) {
    const hashIds: any = {};
    allChallengesGames.forEach((g: any, index: number) => {
      hashIds[g.id] = index;
    });

    detailsMission?.recommended.forEach((id: number) => {
      if (hashIds[id.toString()] != null)
        allTournamentRecommandedGames.push(allChallengesGames[hashIds[id.toString()]]);
    });
  }

  const playGame = (missionId: any) => {
    const mission = state.find((m) => m.id === missionId || m.playerMissionId === missionId) ?? null;

    // console.log('RUNNING playGame:', { missionId, mission, allChallengesGames });

    if (!mission) return;

    let gameId: any = null;
    let foundGameId: any = null;

    // get a recomended game if available
    if (mission.recommended && mission.recommended.length) {
      gameId = mission.recommended[Math.floor(Math.random() * mission.recommended.length)];

      foundGameId = mission.recommended?.find((g: any) => {
        if (g.toString() === gameId.toString()) return true;
        return false;
      });
    }
    // if no recomended, get a game from the mission
    if (!gameId && mission.games && mission.games.length) {
      gameId = mission.games[Math.floor(Math.random() * mission.games.length)];

      foundGameId = mission.games?.find((g: any) => {
        if (g.toString() === gameId.toString()) return true;
        return false;
      });
    }

    if (foundGameId) {
      let found = null;

      if (!allChallengesGames) return;
      found = allChallengesGames.find((g: any) => g.id.toString() === foundGameId?.toString()) ?? null;

      if (found) {
        navigate(`/slot-game/${gameId}/${stringToSlug(found.name)}`);
      }
    }
  };

  const handleJoinPlayAndGame = (e: React.MouseEvent<HTMLElement>) => {
    let id: string | undefined;

    const target = e.currentTarget;
    if (target) id = target.dataset.id;
    if (id == null) return;

    e.stopPropagation();
    e.preventDefault();

    if (checkIfLogin()) return;

    const mission = state.find((m) => m.id === id || m.playerMissionId === id) ?? null;

    console.log('RUNNING handleJoinPlayAndGame:', { e, id, mission });

    if (!mission?.enrolled) {
      dispatch(enrollChallenge({ mission_id: id }));
      return;
    } else {
      // change behavior depending on the challenge scope
      // challengeScope = multi-selection from {GENERIC, CASINO, SPORT, LOTTO, DEPOSIT, ACCOUNT}
      if (Array.isArray(mission.challengeScope) && mission.challengeScope.length === 0) {
        navigate('/deposit');
        return;
      } else {
        // console.log('RUNNING ENROLLED:', { e, mission, id, challengeScope: mission.challengeScope });
        if (mission.challengeScope.includes('DEPOSIT') || mission.challengeScope.includes('GENERIC')) {
          navigate('/deposit');
          return;
        }

        if (mission.challengeScope.includes('CASINO')) {
          playGame(id);
          return;
        }

        if (mission.challengeScope.includes('SPORT')) {
          navigate('/bets/pre-match');
          return;
        }

        if (mission.challengeScope.includes('LOTTO')) {
          navigate('/lotto');
          return;
        }

        if (mission.challengeScope.includes('ACCOUNT')) {
          navigate('/profile/home');
          return;
        }

        // playGame(id);
      }
    }
  };

  const handleQuitMission = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    e.preventDefault();

    let id: string | undefined;
    const target = e?.currentTarget ?? null;
    if (target) id = target.dataset.id;

    if (checkIfLogin()) return;

    if (id) {
      setIsDialogOpen((ps) => ({
        ...ps,
        open: !ps.open,
        missionId: id,
      }));
    } else {
      setIsDialogOpen((ps) => ({ ...ps, open: false, missionId: '' }));
    }
  };

  const handleConfirmQuitMission = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    e.preventDefault();

    let id: string | undefined;
    const target = e?.currentTarget ?? null;
    if (target) id = target.dataset.id;

    if (checkIfLogin()) return;

    if (id && id === isDialogOpen.missionId) {
      // console.log('Cancel mission:', { id, isDialogOpen });
      dispatch(cancelChallenge({ player_mission_id: id }));
    }

    setIsDialogOpen((ps) => ({ ...ps, open: false, missionId: '' }));
    setDetailsMission(null);
    navigate('/profile/home');
  };

  const handleCancelQuitMission = (e: React.MouseEvent<HTMLElement>) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }

    setIsDialogOpen((ps) => ({ ...ps, open: false, missionId: '' }));
  };

  const handleClaimMission = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    e.preventDefault();

    let id: string = '';
    const target = e?.currentTarget ?? null;
    if (target) id = target.dataset.id || '';

    if (!id) return;
    if (checkIfLogin()) return;

    if (id) {
      const mission = state.find((m) => m.id === id || m.playerMissionId === id) ?? '';

      if (!mission) return;

      // make dispatch here
      dispatch(claimChallenge({ player_mission_id: id }));

      // @ts-ignore
      setIsClaimDialogOpen((ps) => ({
        ...ps,
        open: !ps.open,
        missionId: id,
        prizeName: mission.name ?? '',
        prizeValue: mission.prizeValue ?? '',
        prizeCurrency: mission.displayCurrency ?? '',
        prizeCornerImage: mission.cornerImage ?? '',
      }));
    } else {
      setIsClaimDialogOpen((ps) => ({
        ...ps,
        open: false,
        missionId: '',
        prizeName: '',
        prizeValue: '',
        prizeCurrency: '',
        prizeCornerImage: '',
      }));
    }
  };

  const handleConfirmClaimMission = (e: React.MouseEvent<HTMLElement>) => {
    // console.log('DEBUG running handleConfirmClaimMission:', { e, isClaimDialogOpen });

    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }

    let id: string | undefined;
    let type: string | undefined;
    const target = e?.currentTarget ?? null;
    if (target) {
      id = target.dataset.id;
      type = target.dataset.type;
    }

    setIsClaimDialogOpen((ps) => ({
      ...ps,
      open: false,
      missionId: '',
      prizeName: '',
      prizeValue: '',
      prizeCurrency: '',
      prizeCornerImage: '',
    }));

    let rep = false;
    if (type) {
      if (uriMatches[uriMatches.length - 1].pathname.includes('/challenges/')) rep = true;

      dispatch(resetChallengeState());
      navigate('/profile/wallet', { replace: rep });
    } else {
      dispatch(resetChallengeState());
      navigate('/profile/home', { replace: rep });
    }
  };

  const getEnrolledMissions = () => {
    if (!Array.isArray(state)) return [];

    // const em = state.filter((m) => m.enrolled);
    const em = [...state];

    // sort by completed first and next depends on progress
    em.sort((a: any, b: any) => {
      if (b.status === 'completed') return 1;

      if (!isNaN(parseFloat(a.currentProgress)) && !isNaN(parseFloat(b.currentProgress))) {
        return parseFloat(b.currentProgress) - parseFloat(a.currentProgress);
      } else if (isNaN(parseFloat(a.currentProgress)) && !isNaN(parseFloat(b.currentProgress))) {
        return 1;
      }
      return -1;
    });

    return em;
  };

  const enrolledMissions = getEnrolledMissions();

  const getDisplayLayout = (uriMatches: any) => {
    // we have 3 main layouts: FULL_LIST, DETAILS_PAGE, INDIVIDUAL_ITEM
    // INDIVIDUAL_ITEM is used in Profile Home page
    // FULL_LIST is used in Challenges page
    // DETAILS_PAGE is used in Challenge Details page
    if (uriMatches && uriMatches.length) {
      const uriMatch = uriMatches[uriMatches.length - 1];

      if (uriMatch && uriMatch.pathname) {
        if (uriMatch.pathname.includes('/challenge')) {
          if (uriMatch.pathname.includes('/challenges/')) {
            return 'DETAILS_PAGE';
          }
          return 'FULL_LIST';
        }

        if (uriMatch.pathname.includes('/profile') || uriMatch.pathname.includes('/home')) {
          return 'INDIVIDUAL_ITEM';
        }
      }

      return 'INDIVIDUAL_ITEM';
    }
    return 'INDIVIDUAL_ITEM';
  };

  const contextValue = React.useMemo(() => {
    if (window?.config?.challengesEnabled !== '1') return null;

    return {
      items: state,
      enrolledMissions, // sorted state
      // displayed in Profile Home
      primaryMission:
        enrolledMissions.length > 0 ? enrolledMissions[0] : Array.isArray(state) && state.length > 0 ? state[0] : null,

      // handleJoinMission,

      handleQuitMission,
      handleConfirmQuitMission,
      handleCancelQuitMission,
      isDialogOpen: isDialogOpen.open,
      cancelMissionId: isDialogOpen.missionId,

      handleClaimMission,
      handleConfirmClaimMission,
      isClaimDialogOpen: isClaimDialogOpen.open,
      claimMissionId: isClaimDialogOpen.missionId,
      claimPrizeName: isClaimDialogOpen.prizeName,
      claimPrizeValue: isClaimDialogOpen.prizeValue,
      claimPrizeCurrency: isClaimDialogOpen.prizeCurrency,
      claimPrizeCornerImage: isClaimDialogOpen.prizeCornerImage,

      // handleViewMissionDetails,
      // handleCloseMissionDetails,

      handleJoinPlayAndGame,
      // detailsMission,
      detailsMission: detailsMission
        ? {
            ...detailsMission,
            games:
              detailsMission && detailsMission.games && detailsMission.games.length
                ? gameIdsToObjects(allChallengesGames, detailsMission.games, handleJoinPlayAndGame)
                : [],
            recommended:
              detailsMission && detailsMission.recommended && detailsMission.recommended.length
                ? gameIdsToObjects(allTournamentRecommandedGames, detailsMission.recommended, handleJoinPlayAndGame)
                : [],
            links:
              (detailsMission?.sportFilters?.prematch &&
                detailsMission?.sportFilters?.prematch.length > 0 &&
                filtersToLinks(detailsMission.sportFilters.prematch, handleJoinPlayAndGame)) ??
              [],
          }
        : null,

      displayLayout: getDisplayLayout(uriMatches),

      loadingChallenges: loading,
      errorChallenges: error,
      claimedChallengeActivated,
    };
  }, [
    state,
    componentProps,
    isDialogOpen,
    isClaimDialogOpen,
    detailsMission,
    allChallengesGames,
    handleJoinPlayAndGame,
    uriMatches,
    loading,
    error,
    claimedChallengeActivated,
  ]);

  if (!contextValue) return null;

  console.log('Challenges contextValue:', {
    contextValue,
    props,
    state,
    challenges,
    loading,
    // isDialogOpen,
    // isClaimDialogOpen,
    // challangesEnabled: window.config.challengesEnabled ?? false,
    // uriMatches,
    // challengeId,
    detailsMission: detailsMission,
    allChallengesGames,
    error,
    claimedChallengeActivated,
  });

  return (
    <ModuleElementDiv className={componentProps.className ?? ''} $styleText={componentProps.styleText}>
      <DataElementContext.Provider value={contextValue}>{componentProps.children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default Challenges;

import { takeEvery, takeLatest, put } from 'redux-saga/effects';
import axios from 'axios';

import {
  freeBetsLoaded,
  freeBetsFetch,
  freeBetsLoadedHistory,
  freeBetsFiltersLoaded,
  freeBetsLoading,
} from '../actions/free_bets';
import { resetOneRequest } from '../actions';
import { authentication, freeBets, application } from '../actions/actionTypes';
import ClientAPI from './../../ClientAPI/ClientAPI';
import { getBonusAvailable } from '../../common/claim-bonus-exports';

import { endpoints } from '@/api/endpoints';

let apiUrl;

if (window.config.useLocalApi && import.meta.env.MODE === 'development') {
  apiUrl = 'http://localhost:3001/betsapi';
} else {
  apiUrl = window.config.betsApiUrl + '/betsapi';
}

export function* playerApi(requireAuth, method, url, params) {
  const headers = {};

  if (requireAuth) {
    if (requireAuth === true) {
      const state = ClientAPI.getStore().getState();

      //console.log("state", state);

      const { authentication } = state;

      if (!(authentication && ['user', 'token'].indexOf(authentication.auth_type) > -1)) {
        throw Error('not authenticated');
      }

      headers['Authorization'] = 'Bearer ' + authentication.access_token;
    } else {
      headers['Authorization'] = requireAuth;
    }
  }

  if (method === 'GET') {
    let qp = {
      headers,
    };
    if (params) {
      qp = {
        ...qp,
        params,
      };
    }
    return yield axios.get(apiUrl + url, qp);
  }

  return yield axios.post(apiUrl + url, params, { headers });
}

export function* playerApiWOUrl(requireAuth, method, url, params) {
  const headers = {};

  if (requireAuth) {
    if (requireAuth === true) {
      const state = ClientAPI.getStore().getState();

      //console.log("state", state);

      const { authentication } = state;

      if (!(authentication && ['user', 'token'].indexOf(authentication.auth_type) > -1)) {
        throw Error('not authenticated');
      }

      headers['Authorization'] = 'Bearer ' + authentication.access_token;
    } else {
      headers['Authorization'] = requireAuth;
    }
  }

  if (method === 'GET') {
    let qp = {
      headers,
    };
    if (params) {
      qp = {
        ...qp,
        params,
      };
    }
    return yield axios.get(url, qp);
  }

  return yield axios.post(url, params, { headers });
}

export const fetchFreeBetsDumpHistory = () => {
  const axios = ClientAPI.getInstance();
  const store = ClientAPI.getStore();
  const state = store.getState();

  if (['user', 'token'].indexOf(state.authentication.auth_type) === -1 || state.authentication.access_token === null) {
    return new Promise((resolve, reject) => {
      reject(new Error('Not Authenticated'));
    });
  }

  return new Promise((resolve) => {
    axios({
      url: '/api/free-bet/dump-history',
      method: 'get',
    })
      .then((response) => {
        resolve(response && response.result && response.result.history ? response.result.history : []);
      })
      .catch((err) => {
        console.log('fetchFreeBetsDumpHistory: err', err);
        resolve([]);
      });
  });
};

function* fetchSportsFreeBetsFiltersSaga({ free_bets }) {
  const store = ClientAPI.getStore();
  const state = store.getState();

  if (['user', 'token'].indexOf(state.authentication.auth_type) === -1 || state.authentication.access_token === null) {
    yield put(freeBetsFiltersLoaded({}));
    return;
  }

  const ids = [];

  if (free_bets) {
    free_bets.forEach((fb) => {
      if (
        fb &&
        fb.fbid &&
        fb.product &&
        (fb.product === 'sport' || fb.product === 'sport.prematch' || fb.product === 'sport.live') &&
        state.freeBets &&
        state.freeBets.freeBetsFilters &&
        typeof state.freeBets.freeBetsFilters[fb.fbid] === 'undefined'
      ) {
        ids.push(fb.fbid);
      }
    });

    if (ids.length === 0) return; // nothing to fetch
  }

  try {
    /*
    const response = yield playerApi(true, 'POST', '/player/free-bets-filters/' + window.config.clientId, {
      ids: ids,
      platformType: window.config.platformType,
    });
    */

    const tenantId = window.config.tenantId;
    const siteId = window.config.siteId;

    const response = yield playerApiWOUrl(true, 'POST', `${endpoints.freeBetsFilters.list}?tenantId=${tenantId}&siteId=${siteId}`, {
      ids: ids,
    });

    yield put(
      freeBetsFiltersLoaded(
        response && response.data ? response.data : {},
      ),
    );
  } catch (e) {
    console.log('fetchSportsFreeBetsFiltersSaga err', e);
    yield put(freeBetsFiltersLoaded({}));
  }
}

function* fetchFreeBetsSaga() {
  const axios = ClientAPI.getInstance();
  const store = ClientAPI.getStore();
  const state = store.getState();

  if (['user', 'token'].indexOf(state.authentication.auth_type) === -1 || state.authentication.access_token === null) {
    yield put(freeBetsLoaded([]));
    return;
  }

  yield put(freeBetsLoading(1));

  try {
    const response = yield axios({
      url: '/api/free-bet/get-list',
      method: 'get',
    });

    yield put(
      freeBetsLoaded(response && response.result && response.result.free_bets ? response.result.free_bets : []),
    );

    yield fetchSportsFreeBetsFiltersSaga({
      free_bets: response && response.result && response.result.free_bets ? response.result.free_bets : [],
    });
  } catch (e) {
    console.log('fetchFreeBetsSaga err', e);
  }
}

function* fetchFreeBetsHistorySaga(action) {
  const axios = ClientAPI.getInstance();
  const store = ClientAPI.getStore();
  const state = store.getState();
  const page = action.page;

  if (['user', 'token'].indexOf(state.authentication.auth_type) === -1 || state.authentication.access_token === null) {
    yield put(freeBetsLoadedHistory([]));
    return;
  }

  yield put(freeBetsLoading(2));

  try {
    const response = yield axios({
      url: '/api/free-bet/get-history',
      method: 'post',
      data: {
        page: page,
        items_per_page: 10,
      },
    });

    yield put(
      freeBetsLoadedHistory(
        response && response.result && response.result.history
          ? { items: response.result.history, page }
          : { items: [], page },
      ),
    );
  } catch (e) {
    console.log('fetchFreeBetsSaga err', e);
  }
}

function* removeFreeBetSaga(action) {
  const axios = ClientAPI.getInstance();
  const store = ClientAPI.getStore();
  const state = store.getState();
  const code = action.code;

  if (!code) return;

  if (['user', 'token'].indexOf(state.authentication.auth_type) === -1 || state.authentication.access_token === null) {
    return;
  }

  try {
    yield axios({
      url: '/api/free-bet/delete',
      method: 'post',
      data: {
        code: code,
      },
    });

    yield put(freeBetsFetch());
    yield put(resetOneRequest('get-bonus-available'));
    yield put(getBonusAvailable());
  } catch (e) {
    console.log('fetchFreeBetsSaga err', e);
  }
}

export default function* watchFreeBetsSaga() {
  yield takeEvery(freeBets.FREE_BETS_FETCH, fetchFreeBetsSaga);
  yield takeEvery(freeBets.FREE_BET_REMOVE, removeFreeBetSaga);
  yield takeEvery(freeBets.FREE_BETS_FETCH_HISTORY, fetchFreeBetsHistorySaga);
  yield takeLatest(authentication.AUTHENTICATE, fetchFreeBetsSaga);
  yield takeEvery(application.REINITIALIZE, fetchFreeBetsSaga);
  yield takeEvery(freeBets.FREE_BETS_FETCH_FILTERS, fetchSportsFreeBetsFiltersSaga);
}

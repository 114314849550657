import React from 'react';
import styled from 'styled-components';
import { useNavigate, useMatches } from 'react-router-dom';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { useAppSelector, useAppDispatch } from '../../../store';
import { DataElementContext } from '../../../page-components/common/DataElementContext';
import { tournamentActivate, tournamentEnroll } from '../../../modules/tournaments-missions/store/actions/tournament';
import {
  loadTournamentsGroups,
  toggleHappyHoursSelected,
} from '../../../modules/tournaments-missions/store/actions/tournaments_groups';
import { loadPlayerLeaderBoard } from '../../../modules/tournaments-missions/store/actions/leader_board';
import { gameIdsToObjects } from '../../../utils/gameIdsToObjects';
import DefaultAvatar from '../_common/assets/img-avatar-male.png';
import fetchGamesInfo from '../../../utils/games-info-api-cache';
import { getMomentumBonuses } from '../../../modules/momentum/store/actions/momentum';

import { Group } from '../tournament-group';

import './index.scss';

type GameHeaderProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: {
    dsType: string;
  };
};

const defaultProps = {
  className: '',
  styleText: '',
  properties: {
    dsType: '',
  },
};

type StateProps = {
  hasActive: boolean;
  hasEnrolled: boolean;
  tournament: any;
  group: Group | null;
  activeTournaments: any;
};

type AppSelectorState = {
  authentication?: any;
  groups?: any[];
  groupsLoaded?: boolean;
  rank?: any;
  allGames?: any[];
  hhStatuses?: any;
  hhRunning?: any;
  hhSelected?: boolean;
  isHHEligibleBet?: any;
  hhPrizeWon?: any;
  playerProfile?: { client_player_id?: string };
};

const transformTournament = (tournament: any, lang: string, allGames?: any[]) => {
  const copy = JSON.parse(JSON.stringify(tournament));

  const newMetaUI: any = {};

  copy.meta.ui &&
    Object.keys(copy.meta.ui).forEach((metaUIKey) => {
      if (copy.meta.ui[metaUIKey] != null && copy.meta.ui[metaUIKey].url != null) {
        newMetaUI[metaUIKey] = copy.meta.ui[metaUIKey].url;
      } else if (copy.meta.ui[metaUIKey] != null && copy.meta.ui[metaUIKey].text != null) {
        if (copy.meta.ui[metaUIKey].text[lang] != null && copy.meta.ui[metaUIKey].text[lang]) {
          newMetaUI[metaUIKey] = copy.meta.ui[metaUIKey].text[lang];
        } else {
          let value = '';

          const keys = Object.keys(copy.meta.ui[metaUIKey].text);
          for (let i = 0; i < keys.length; i++) {
            const k = keys[i];
            if (copy.meta.ui[metaUIKey].text[k]) {
              value = copy.meta.ui[metaUIKey].text[k];
              break;
            }
          }

          newMetaUI[metaUIKey] = value;
        }
      }
    });

  copy.meta.ui = newMetaUI;

  /*
  if (allGames != null) {
    if (copy.meta && copy.meta.games) {
      copy.meta.games = gameIdsToObjects(allGames, copy.meta.games);
    }
    if (copy.meta && copy.meta.recommended) {
      copy.meta.recommended = gameIdsToObjects(allGames, copy.meta.recommended);
    }
  }
  */

  return copy;
};

const transformTournaments = (tournaments: any[], groupNames: string[], lang: string, allGames?: any[]) => {
  const copy = JSON.parse(JSON.stringify(tournaments));

  copy &&
    copy.forEach((tournament: any, index: number) => {
      const newMetaUI: any = {};

      tournament.meta.ui &&
        Object.keys(tournament.meta.ui).forEach((metaUIKey) => {
          if (tournament.meta.ui[metaUIKey] != null && tournament.meta.ui[metaUIKey].url != null) {
            newMetaUI[metaUIKey] = tournament.meta.ui[metaUIKey].url;
          } else if (tournament.meta.ui[metaUIKey] != null && tournament.meta.ui[metaUIKey].text != null) {
            if (tournament.meta.ui[metaUIKey].text[lang] != null && tournament.meta.ui[metaUIKey].text[lang]) {
              newMetaUI[metaUIKey] = tournament.meta.ui[metaUIKey].text[lang];
            } else {
              let value = '';

              const keys = Object.keys(tournament.meta.ui[metaUIKey].text);
              for (let i = 0; i < keys.length; i++) {
                const k = keys[i];
                if (tournament.meta.ui[metaUIKey].text[k]) {
                  value = tournament.meta.ui[metaUIKey].text[k];
                  break;
                }
              }

              newMetaUI[metaUIKey] = value;
            }
          }
        });

      copy[index].meta.ui = newMetaUI;

      /*
      if (allGames != null) {
        if (copy[index].meta && copy[index].meta.games) {
          copy[index].meta.games = gameIdsToObjects(allGames, copy[index].meta.games);
        }
        if (copy[index].meta && copy[index].meta.recommended) {
          copy[index].meta.recommended = gameIdsToObjects(allGames, copy[index].meta.recommended);
        }
      }
      */

      copy[index].groupName = groupNames[index];
    });

  return copy;
};

const transformGroup = (group: Group, lang: string, allGames?: any[]) => {
  const copy = JSON.parse(JSON.stringify(group));

  copy.tournaments &&
    copy.tournaments.forEach((tournament: any, index: number) => {
      const newMetaUI: any = {};

      tournament.meta.ui &&
        Object.keys(tournament.meta.ui).forEach((metaUIKey) => {
          if (tournament.meta.ui[metaUIKey] != null && tournament.meta.ui[metaUIKey].url != null) {
            newMetaUI[metaUIKey] = tournament.meta.ui[metaUIKey].url;
          } else if (tournament.meta.ui[metaUIKey] != null && tournament.meta.ui[metaUIKey].text != null) {
            if (tournament.meta.ui[metaUIKey].text[lang] != null && tournament.meta.ui[metaUIKey].text[lang]) {
              newMetaUI[metaUIKey] = tournament.meta.ui[metaUIKey].text[lang];
            } else {
              let value = '';

              const keys = Object.keys(tournament.meta.ui[metaUIKey].text);
              for (let i = 0; i < keys.length; i++) {
                const k = keys[i];
                if (tournament.meta.ui[metaUIKey].text[k]) {
                  value = tournament.meta.ui[metaUIKey].text[k];
                  break;
                }
              }

              newMetaUI[metaUIKey] = value;
            }
          }
        });

      copy.tournaments[index].meta.ui = newMetaUI;

      /*
      if (allGames != null) {
        if (copy.tournaments[index].meta && copy.tournaments[index].meta.games) {
          copy.tournaments[index].meta.games = gameIdsToObjects(allGames, copy.tournaments[index].meta.games);
        }
        if (copy.tournaments[index].meta && copy.tournaments[index].meta.recommended) {
          copy.tournaments[index].meta.recommended = gameIdsToObjects(
            allGames,
            copy.tournaments[index].meta.recommended,
          );
        }
      }
      */
    });

  const newMetaUI: any = {};

  group.meta.ui &&
    Object.keys(group.meta.ui).forEach((metaUIKey) => {
      if (group.meta.ui[metaUIKey] != null && group.meta.ui[metaUIKey].url != null) {
        newMetaUI[metaUIKey] = group.meta.ui[metaUIKey].url;
      } else if (group.meta.ui[metaUIKey] != null && group.meta.ui[metaUIKey].text != null) {
        if (group.meta.ui[metaUIKey].text[lang] != null && group.meta.ui[metaUIKey].text[lang]) {
          newMetaUI[metaUIKey] = group.meta.ui[metaUIKey].text[lang];
        } else {
          let value = '';

          const keys = Object.keys(group.meta.ui[metaUIKey].text);
          for (let i = 0; i < keys.length; i++) {
            const k = keys[i];
            if (group.meta.ui[metaUIKey].text[k]) {
              value = group.meta.ui[metaUIKey].text[k];
              break;
            }
          }

          newMetaUI[metaUIKey] = value;
        }
      }
    });

  copy.meta.ui = newMetaUI;

  return copy;
};

const emptyArr: any[] = [];
const emmtyObj: any = {};

export const fetchTournamentInfo = (gameId: number, lang = 'en') => {
  const [state, setState] = React.useState<StateProps>({
    hasActive: false,
    hasEnrolled: false,
    tournament: null,
    activeTournaments: [],
    group: null,
  });

  const dispatch = useAppDispatch();
  const authentication = useAppSelector<any>((state) => state.authentication);
  const groups = useAppSelector<any>((state) =>
    state.tournamentsMissions ? state.tournamentsMissions.groups.list : emptyArr,
  );
  const groupsLoaded = useAppSelector<any>((state) =>
    state.tournamentsMissions ? state.tournamentsMissions.groups.loaded : false,
  );
  const rank = useAppSelector<any>((state) =>
    state.tournamentsMissions ? state.tournamentsMissions.leaderboard.player : emmtyObj,
  );

  React.useEffect(() => {
    if (!groupsLoaded) {
      dispatch(loadTournamentsGroups());
    }
  }, []);

  React.useEffect(() => {
    if (!groups || !groups.length) return;

    if (gameId && !isNaN(gameId)) {
      let hasActive = false;
      let hasEnrolled = false;
      let tournament = null;
      let currentGroup = null;
      const activeTournaments: any = [];
      const groupNames: any = [];

      for (let i = 0; i < groups.length; i++) {
        const group = groups[i];

        if (group.type !== 2) continue;

        if (typeof group.block_enroll === 'undefined' || !group.block_enroll) {
          for (let j = 0; j < group.tournaments.length; j++) {
            if (group.tournaments[j].activated) {
              if (
                group.tournaments[j].meta &&
                group.tournaments[j].meta.games &&
                group.tournaments[j].meta.games.indexOf(gameId) > -1
              ) {
                currentGroup = group;
                tournament = group.tournaments[j];
                hasActive = true;
                break;
              }
            }
          }
        }
        if (hasActive) break;
      }

      const now = moment().valueOf();

      for (let i = 0; i < groups.length; i++) {
        const group = groups[i];

        if (group.type !== 2) continue;
        if (!(typeof group.block_enroll === 'undefined' || !group.block_enroll)) continue;

        group.tournaments.forEach((t: any) => {
          if (t.start_date <= now && now < t.end_date) {
            if (t.meta && t.meta.games && t.meta.games.indexOf(gameId) > -1) {
              if (typeof group.block_enroll === 'undefined' || !group.block_enroll) {
                activeTournaments.push(t);
                groupNames.push(group.name);
              }
            }
          }
        });
      }

      if (!hasActive) {
        const sorted = [...groups].sort((a, b) => {
          const priorityA = a.priority ? a.priority : 0;
          const priorityB = b.priority ? b.priority : 0;
          return priorityB - priorityA;
        });

        for (let i = 0; i < sorted.length; i++) {
          const group = sorted[i];

          if (group.type !== 2) continue;

          if (!group.block_enroll) {
            for (let j = 0; j < group.tournaments.length; j++) {
              if (group.tournaments[j].enrolled) {
                if (
                  group.tournaments[j].meta &&
                  group.tournaments[j].meta.games &&
                  group.tournaments[j].meta.games.indexOf(gameId) > -1
                ) {
                  currentGroup = group;
                  tournament = group.tournaments[j];
                  hasEnrolled = true;
                  break;
                }
              }
            }
          }
          if (hasEnrolled) break;
        }
      }

      if (!hasActive && !hasEnrolled) {
        const now = moment().valueOf();
        const sorted = [...groups].sort((a, b) => {
          const priorityA = a.priority ? a.priority : 0;
          const priorityB = b.priority ? b.priority : 0;
          return priorityB - priorityA;
        });

        for (let i = 0; i < sorted.length; i++) {
          const group = sorted[i];

          if (group.type !== 2) continue;

          if (!group.block_enroll) {
            for (let j = 0; j < group.tournaments.length; j++) {
              if (group.tournaments[j].start_date <= now && now < group.tournaments[j].end_date) {
                if (
                  group.tournaments[j].meta &&
                  group.tournaments[j].meta.games &&
                  group.tournaments[j].meta.games.indexOf(gameId) > -1
                ) {
                  currentGroup = group;
                  tournament = group.tournaments[j];
                  break;
                }
              }
              if (tournament) break;
            }
          }
        }
      }

      let isAuthenticated = false;
      if (authentication && ['user', 'token'].indexOf(authentication.auth_type) > -1) {
        isAuthenticated = true;
      }

      if (tournament) {
        if (rank && !rank[tournament.id]) {
          dispatch(loadPlayerLeaderBoard({ tournamentId: tournament.id, inGame: true }));
        }

        if (tournament.enrolled && !tournament.activated && isAuthenticated) {
          if (tournament.player_mission_id) {
            dispatch(tournamentActivate({ tournamentId: tournament.player_mission_id }));
          }
        }

        setState({
          hasActive,
          hasEnrolled,
          tournament: transformTournament(tournament, lang),
          activeTournaments: transformTournaments(activeTournaments, groupNames, lang),
          group: transformGroup(currentGroup, lang),
        });
      }
    }
  }, [authentication, groups, rank, tournamentActivate, gameId, lang]);

  return state;
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);
const emptyArray: any = [];

const GameHeader = (componentProps: GameHeaderProps) => {
  const tmpProps = { ...defaultProps, ...componentProps };
  delete tmpProps.children;
  const props = JSON.parse(JSON.stringify(tmpProps));
  const { children } = componentProps;

  const { i18n, t } = useTranslation();
  const dispatch = useAppDispatch();
  const authentication = useAppSelector((state) => state.authentication);
  const rank: any = useAppSelector((state) =>
    state.tournamentsMissions ? state.tournamentsMissions.leaderboard.player : {},
  );
  const hhRunning = useAppSelector((state) => state.happyHour.running);
  const hhStatuses = useAppSelector((state) => state.happyHour.status);
  const hhSelected = useAppSelector((state) =>
    state.tournamentsMissions ? state.tournamentsMissions.groups.hhSelected : false,
  );
  const isHHEligibleBet = useAppSelector((state) => state.happyHour.betInfo);
  const momentumPrize = useAppSelector<any>((state) => state.momentum.prize?.value);
  const momentumPrizeLoaded = useAppSelector<any>((state) => state.momentum?.prize?.loaded);
  const momentumEligibleGames = useAppSelector<any>((state) =>
    state.momentum?.prize?.eligibleGames ? state.momentum.prize.eligibleGames : emptyArray,
  );

  const matches = useMatches();

  const [state, setState] = React.useState({
    openTournamentInfoModal: false,
    openEnrollModal: false,
  });

  let gameId = props.properties.targetId;

  if (props.properties.targetIdFromPath && props.properties.pathParamKey) {
    if (matches && matches.length) {
      const match = matches[0];
      if (match.params && match.params[props.properties.pathParamKey] != null) {
        gameId = match.params[props.properties.pathParamKey];
      }
    }
  }

  gameId = gameId ? Number(gameId) : gameId;

  const { hasActive, hasEnrolled, tournament, activeTournaments, group } = fetchTournamentInfo(gameId, i18n.language);
  const [showHappyHoursTermsAndConditions, setShowHappyHoursTermsAndConditions] = React.useState(false);
  const [extendHappyHourHeader, setExtendHappyHourHeader] = React.useState(false);
  const [extendJackpotHeader, setExtendJackpotHeader] = React.useState(false);

  const {
    data: { data: allTournamentGames },
  } = fetchGamesInfo({
    dsId: window.config.dataSourceAllGames,
    gameIds: tournament?.meta?.games,
    authenticationToken: authentication?.access_token,
  });

  React.useEffect(() => {
    if (!momentumPrizeLoaded && ['user', 'token'].indexOf(authentication.auth_type) > -1) {
      dispatch(getMomentumBonuses());
    }
  }, [momentumPrizeLoaded, authentication]);

  const allTournamentRecommandedGames: any = [];
  if (allTournamentGames?.length) {
    const hashIds: any = {};
    allTournamentGames.forEach((g: any, index: number) => {
      hashIds[g.id] = index;
    });
    tournament?.meta?.recommended.forEach((id: number) => {
      if (hashIds[id.toString()] != null)
        allTournamentRecommandedGames.push(allTournamentGames[hashIds[id.toString()]]);
    });
  }

  let isAuthenticated = false;
  if (authentication && ['user', 'token'].indexOf(authentication.auth_type) > -1) isAuthenticated = true;

  if (!isAuthenticated) return null;
  if (!gameId) return null;
  // if (!tournament) return null;

  // get a list of top players (3->5 top players). might include or not the current player -> limit to 3 players
  const getTopPlayers = () => {
    if (tournament && rank && rank[tournament.id] && rank[tournament.id].top) {
      const image = tournament?.meta?.ui?.avatar_image ?? DefaultAvatar;

      const players = JSON.parse(JSON.stringify(rank[tournament.id].top)).map((el: any) => {
        // return JSON.parse(JSON.stringify(rank[tournament.id].top)).map((el: any) => {

        return {
          pos: el.pos,
          nickname: el.nickname,
          score: el.score,
          award: el.award,
          me: !!el.me,
          outside: false,
          avatar:
            el.avatar && typeof el.avatar === 'string' ? el.avatar : el.avatar && el.avatar.url ? el.avatar.url : image,
          postStr: el.pos.toString(),
        };
      });

      if (!Array.isArray(players)) return [];

      // place 1st player in the middle and remove the rest if there are more than 3 players
      if (players.length >= 3) {
        players[0] = players.splice(1, 1, players[0])[0];
        return players.slice(0, 3);
      } else {
        return players;
      }
    }
    return [];
  };

  // get the list of players around and including the current player and limit to 4 players
  const getPlayers = () => {
    if (tournament && rank && rank[tournament.id] && rank[tournament.id].player) {
      const image = tournament?.meta?.ui?.avatar_image ?? DefaultAvatar;

      const players = JSON.parse(JSON.stringify(rank[tournament.id].player)).map((el: any) => {
        // return JSON.parse(JSON.stringify(rank[tournament.id].player)).map((el: any) => {

        return {
          pos: el.pos,
          nickname: el.nickname,
          score: el.score,
          award: el.award,
          me: !!el.me,
          outside: false,
          avatar:
            el.avatar && typeof el.avatar === 'string' ? el.avatar : el.avatar && el.avatar.url ? el.avatar.url : image,
          postStr: el.pos.toString(),
        };
      });

      if (!Array.isArray(players)) return [];

      // get player index in the list
      let playerIndex = -1;
      for (let i = 0; i < players.length; i++) {
        if (players[i].me) {
          playerIndex = i;
          break;
        }
      }
      // if player in not in the first 4 players, show the 3 players before and the player
      if (players.length > 4) {
        if (playerIndex > 4) {
          return players.slice(playerIndex - 3, playerIndex + 1);
        } else {
          return players.slice(0, 4);
        }
      } else {
        return players;
      }
    }
    return [];
  };

  // handled joining/enrolling to an active tournament
  const handleJoinEnroll = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    if (tournament && !tournament.enrolled) {
      dispatch(tournamentEnroll({ tournamentId: tournament.id, activate: true }));
    }
  };

  // hide/show the popup with a summary about the tournament
  const toggleTournamentInfoModal = (e?: React.MouseEvent<HTMLElement>) => {
    if (e) e.stopPropagation();
    setState((v) => ({
      ...v,
      openTournamentInfoModal: !v.openTournamentInfoModal,
    }));
  };

  // hide/show the popup from where a player can switch between active tournments or happy hour
  const toggleEnrollModal = (e?: React.MouseEvent<HTMLElement>) => {
    if (e) e.stopPropagation();
    setState((v) => ({
      ...v,
      openEnrollModal: !v.openEnrollModal,
    }));
  };

  const handleJoinEnrollTournament = (e: React.MouseEvent<HTMLElement>) => {
    dispatch(toggleHappyHoursSelected(false));
    const target = e.currentTarget;

    if (target) {
      const id: string | undefined = target.dataset.id;
      const type = target.dataset.type;
      if (id != null) {
        if (type === 'enroll') {
          dispatch(
            tournamentEnroll({
              tournamentId: parseInt(id, 10),
              activate: true,
              cb: () => {
                toggleEnrollModal();
              },
            }),
          );
          return;
        } else {
          dispatch(
            tournamentActivate({
              tournamentId: parseInt(id, 10),
              cb: () => {
                toggleEnrollModal();
              },
            }),
          );
          return;
        }
      }
    }
    toggleEnrollModal();
  };
  const handleHappyHourJoin = (e: React.MouseEvent<HTMLElement>) => {
    dispatch(toggleHappyHoursSelected(true));
    toggleEnrollModal();
  };

  const hhIsRunning = hhRunning && hhRunning['online-slots'] ? hhRunning['online-slots'] : false;
  const hhStatus = hhIsRunning && hhStatuses && hhStatuses['online-slots'] ? hhStatuses['online-slots'] : null;

  const getTexts = () => {
    const topPlayers = getTopPlayers();
    const texts = [];

    if (tournament) {
      texts.push(tournament.name);

      if (tournament.meta && tournament.meta.ui) {
        texts.push(`${tournament.meta.ui.prize_1} ${tournament.meta.ui.prize_2}`);
      }
    }

    if (Array.isArray(topPlayers)) {
      for (let i = 0; i < 3; i++) {
        if (topPlayers.length > i && topPlayers[i] && topPlayers[i].award) {
          texts.push(`${t('Position')} #${topPlayers[i].pos}: ${topPlayers[i].award}`);
        }
      }
    }

    return texts;
  };

  const isBetEligible = () => {
    if (isHHEligibleBet && isHHEligibleBet['online-slots']) {
      if (isHHEligibleBet['online-slots'].eligible) {
        return true;
      }
      return false;
    }
    return true;
  };

  const getTournamentCalendarInfo = (tournament: any) => {
    if (!tournament) return null;
    // if Happy Hour, show the Happy Hour info
    if (typeof tournament.duration !== 'undefined') {
      return `${moment(tournament.start_time * 1000).format('DD')} ${t(
        moment(tournament.start_time * 1000).format('MMM'),
      )} - ${moment(tournament.start_time * 1000).format('DD')} ${t(
        moment((tournament.start_time + tournament.duration) * 1000).format('MMM'),
      )}`;
    } else {
      // if not Happy Hour, show the tournament info
      return `${moment(tournament.start_date).format('DD')} ${t(
        moment(tournament.start_date).format('MMM'),
      )} - ${moment(tournament.end_date).format('DD')} ${t(moment(tournament.end_date).format('MMM'))}`;
    }
  };

  let hasMomentum = false;
  let hasMomentumEligibleGame = null;
  if (momentumPrize?.active && gameId) {
    if (momentumPrize?.bonus_expires_at) {
      const now = new Date().valueOf();
      const prizeExpiresAt = new Date(momentumPrize?.bonus_expires_at).valueOf();
      const expired = now > prizeExpiresAt;

      if (!expired) {
        if (momentumEligibleGames.length > 0 && momentumEligibleGames.includes(parseInt(gameId, 10))) {
          hasMomentum = true;
        }
      }
    }
  } else if (momentumEligibleGames.length > 0 && gameId) {
    if (momentumEligibleGames.includes(parseInt(gameId, 10))) {
      hasMomentumEligibleGame = true;
    } else {
      hasMomentumEligibleGame = false;
    }
  }

  let canEnrollModal = false;
  if (hhIsRunning) {
    canEnrollModal = true;
  } else if (activeTournaments.length > 0) {
    canEnrollModal = true;
  } else if (hasMomentumEligibleGame != null) {
    canEnrollModal = true;
  }

  const contextValue = {
    hasMomentum,
    hasMomentumEligibleGame,
    hasActive: hasActive,
    hasEnrolled: hasEnrolled,
    //currentTournament: tournament,
    currentTournament: tournament
      ? {
          ...tournament,
          meta: tournament.meta
            ? {
                ...tournament.meta,
                games:
                  tournament.meta &&
                  tournament.meta.games &&
                  gameIdsToObjects(allTournamentGames, tournament.meta.games),
                recommended:
                  tournament.meta &&
                  tournament.meta.recommended &&
                  gameIdsToObjects(allTournamentRecommandedGames, tournament.meta.recommended),
              }
            : {},
        }
      : null,
    group: group,
    url: group ? group.url : null,
    name: group ? group.name : null,
    topPlayers: getTopPlayers(),
    players: getPlayers(),
    handleJoinEnroll: handleJoinEnroll,
    toggleTournamentInfoModal: toggleTournamentInfoModal,
    canEnrollModal: canEnrollModal,
    toggleEnrollModal: toggleEnrollModal,
    openTournamentInfoModal: state.openTournamentInfoModal,
    openEnrollModal: state.openEnrollModal,
    tournaments: activeTournaments
      ? activeTournaments.map((t: any) => ({
          ...t,
          handleJoinEnrollTournament: handleJoinEnrollTournament,
          happyHourSelected: hhSelected,
          dateString: getTournamentCalendarInfo(tournament),
        }))
      : [],
    happyHourSelected: hhSelected,
    happyHourIsRunning: hhIsRunning,
    happyHour: hhStatus,
    isHHEligibleBet: isBetEligible(),
    happyHourDateString: getTournamentCalendarInfo(hhStatus),
    handleHappyHourJoin,
    showHappyHoursTermsAndConditions: showHappyHoursTermsAndConditions,
    toggleHappyHoursTermsAndCondition: () => {
      setShowHappyHoursTermsAndConditions((v) => !v);
    },
    extendHappyHourHeader: extendHappyHourHeader,
    toggleExtendHappyHourHeader: () => {
      setExtendHappyHourHeader((pS) => !pS);
    },
    scrollingTexts: getTexts(),
    extendJackpotHeader: extendJackpotHeader,
    toggleExtendJackpotHeader: () => {
      setExtendJackpotHeader((pS) => !pS);
    },
    jackpot: {}, //TODO: add jackpot data
  };

  //console.log('GameHeader[contextValue]', contextValue);

  return (
    <ModuleElementDiv className={props.className ?? ''} $styleText={props.styleText}>
      <DataElementContext.Provider value={contextValue}>{children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default GameHeader;

import React from 'react';
import styled from 'styled-components';
import { connect, shallowEqual } from 'react-redux';
import axios from 'axios';
import { useNavigate, useMatches } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import './index.scss';

import { useAppSelector, useAppDispatch } from '../../../store';
import { DataElementContext } from '../../../page-components/common/DataElementContext';
import { loadedWheelStatus } from '../../../modules/wheel/store/actions/wheel';
import { fetchWheelsDataSource } from '@/store/slices/wheelsDataSource';

type WheelProps = {
  children: any;
  styleText: string;
  className: string;
  loadedWheelStatus(status: any, skip?: any): void;
  wheelSetups: any;
  authentication: any;
  properties?: {
    dsType: string;
  };
};

const apiUrl = window.config.betsApiUrl + '/wheel/player';

const defaultProps = {
  className: '',
  styleText: '',
  properties: {
    dsType: '',
  },
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const WheelSimpleModule = (componentProps: WheelProps) => {
  const tmpProps = { ...defaultProps, ...componentProps };
  delete tmpProps.children;
  const props = JSON.parse(JSON.stringify(tmpProps));
  const { children } = componentProps;

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { authentication, loadingWheelData, wheelSetups } = useAppSelector<{
    authentication: any;
    loadingWheelData: boolean;
    wheelSetups: any;
  }>(
    (state) => ({
      wheelSetups: state.wheelsDataSource.wheels,
      //wheelSetups: state.config.wheelSetups,
      authentication: state.authentication,
      loadingWheelData: state.wheel.wheel.loading,
    }),
    shallowEqual,
  );

  const [hasError, setHasError] = React.useState<boolean>(false);
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [status, setStatus] = React.useState<any>(null);
  const [spinning, setSpinning] = React.useState<boolean>(false);
  const [processing, setProcessing] = React.useState<boolean>(false);

  const isSpinning = React.useRef(false);
  const wheelRef = React.useRef(null);

  let wheelSetupId = '';
  if (status?.wheel_setup && wheelSetups[status.wheel_setup]) {
    wheelSetupId = status?.wheel_setup;
  }

  React.useEffect(() => {
    if (loaded && status && !isSpinning.current && wheelRef.current) {
      if (!status.wheel_setup) {
        navigate('/');
        return;
      }
    }
  }, [wheelRef, loaded, status, wheelSetups]); // eslint-disable-line

  const loadStatus = () => {
    if (hasError) {
      setLoaded(false);
    }
    setLoading(true);
    setHasError(false);
    setSpinning(false);
    setProcessing(false);
    axios
      .get(apiUrl + '/status', {
        params: {
          wheel_setup_id: wheelSetupId,
        },
        headers: {
          Authorization: 'Bearer ' + authentication.access_token,
        },
      })
      .then((response) => {
        if (response && response.data) {
          const status = response.data;
          setStatus(status);
          setLoaded(true);
          setLoading(false);
          componentProps.loadedWheelStatus(status);
        }
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
      });
  };

  React.useEffect(() => {
    if (['user', 'token'].indexOf(authentication.auth_type) === -1 || authentication.access_token === null) {
      localStorage.setItem('redirect_after_login', '/wheel');
      navigate('/login');
      return;
    }
    loadStatus();
    dispatch(fetchWheelsDataSource({ id: 'player_wheel_setups' }));
  }, []); // eslint-disable-line

  const canFreeSpin = () => {
    if (status && status.free_spin && status.free_spin.status && status.free_spin.status === 'available') {
      return true;
    }
    return false;
  };
  const canKeysSpin = () => {
    if (status && status.keys_spin && status.keys_spin.status && status.keys_spin.status === 'available') {
      return true;
    }
    return false;
  };
  const canNextSpin = () => {
    if (status && status.free_spin && status.free_spin.next_spin_after) {
      return true;
    }
    return false;
  };

  if (!wheelSetupId) return null;

  const contextValue: any = {
    id: wheelSetupId,
    spinning, // are wheels spinning
    processing,
    loading, // are we loading data

    canFreeSpin: canFreeSpin(),
    canKeysSpin: canKeysSpin(),
    canNextSpin: canNextSpin(),
    availableKeys: status && status.keys_spin && status.keys_spin.available ? status.keys_spin.available : 0,

    loaded: loaded,
    hasError: hasError,

    nextSpinAfter: canNextSpin() ? status.free_spin.next_spin_after : null,
    // TODO: remove hardcoded values
    isEligible: true,
    // isEligible: status && status.status && status.status === 'available' ? true : false,

    status: status,
  };

  // console.log('Simple Wheel contextValue: ', { contextValue, cfg, props, status });

  return (
    <ModuleElementDiv className={props.className ?? ''} $styleText={props.styleText}>
      <DataElementContext.Provider value={contextValue}>{children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

const mapStateToProps = (state: any) => {
  return {
    wheelSetups: state.config.wheelSetups,
    authentication: state.authentication,
  };
};

const mapActions = {
  loadedWheelStatus: loadedWheelStatus,
};

const Wheel = connect(mapStateToProps, mapActions)(WheelSimpleModule);

export default Wheel;

import * as actionTypes from './../actions/actionTypes';

const initialOktoState = {
  paymentCode: null,
  qrPayload: null,
  customer: {
    active_auto_exclusion: null,
    amount_can_deposit: null,
    account_verified: null,
    okto_terms_accepted: null,
    okto_terms_modified: null,
    okto_customer_date: null,
  },
  error: false,
};

const initialState = {
  bridger: {
    cashierKey: null,
    cashierToken: null,
    error: false,
  },
  paysafe: {
    iframeUrl: null,
    error: false,
  },
  okto: { ...initialOktoState },
  aircash: {
    loading: false,
    iframeUrl: null,
    error: false,
  },
  viva: {
    loading: false,
    loadingAccessToken: false,
    loadingCardToken: false,
    amount: null,
    iframeLink: null,
    serverResponse: false,
    bonusError: false,
    tokenList: null,
  },
};

const depositReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.deposit.INITIATE_BRIDGER_PAY_DEPOSIT:
      return {
        ...state,
        bridger: {
          ...state.bridger,
          cashierKey: null,
          cashierToken: null,
          error: false,
        },
      };
    case actionTypes.deposit.INITIATE_BRIDGER_PAY_DEPOSIT_SUCCESS:
      return {
        ...state,
        bridger: {
          ...state.bridger,
          cashierKey: action.data.cashierKey,
          cashierToken: action.data.cashierToken,
          error: false,
        },
      };
    case actionTypes.deposit.INITIATE_BRIDGER_PAY_DEPOSIT_FAILED:
      return {
        ...state,
        bridger: {
          ...state.bridger,
          cashierKey: null,
          cashierToken: null,
          error: true,
        },
      };

    case actionTypes.paymentCheckout.REQUEST_PAYMENT_LINK:
      return {
        ...state,
        paysafe: {
          ...state.paysafe,
          iframeUrl: null,
          error: false,
        },
      };
    case actionTypes.paymentCheckout.RECEIVED_PAYMENT_LINK:
      console.log(action);
      return {
        ...state,
        paysafe: {
          ...state.paysafe,
          iframeUrl: action.response.link,
          error: !!(action.response?.error ?? action.response.errorCode),
        },
      };

    case actionTypes.deposit.OKTO_CREATE_PAYMENT_CODE:
      return {
        ...state,
        loading: true,
        okto: { ...initialOktoState },
      };
    case actionTypes.deposit.OKTO_RECEIVED_PAYMENT_CODE:
      return {
        ...state,
        loading: false,
        okto: {
          ...initialOktoState,
          ...action.data,
        },
      };
    case actionTypes.deposit.OKTO_RECEIVED_CUSTOMER:
      return {
        ...state,
        loading: false,
        okto: {
          ...initialOktoState,
          customer: {
            ...initialOktoState.customer,
            ...action.data,
          },
        },
      };
    case actionTypes.deposit.OKTO_GET_CUSTOMER:
      return {
        ...state,
        loading: true,
        okto: {
          ...initialOktoState,
        },
      };
    case actionTypes.deposit.OKTO_RESET:
      return {
        ...state,
        okto: {
          ...initialOktoState,
          // customer: {
          //   ...initialOktoState.customer,
          //   ...state.okto.customer,
          // },
        },
      };

    case actionTypes.paymentCheckout.REQUEST_VIVA_TOKEN:
      return {
        ...state,
        viva: {
          ...state.viva,
          loadingAccessToken: true,
        },
      };
    case actionTypes.paymentCheckout.RECEIVED_VIVA_TOKEN:
      return {
        ...state,
        viva: {
          ...state.viva,
          loadingAccessToken: false,
        },
      };
    case actionTypes.paymentCheckout.REQUEST_VIVA_CARD_TOKEN:
      return {
        ...state,
        viva: {
          ...state.viva,
          loadingCardToken: true,
        },
      };
    case actionTypes.paymentCheckout.RECEIVED_PAYMENT_TOKEN:
      return {
        ...state,
        viva: {
          ...state.viva,
          loadingCardToken: false,
          tokenList: action?.data?.result,
        },
      };
    case actionTypes.paymentCheckout.REQUEST_VIVA_CHARGE_TOKEN:
      return {
        ...state,
        viva: {
          ...state.viva,
          loading: true,
        },
      };
    case actionTypes.paymentCheckout.SEND_VIVA_CHARGE_TOKEN:
      let iframeLink = null;
      if (action.data && action.data.redirectToACSForm) {
        iframeLink = action.data.redirectToACSForm;
      }
      return {
        ...state,
        viva: {
          ...state.viva,
          loading: true,
          iframeLink: iframeLink,
        },
      };
    case actionTypes.paymentCheckout.RECEIVED_VIVA_CHARGE_TOKEN:
      let serverResponse = null;
      if (typeof action.data.serverResponse !== 'undefined') {
        serverResponse = action.data.serverResponse;
      } else {
        if (action.data.result && action.data.result.status) {
          serverResponse = action.data.result.status;
        }

        if (action.data.result && action.data.result.ResponseCode) {
          serverResponse = `${action.data.result.ResponseMessage} (${action.data.result.ResponseCode})`;
        }

        if (action.data.result && action.data.result.http) {
          serverResponse = 'Something went wrong (HTTP_FAIL)';
        }

        if (!serverResponse) {
          serverResponse = 'success';
        }
      }
      const bonusError = action?.data?.result?.bonusError ? action.data.result.bonusError : false;

      return {
        ...state,
        viva: {
          ...state.viva,
          loading: false,
          serverResponse: serverResponse,
          bonusError: bonusError,
        },
      };
    case actionTypes.paymentCheckout.RECEIVED_VIVA_CHARGE_TOKEN_WITH_CARD:
      let serverResponseWC = null;
      if (action.data.result && action.data.result.status) {
        serverResponseWC = action.data.result.status;
      }

      if (action.data.response && action.data.response.data && action.data.response.data.message) {
        serverResponseWC = action.data.response.data.message;
        if (action.data.response.status) {
          serverResponseWC = action.data.response.status + ': ' + serverResponseWC;
        }
      }
      const bonusErrorWithCard = action.data.result.bonusError ? action.data.result.bonusError : false;
      return {
        ...state,
        viva: {
          ...state.viva,
          loading: false,
          serverResponse: serverResponseWC,
          bonusError: bonusErrorWithCard,
        },
      };
    case actionTypes.paymentCheckout.RESET_PAYMENT_CHECKOUT:
      return {
        ...state,
        viva: {
          ...state.viva,
          loading: false,
          loadingAccessToken: false,
          loadingCardToken: false,
          access_token: null,
          amount: null,
          iframeLink: null,
          serverResponse: false,
        },
      };
    case actionTypes.deposit.AIRCASH_REQUEST_PAYMENT_LINK:
      return {
        ...state,
        aircash: {
          ...state.aircash,
          loading: true,
          error: false,
        },
      };
    case actionTypes.deposit.AIRCASH_RECEIVED_PAYMENT_LINK:
      return {
        ...state,
        aircash: {
          ...state.aircash,
          loading: false,
          iframeUrl: action.response.url,
          error: !!(action.response?.error ?? action.response.errorCode),
        },
      };
    default:
      return state;
  }
};

export default depositReducer;

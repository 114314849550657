import React from 'react';
import styled from 'styled-components';
import moment from 'moment';

import { useAppSelector, useAppDispatch } from '../../../store';
import { DataElementContext } from '../../../page-components/common/DataElementContext';
import { loadTournamentsGroups } from '../../../modules/tournaments-missions/store/actions/tournaments_groups';
import { fetchSources } from '../../../store/slices/dataSources';
import { getMomentumBonuses } from '../../../modules/momentum/store/actions/momentum';

import './index.scss';

export interface Group {
  id: number;
  meta: any;
  priority: number;
  type: number;
  url: string;
  name: string;
  currentTournament: any;
  tournaments: any[];
}

const emptyArr: any[] = [];
const emptyObj: any = {};

type RewardsCardCarouselProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: {
    dsType: string;
  };
};

const defaultProps = {
  className: '',
  styleText: '',
  properties: {
    dsType: '',
  },
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const RewardsCardCarousel = (componentProps: RewardsCardCarouselProps) => {
  const dispatch = useAppDispatch();

  const refresh = React.useRef(0);
  const fetchedSources = React.useRef(false);

  const allTournamentGroups = useAppSelector((state) =>
    state.tournamentsMissions ? state.tournamentsMissions.groups.list : emptyArr,
  );
  const groupsLoaded = useAppSelector((state) =>
    state.tournamentsMissions ? state.tournamentsMissions.groups.loaded : false,
  );
  const [allLoadedTournaments, setAllLoadedTournaments] = React.useState<any>([]);
  // happy hour
  const happyHourRunning = useAppSelector((state) => state.happyHour.running);
  // jackpots
  const dsItems = useAppSelector((state) => state.dataSources.items);
  const dsLoaded = useAppSelector((state) => state.dataSources.loaded);

  const prize = useAppSelector<any>((state) => state.momentum.prize?.value);
  const momentumPrizeLoaded = useAppSelector<any>((state) => state.momentum?.prize?.loaded);
  const authentication = useAppSelector((state) => state.authentication);

  const getTournamentGroups = () => {
    if (!allTournamentGroups) return;

    const resGr: any = [];
    if (allTournamentGroups && Array.isArray(allTournamentGroups)) {
      allTournamentGroups.forEach((group: any) => {
        if (group && group.tournaments && Array.isArray(group.tournaments)) {
          const tournaments = [...group.tournaments];
          tournaments.sort((a, b) => {
            if (a && a.start_date && b && b.end_date) {
              return parseInt(a.start_date, 10) - parseInt(b.end_date, 10);
            }
            return 0;
          });

          const now = moment().valueOf();
          const activeTournaments = tournaments.filter((t) => {
            if (t && parseInt(t.end_date, 10) > now) return true;
            return false;
          });

          // console.log('debug activeTournaments', { activeTournaments, tournaments });

          if (activeTournaments.length > 0) {
            resGr.push({ type: 'tournament', subtype: group.type, id: group.id });
          }
        }
      });
    }

    setAllLoadedTournaments(resGr);
  };

  React.useEffect(() => {
    if (!momentumPrizeLoaded && ['user', 'token'].indexOf(authentication.auth_type) > -1) {
      dispatch(getMomentumBonuses());
    }
  }, [momentumPrizeLoaded, authentication]);

  React.useEffect((): any => {
    if (!groupsLoaded) {
      dispatch(loadTournamentsGroups());
    } else {
      getTournamentGroups();
    }
  }, [groupsLoaded]);

  React.useEffect(() => {
    if (dsLoaded && dsItems) {
      if (!dsItems['all_jackpots_api'] && !fetchedSources.current) {
        dispatch(fetchSources({ ids: ['all_jackpots_api'] }));
        fetchedSources.current = true;
      }
    }
  }, [dsLoaded, dsItems]);

  const contextValue = React.useMemo(() => {
    const list = [];

    if (prize?.active) {
      if (prize?.bonus_expires_at) {
        const now = new Date().valueOf();
        const prizeExpiresAt = new Date(prize?.bonus_expires_at).valueOf();
        const expired = now > prizeExpiresAt;

        if (!expired) {
          list.push({ type: 'momentum', subtype: '' });
        }
      }
    }

    if (happyHourRunning['online-slots']) {
      list.push({ type: 'happy-hour', subtype: 'online-slots' });
    }

    if (happyHourRunning['bets-prematch']) {
      list.push({ type: 'happy-hour', subtype: 'bets-prematch' });
    }

    if (happyHourRunning['bets-live']) {
      list.push({ type: 'happy-hour', subtype: 'bets-live' });
    }

    if (allLoadedTournaments.length > 0) {
      list.push(...allLoadedTournaments);
    }

    if (
      dsItems &&
      dsItems['all_jackpots_api'] &&
      dsItems['all_jackpots_api'].data &&
      dsItems['all_jackpots_api'].data.length > 0
    ) {
      dsItems['all_jackpots_api'].data.forEach((jackpot: any) => {
        list.push({ type: 'jackpot', subtype: jackpot.provider, id: jackpot.group });
      });
    }

    refresh.current += 1;

    return {
      list,
      refresh: refresh.current,
    };
  }, [dsItems, dsLoaded, allLoadedTournaments, happyHourRunning, prize]);

  //console.log('RewardsCardCarousel[contextValue]', contextValue);

  return (
    <ModuleElementDiv className={componentProps.className ?? ''} $styleText={componentProps.styleText}>
      <DataElementContext.Provider value={contextValue}>{componentProps.children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default RewardsCardCarousel;

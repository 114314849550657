import React from 'react';
import styled from 'styled-components';
import { useAppSelector, useAppDispatch } from '../../../store';
import { DataElementContext } from '../../../page-components/common/DataElementContext';
import ClientAPI from '@/modules/casino/ClientAPI/ClientAPI';
import { processComponentProps } from '@/page-components/utils/processComponentProps';
import {
  Client,
  enableCache,
  API_REGIONS,
  UserRepository,
  MessageRepository,
  ChannelRepository,
  CommunityRepository,
  PostRepository,
  subscribeTopic,
  getCommunityTopic,
  SubscriptionLevels,
  VERSION,
} from '@amityco/ts-sdk';

import './index.scss';

const regions: { [key: string]: string } = {
  eu: API_REGIONS.EU,
};

const AMITY_API_KEY = window.config.AMITY_API_KEY;
const AMITY_API_REGION = regions[window.config.AMITY_API_REGION];
const AMITY_CHANNEL_ID = window.config.AMITY_MAIN_CHANNEL_ID;
const AMITY_COMMUNITY_ID = window.config.AMITY_MAIN_COMMUNITY_ID;

type SocialHubProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: {
    dsType: string;
    defaultTabOpened: string;
  };
};

const defaultProps = {
  className: '',
  styleText: '',
  properties: {
    dsType: '',
  },
};

enum TABS {
  CHAT = 'chat',
  FEED = 'feed',
}

export enum PostType {
  TEXT = 'text',
  IMAGE = 'image',
  VIDEO = 'video',
}

export type SocialHubUser = {
  isConnected: boolean;
  isProfileLoading: boolean;
  userId: string;
  displayName: string;
  avatarUrl: string;
  isGuest: boolean;
  joinedChannelId: string;
};

type FeedMetadata = {
  pinned: {
    text: string;
    type: string;
    postId: string;
  };
};

enum PinnedMessageType {
  TEXT = 'text',
  WARN = 'warning',
  POST = 'post',
}

type PinnedMessage = {
  type: PinnedMessageType;
  text: string;
  postId: string;
  onPostTypeMessageClick: () => void;
};

// -------------------------

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

// Only required to do once in the lifetime of the application
const client = Client.createClient(AMITY_API_KEY, AMITY_API_REGION);
// wipeCache();
enableCache();

// Client.onNetworkActivities((request, response) => {
//   console.log('🐞request', request);
//   console.log('🪲response', response);
// });

const sessionHandler: Amity.SessionHandler = {
  sessionWillRenewAccessToken(renewal: Amity.AccessTokenRenewal) {
    // for details on other renewal methods check session handler
    renewal.renew();
  },
};

const login = (userId: string, displayName: string, avatarCustomUrl?: string) => {
  console.info('🌍🔄️ LOGGING IN TO AMITY...', VERSION);
  console.info('🌍 -> userId', userId);
  console.info('🌍 -> nickname', displayName);
  console.info('🌍 -> avatar', avatarCustomUrl);

  const axios = ClientAPI.getInstance();
  const request = axios({
    url: '/api/social-hub/amity-auth',
    method: 'post',
    data: {
      userId,
    },
  });

  request
    .then((response) => {
      // @ts-ignore
      if (response?.status === 'OK' && response?.result) {
        // @ts-ignore
        const authToken = response.result;

        Client.login(
          {
            userId,
            displayName,
            authToken,
          },
          sessionHandler,
        ).then((success) => {
          if (!success) {
            console.log('🌍❌ ERROR LOGGING IN');
            return;
          }

          console.log('🌍✅ LOGGED IN', success);

          // Update user's avatar
          if (avatarCustomUrl) {
            console.log('🌍🔄️ UPDATING AVATAR...');

            UserRepository.updateUser(userId, {
              avatarCustomUrl,
            }).then((user) => {
              console.log('🌍✅ UPDATED AVATAR', user);
            });
          }
        });
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

const SocialHub = (componentProps: SocialHubProps) => {
  const tmpProps = { ...defaultProps, ...componentProps };
  delete tmpProps.children;
  const { children } = componentProps;
  let props = componentProps;
  const dataElementContext = React.useContext(DataElementContext);
  [props] = processComponentProps(props, dataElementContext);

  const profile = useAppSelector((state) => state.profile);
  const nickname = useAppSelector((state) => state.social.nickname);
  const avatar = useAppSelector((state) => state.social.avatar);

  const defaultTabOpened = props.properties?.defaultTabOpened ?? TABS.CHAT;

  const [selectedTab, setSelectedTab] = React.useState(defaultTabOpened);
  const [oldTab, setOldTab] = React.useState(defaultTabOpened);
  const [selectedNewPostType, setSelectedNewPostType] = React.useState(PostType.TEXT);
  const [newPostSubmissionSuccess, setNewPostSubmissionSuccess] = React.useState(false);
  const [sessionState, setSessionState] = React.useState<Amity.SessionStates>(client.sessionState);
  const [joinedChannelId, setJoinedChannelId] = React.useState('');
  const [channelMembers, setChannelMembers] = React.useState<Amity.LiveCollection<Amity.Membership<'channel'>>>();
  const [channelMessages, setChannelMessages] = React.useState<Amity.LiveCollection<Amity.Message>>();
  const [isFeedJoined, setIsFeedJoined] = React.useState(false);
  const [posts, setPosts] = React.useState<Amity.LiveCollection<Amity.Post>>();
  const postsNextPageFn = React.useRef<() => void | undefined>();
  const [postsHasNextPage, setPostsHasNextPage] = React.useState(false);
  const [selectedPostId, setSelectedPostId] = React.useState('');
  const [feedMetadata, setFeedMetadata] = React.useState<FeedMetadata>();
  const [pinnedMessage, setPinnedMessage] = React.useState<PinnedMessage>();

  const selectedBetsNumber = useAppSelector(
    (state) =>
      (state.bets.betsSlip.tickets[state.bets.betsSlip.currentTicket].live.selected?.length ?? 0) +
      (state.bets.betsSlip.tickets[state.bets.betsSlip.currentTicket].prematch.selected?.length ?? 0),
  );
  const [betSlip, setBetSlip] = React.useState<any>({
    old: selectedBetsNumber,
    new: selectedBetsNumber,
    showTooltip: false,
  });

  React.useEffect(() => {
    setBetSlip((prev: any) => {
      const oldValue = prev.new;
      const newValue = selectedBetsNumber;

      if (oldValue === 0 && newValue > 0) {
        setTimeout(() => {
          setBetSlip((prev: any) => {
            return { ...prev, showTooltip: false };
          });
        }, 3000);
      }

      return {
        old: oldValue,
        new: newValue,
        showTooltip: oldValue === 0 && newValue > 0,
      };
    });
  }, [selectedBetsNumber]);

  const currentUser: SocialHubUser = React.useMemo(() => {
    const userId = profile.client_player_id ? `${window.config.clientId}_${profile.client_player_id}` : 'guest_test';
    return {
      isConnected: sessionState === 'established' && Client.getActiveUser().userId == userId,
      isProfileLoading: profile.loading,
      userId,
      displayName: nickname?.nickname || nickname?.default_nickname || 'Guest',
      avatarUrl: avatar?.url || '',
      isGuest: userId == 'guest_test',
      joinedChannelId,
    };
  }, [
    avatar?.url,
    joinedChannelId,
    nickname?.default_nickname,
    nickname?.nickname,
    profile.client_player_id,
    profile.loading,
    sessionState,
  ]);

  //# Detect session state changes on Amity client instance
  React.useEffect(() => {
    return Client.onSessionStateChange((state: Amity.SessionStates) => {
      setSessionState(state);
      console.log('🌍SESSION STATE CHANGED:', state);
    });
  }, [currentUser.userId, sessionState]);

  //# Detect user changes for re-authentication
  React.useEffect(() => {
    if (currentUser.isProfileLoading || currentUser.isConnected || sessionState === 'establishing') return;
    login(currentUser.userId, currentUser.displayName, currentUser.avatarUrl);
  }, [currentUser, sessionState]);

  const onChangeTab = React.useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      if (e.currentTarget.dataset.tab != null && e.currentTarget.dataset.tab !== '') {
        const tab = e.currentTarget.dataset.tab as TABS;
        setOldTab(selectedTab);
        setSelectedTab(tab);
        setSelectedPostId('');
      }
    },
    [selectedTab],
  );

  //#--------------------------- CHAT ---------------------------

  //# Join Amity channels
  React.useEffect(() => {
    if (!currentUser.isConnected) return;

    console.log('💬🔄️ JOINING CHANNEL...');

    // Join main public channel (for chat messages)
    ChannelRepository.joinChannel(AMITY_CHANNEL_ID).then((isJoined) => {
      console.log('💬✅ JOINED CHANNEL', isJoined);
      setJoinedChannelId(AMITY_CHANNEL_ID);
    });
  }, [currentUser.isConnected]);

  //# Channel members info sync
  React.useEffect(() => {
    if (!joinedChannelId) return;

    const unsubscribe = ChannelRepository.Membership.getMembers(
      { channelId: AMITY_CHANNEL_ID, memberships: ['member', 'muted', 'banned'], limit: 100 }, // TODO: members pagination
      (members) => {
        console.log(members.loading ? '💬🔄️ LOADING MEMBER INFO...' : '💬✅ MEMBER INFO LOADED', members);
        setChannelMembers(members);
      },
    );

    return () => {
      console.log('💬⭕ SH: UNSUBSCRIBING OBSERVER FOR MEMBERS...');
      unsubscribe();
    };
  }, [joinedChannelId]);

  //# Messages sync
  React.useEffect(() => {
    if (!joinedChannelId) return;

    const unsubscribe = MessageRepository.getMessages({ subChannelId: AMITY_CHANNEL_ID, limit: 50 }, (messages) => {
      console.log(messages.loading ? '💬🔄️ LOADING MESSAGES...' : '💬✅ MESSAGES LOADED', messages);
      //@ts-ignore
      setChannelMessages(messages);
    });

    return () => {
      console.log('💬⭕ SH: UNSUBSCRIBING OBSERVER FOR CHAT MESSAGES CHANNEL...');
      unsubscribe();
    };
  }, [joinedChannelId]);

  //#--------------------------- FEED ---------------------------

  //# Join community
  React.useEffect(() => {
    if (!currentUser.isConnected) return;

    console.log('📣🔄️ JOINING COMMUNITY...');

    CommunityRepository.joinCommunity(AMITY_COMMUNITY_ID).then((isJoined) => {
      console.log('📣✅ JOINED COMMUNITY', isJoined);
      setIsFeedJoined(isJoined);
    });
  }, [currentUser.isConnected]);

  //# Feed posts sync
  React.useEffect(() => {
    if (!isFeedJoined) return;

    const disposers: Amity.Unsubscriber[] = [];

    const unsub = PostRepository.getPosts(
      { targetId: AMITY_COMMUNITY_ID, targetType: 'community', limit: 10 },
      (posts) => {
        console.log(posts.loading ? '📣🔄️ LOADING POSTS...' : '📣✅ POSTS LOADED', posts);
        setPosts(posts);

        if (!posts.loading) {
          const hashParams = window.location.hash.substring(1);
          const params = new URLSearchParams(hashParams);
          const hashPostId = params.get('postId');
          setPostsHasNextPage(posts.hasNextPage ?? false);
          postsNextPageFn.current = posts.onNextPage;

          if (hashPostId) {
            console.log('📣🤙 DIRECT LINK TO SHARED POST!', hashPostId);
            setSelectedTab(TABS.FEED);
            setSelectedPostId(hashPostId);
            window.history.replaceState(null, '', window.location.pathname + window.location.search);
          }
        }

        // Subscribe to post updates
        CommunityRepository.getCommunity(AMITY_COMMUNITY_ID, (community) => {
          if (community.loading) return;

          setFeedMetadata(community.data.metadata as FeedMetadata);

          if (disposers.length == 0)
            disposers.push(subscribeTopic(getCommunityTopic(community.data, SubscriptionLevels.POST), (params) => {}));
        });
      },
    );
    disposers.push(unsub);
    // unsub();

    return () => {
      console.log('📣⭕ SH: UNSUBSCRIBING OBSERVERS FOR FEED...', disposers);
      disposers.forEach((fn) => fn());
    };
  }, [isFeedJoined]);

  //# Extract the pinned message data from the Amity feed metadata
  React.useEffect(() => {
    if (feedMetadata?.pinned) {
      setPinnedMessage({
        type: feedMetadata.pinned.type as PinnedMessageType,
        text: feedMetadata.pinned.text,
        postId: feedMetadata.pinned.postId,
        onPostTypeMessageClick: () => {
          setSelectedTab(TABS.FEED);
          setSelectedPostId(feedMetadata.pinned.postId);
        },
      });
    }
  }, [feedMetadata]);

  //# Posts lazy loading (when scrolling to the bottom of the feed)
  const lastPostRef = React.useRef<HTMLElement>();
  React.useEffect(() => {
    if (selectedPostId) return;

    const els = document.querySelectorAll('.wl-social-hub-feed-post');
    if (!els.length) return;
    lastPostRef.current = els[els.length - 1] as HTMLElement;

    const options = {
      rootMargin: '0px',
      threshold: 0.5,
    };

    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting && postsHasNextPage) {
        console.log('🪼 LAZY LOADING MORE POSTS...', entry);
        postsNextPageFn.current?.();
      }
    }, options);

    if (lastPostRef.current) {
      observer.observe(lastPostRef.current);
    }

    return () => {
      if (lastPostRef.current) {
        observer.unobserve(lastPostRef.current);
      }
    };
  }, [selectedTab, postsNextPageFn, posts, postsHasNextPage, selectedPostId]);

  //--------------------------- END ---------------------------

  const contextValue = React.useMemo(() => {
    return {
      oldTab,
      selectedTab,
      setSelectedTab,
      onChangeTab,
      currentUser,
      pinnedMessage,

      // Chat
      channelMembers,
      channelMessages,

      // Feed
      posts:
        posts?.data.map((post) => {
          return {
            ...post,
          };
        }) || [],
      // postsLoading: posts?.loading ?? false,
      selectedPostId,
      setSelectedPostId,
      selectedNewPostType,
      setSelectedNewPostType,
      newPostSubmissionSuccess,
      setNewPostSubmissionSuccess,

      betSlipSelectedOld: betSlip.old,
      betSlipSelectedNew: betSlip.new,
      betSlipShowTooltip: betSlip.showTooltip,
    };
  }, [
    oldTab,
    selectedTab,
    onChangeTab,
    currentUser,
    pinnedMessage,
    channelMembers,
    channelMessages,
    posts?.data,
    selectedPostId,
    betSlip,
    selectedNewPostType,
    newPostSubmissionSuccess,
  ]);
  //console.log('SocialHub[contextValue]', contextValue);

  return (
    <ModuleElementDiv className={props.className ?? ''} $styleText={props.styleText}>
      <DataElementContext.Provider value={contextValue}>{componentProps.children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default SocialHub;

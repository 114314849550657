import React from 'react';
import styled from 'styled-components';
import { useMatches } from 'react-router-dom';

import { useAppSelector, useAppDispatch } from '../../../../../store';
import { DataElementContext } from '../../../../../page-components/common/DataElementContext';
import { betsSlipAddLottoTicket } from '../../../../../modules/lotto/store/actions/betsSlip';
import moment from 'moment';

type LottoBetslipBodyProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: {
    dsType: string;
  };
};

const defaultProps = {
  className: '',
  styleText: '',
  properties: {
    dsType: '',
  },
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const LottoBetslipBody = (componentProps: LottoBetslipBodyProps) => {
  const tmpProps = { ...defaultProps, ...componentProps };
  delete tmpProps.children;
  const props = JSON.parse(JSON.stringify(tmpProps));
  const { children } = componentProps;

  const matches = useMatches();
  const dispatch = useAppDispatch();
  const lottoEvents = useAppSelector((state) => state.lotto.lottoEvents.items);
  const lottoSystems = useAppSelector((state) => state.lotto.lottoEvents.systems);
  const initialized = useAppSelector((state) => state.lotto.lottoEvents.initialized);
  const loading = useAppSelector((state) => state.lotto.lottoEvents.loading);
  const bonuses = useAppSelector((state) => state.lotto.bonuses.items);
  const lottoTicket = useAppSelector((state) => state.lotto.betsSlip.lottoTicket);

  let defaultSelectedNextEvents = 0;
  let defaultSelectedEventsDates: string[] = [];
  if (lottoTicket?.src === 'details') {
    if (lottoTicket?.selectedEvents?.length) {
      defaultSelectedEventsDates = lottoTicket?.selectedEvents.map((event: any) => event.event_date);
    }
    if (lottoTicket?.additionalEvents != null && lottoTicket?.additionalEvents > 0) {
      defaultSelectedNextEvents = lottoTicket?.additionalEvents;
    }
  }

  const [selectedEventsDates, setSelectedEventDates] = React.useState<string[]>(defaultSelectedEventsDates);
  const [selectedNextEvents, setSelectedNextEvents] = React.useState(defaultSelectedNextEvents);

  // try to initialize systemId from the properties object
  const systemId = lottoTicket?.event?.system_id ?? '';
  const eventId = lottoTicket?.event?.event_id;

  const event = lottoEvents.find((event) => event.system_id === systemId);

  let eligibleLuckyNumber = false;
  if (bonuses?.length) {
    bonuses.find((bonus) => {
      if (bonus.lottery_systems.indexOf(systemId) > -1) {
        eligibleLuckyNumber = true;
        return true;
      }
      return false;
    });
  }

  // choose a lotto event date (from the first 5 visible ones)
  const onEventDateClick = React.useCallback((e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    if (e?.currentTarget?.dataset?.date) {
      const date = e.currentTarget.dataset.date;
      setSelectedEventDates((v) => {
        if (v.includes(date)) {
          return v.filter((d) => d !== date);
        }
        return [...v, date];
      });
    }
  }, []);

  // choose how many lotto events to select after the first 5 visible ones
  const onSelectedNextEventRange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    if (e?.target?.value != null) {
      setSelectedNextEvents(parseInt(e.target.value, 10));
    }
  }, []);

  React.useEffect(() => {
    if (selectedEventsDates.length) {
      const now = moment().format('YYYY-MM-DD HH:mm:ss');
      const events = lottoEvents.filter((event) => event.system_id === systemId && event.event_date >= now);
      if (events.length) {
        // if there are selected dates, filter them to only include dates that are
        // after the first event date
        const event_date = events[0].event_date;
        const remaining = selectedEventsDates.filter((date) => date >= event_date);

        if (remaining.length !== selectedEventsDates.length) {
          if (remaining.length === 0) {
            remaining.push(event_date);
          }

          setSelectedEventDates(remaining);
        }
      }
    }
  }, [systemId, selectedEventsDates, lottoEvents]);

  React.useEffect(() => {
    if (event) {
      const now = moment().format('YYYY-MM-DD HH:mm:ss');
      const events = lottoEvents.filter((e) => e.system_id === event.system_id && e.event_date >= now);

      const selectedEvents = events.filter((e) => selectedEventsDates.indexOf(e.event_date) > -1);
      const originalSelectedEvents = [...selectedEvents];

      if (selectedNextEvents > 0) {
        const nextEvents = events.slice(5);
        for (let i = 0; i < selectedNextEvents; i++) {
          selectedEvents.push(nextEvents[i]);
        }
      }

      const allSelectedEvents = selectedEvents.map((e) => e.event_id);
      const allSelectedEventsFull = [...selectedEvents];

      const ticketData = {
        src: 'betslip',
        selectedEvents: originalSelectedEvents,
        additionalEvents: selectedNextEvents,
        allSelectedEvents: allSelectedEvents,
        allSelectedEventsFull: allSelectedEventsFull,
      };

      dispatch(betsSlipAddLottoTicket(ticketData));
    }
  }, [selectedEventsDates, selectedNextEvents, lottoEvents, event]);

  React.useEffect(() => {
    const length = lottoTicket?.numbers?.length ?? 0;
    if (length === 0) {
      setSelectedEventDates([]);
      setSelectedNextEvents(0);
    }
  }, [lottoTicket?.numbers?.length]);

  React.useEffect(() => {
    if (systemId && eventId) {
      const now = moment().format('YYYY-MM-DD HH:mm:ss');

      let found = false;
      let events = lottoEvents;

      if (eventId) {
        events = lottoEvents.filter((event: any) => {
          if (event.event_id === eventId) {
            found = true;
          }
          return found;
        });
      }

      if (!found) {
        events = lottoEvents;
      }

      events = events.filter((event) => event.system_id === systemId && event.event_date >= now);

      if (events.length) {
        if (selectedEventsDates.length === 0) {
          setSelectedEventDates([events[0]?.event_date ?? '']);
        } else {
          const eventDatesHash: any = {};
          events.slice(0, 5).forEach((event) => {
            eventDatesHash[event.event_date] = true;
          });

          const remainingDates: any = [];
          selectedEventsDates.forEach((date) => {
            if (eventDatesHash[date]) {
              remainingDates.push(date);
            }
          });

          if (remainingDates.length) {
            setSelectedEventDates(remainingDates);
          } else {
            setSelectedEventDates([events[0]?.event_date ?? '']);
          }
        }
      }
    }
  }, [systemId, eventId]);

  const onToggleSystem = React.useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      if (e?.currentTarget?.dataset?.id) {
        if (lottoTicket) {
          const id = parseInt(e.currentTarget.dataset.id, 10);
          const systems = [...(lottoTicket?.systems ?? [])];
          if (systems.indexOf(id) !== -1) {
            systems.splice(systems.indexOf(id), 1);
          } else {
            systems.push(id);
          }
          dispatch(betsSlipAddLottoTicket({ src: 'betslip', systems }));
        }
      }
    },
    [lottoTicket],
  );

  const contextValue = React.useMemo(() => {
    const now = moment().format('YYYY-MM-DD HH:mm:ss');

    let found = false;
    let events = lottoEvents;

    if (eventId) {
      events = lottoEvents.filter((event: any) => {
        if (event.event_id === eventId) {
          found = true;
        }
        return found;
      });
    }

    if (!found) {
      events = lottoEvents;
    }

    events = events.filter((event) => event.system_id === systemId && event.event_date >= now);
    const event = events.length ? JSON.parse(JSON.stringify(events[0])) : null;

    //console.log('LottoBetslipBody[contextValue]', { events, event });

    const visibleEvents = events.splice(0, 5);
    const startDate = events[0]?.event_date ?? '';
    let endDate = events[events.length - 1]?.event_date ?? '';
    if (events.length > 45) {
      endDate = events[45].event_date;
    }

    const numbers = [];

    for (let i = 0; i < 18; i++) {
      let value = 0;
      if (lottoTicket?.numbers?.[i]) {
        value = lottoTicket.numbers[i];
      }

      numbers.push({
        value: value ? value : '-',
        lucky: value.toString() === lottoTicket?.extra?.luckyNumber?.toString(),
        o: i >= event?.k,
      });
    }

    let maxSystems = event?.m ?? 0;
    if (lottoTicket?.numbers?.length < maxSystems) {
      maxSystems = lottoTicket.numbers.length;
    }

    const selectedSystems: any = {};
    (lottoTicket?.systems ?? []).forEach((system: number) => {
      selectedSystems[system] = true;
    });

    const systems = [...Array(maxSystems).keys()].map((i) => {
      return {
        id: i + 1,
        total: lottoTicket?.numbers?.length ?? 0,
        selected: selectedSystems[i + 1] ?? false,
      };
    });

    return {
      visibleEvents: visibleEvents,
      remainingStartDate: startDate,
      remainingEndDate: endDate,
      totalRemaining: events.length > 45 ? 45 : events.length,
      selectedEventsDates,
      selectedNextEvents,
      totalDraws: selectedEventsDates?.length + selectedNextEvents ?? 0,
      onEventDateClick,
      onSelectedNextEventRange,
      n: event?.n ?? 0,
      k: event?.k ?? 0,
      m: event?.m ?? 0,
      r: event?.r ?? 0,
      eventName: event?.event_name ?? '',

      numbers: numbers,
      selectedNumbers: lottoTicket?.numbers?.length ?? 0,
      luckyNumber: lottoTicket?.extra?.luckyNumber ?? 0,
      systems,
      onToggleSystem,
    };
  }, [
    componentProps,
    lottoTicket,
    lottoEvents,
    lottoSystems,
    initialized,
    loading,
    bonuses,
    onEventDateClick,
    onSelectedNextEventRange,
    selectedEventsDates,
    selectedNextEvents,
    eligibleLuckyNumber,
    onToggleSystem,
    eventId,
  ]);

  //console.log('LottoBetslipBody[contextValue]', contextValue);

  return (
    <ModuleElementDiv className={props.className ?? ''} $styleText={props.styleText}>
      <DataElementContext.Provider value={contextValue}>{children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default LottoBetslipBody;

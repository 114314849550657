import React, { useEffect } from 'react';
import styled from 'styled-components';
import IBAN from 'iban';

import { useAppSelector, useAppDispatch } from '../../../store';
import { DataElementContext } from '../../../page-components/common/DataElementContext';

import './index.scss';
import { getTax } from '../../../modules/casino/store/actions';
import { getPaymentMethods, linkIban, withdraw } from '@/modules/casino/store/actions/withdrawals';
import { paymentProviderNameByWithdrawId, PaymentProviderWithdraw } from '@/constants/paymentProvider';
import { formatCurrency } from '@/modules/bets/utils/formatters';
import { requestDocuments } from '@/modules/casino/store/actions/documents';
import { receivedWithdraw } from '@/modules/casino/store/actions/withdraw_wizard';
import { useTranslation } from 'react-i18next';

type WithdrawProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: {
    dsType: string;
  };
};

type ModuleStateProps = {
  maxWithdrawLimit: number | string;
  withdrawValue: number;
  limitError: string | boolean;
  withdrawValueNoTax: number;
  currentStep: string;
  lastStep: string;
  withdrawType: number;
  totalBonus?: number;
  selectedWithdrawType?: any;
  initialized: boolean;
  paymentMethods: any[];
  error?: string;
  isOpen?: boolean;
  loading?: boolean;
  iban: {
    details: {
      iban: string;
      beneficiaryName: string;
      friendlyName: string;
    };
    errors: {
      iban: boolean;
      beneficiaryName: boolean;
      friendlyName: boolean;
    };
  };
};

interface Hash {
  [key: string]: string;
}

const defaultProps = {
  className: '',
  styleText: '',
  properties: {
    dsType: '',
  },
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const Withdraw = (componentProps: WithdrawProps) => {
  const tmpProps = { ...defaultProps, ...componentProps };
  delete tmpProps.children;
  const props = JSON.parse(JSON.stringify(tmpProps));
  const { children } = componentProps;

  const ERRORS = {
    MINIMUM_LIMIT: 'WITHDRAWALS_AMOUNT_ERROR_MINIMUM_LIMIT',
    MAXIMUM_LIMIT: 'WITHDRAWALS_AMOUNT_ERROR_MAXIMUM_LIMIT_{{MAX}}',
  };

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const wallet = useAppSelector((state) => state.wallet);
  const freeBets = useAppSelector((state) => state.freeBets.freeBets);
  const taxes = useAppSelector((state) => state.withdrawWizard.taxes);
  const { errorCode, transactionId } = useAppSelector((state) => state.withdrawWizard);
  const profile = useAppSelector((state) => state.profile);
  const documents = useAppSelector((state) => state.documents);
  const paymentMethods = useAppSelector((state) => state.withdrawals.paymentMethods);
  const currency = formatCurrency(wallet?.currency);

  const getMaxLimit = () => {
    let max = window.config.currency_settings.DEFAULT.WITHDRAW_LIMITS?.RON?.max ?? 0;
    if (max > wallet.main) {
      max = wallet.main;
    }
    return max;
  };

  const initialState = {
    withdrawValue: 0,
    limitError: false,
    withdrawValueNoTax: 0,
    currentStep: 'default',
    lastStep: 'default',
    withdrawType: 0,
    totalBonus: 0,
    selectedWithdrawType: null,
    initialized: false,
    paymentMethods: [],
    error: '',
    isOpen: false,
    loading: false,
    iban: {
      details: {
        iban: '',
        beneficiaryName: '',
        friendlyName: '',
      },
      errors: {
        iban: false,
        beneficiaryName: false,
        friendlyName: false,
      },
    },
    maxWithdrawLimit: getMaxLimit(),
  };

  const [state, _setState] = React.useState<ModuleStateProps>(initialState);

  const stateRef = React.useRef(state);

  const setState = (data: any) => {
    stateRef.current = data;
    _setState(data);
  };
  React.useEffect(() => {
    setState((v: any) => ({
      ...v,
      ...initialState,
    }));
    dispatch(receivedWithdraw({ reset: true }));
    // dispatch(requestBPCardToken());
    if (profile?.client_player_id) {
      init();
    }
    return () => {
      dispatch(receivedWithdraw({ reset: true }));
    };
  }, []);

  React.useEffect(() => {
    if (profile?.client_player_id) {
      init();
    }
  }, [profile]);

  React.useEffect(() => {
    let documentsOK = true;
    if (documents) {
      if (documents.documentsRequest) {
        const docs = documents.documentsRequest;
        if (docs.length > 0) {
          for (let i = 0; i < docs.length; i++) {
            if (docs[i].status !== 8 /*&& docs[i].type === 1*/) {
              documentsOK = false;
              break;
            }
          }
        }
      }
    }
    if (!documentsOK) {
      setState((v: any) => ({
        ...v,
        currentStep: 'documents',
        lastStep: v.currentStep,
      }));
    }
  }, [documents]);

  React.useEffect(() => {
    let pm: any[] = [];
    const iban = state.iban.details.iban.replace(/\s/g, '');
    if (paymentMethods.length > 0) {
      pm = paymentMethods.reduce((acc, item) => {
        const type: number = parseInt(item.withdrawType, 10);
        if (!acc[type]) {
          acc[type] = {
            type: type,
            details: [],
          };
        }

        acc[type].details.push({
          ...item.withdrawMethodDetails,
          reference: item.withdrawTypeReference,
        });

        if (type === PaymentProviderWithdraw.iban && iban) {
          if (item.withdrawMethodDetails?.iban === iban) {
            setState((v: any) => ({
              ...v,
              selectedWithdrawType: item.withdrawTypeReference,
            }));
          }
        }

        return acc;
      }, []);
    }

    if (props.properties.forceBankTransfer) {
      if (!pm[PaymentProviderWithdraw.iban]) {
        pm[PaymentProviderWithdraw.iban] = {
          type: PaymentProviderWithdraw.iban,
          details: [],
          add: true,
        };
      } else {
        pm[PaymentProviderWithdraw.iban].add = true;
      }
    }
    if (props.properties.forceSkrill) {
      if (!pm[PaymentProviderWithdraw.skrill]) {
        pm[PaymentProviderWithdraw.skrill] = {
          type: PaymentProviderWithdraw.skrill,
          details: [],
          add: true,
        };
      } else {
        pm[PaymentProviderWithdraw.skrill].add = true;
      }
    }

    setState((v: any) => ({
      ...v,
      paymentMethods: pm,
    }));
    if (state.currentStep === 'default') {
      let newProvider = null;
      if (paymentMethods.length > 0) {
        newProvider = paymentMethods[0].withdrawType;
      }
      changePaymentProvider(newProvider);
    }
  }, [paymentMethods]);

  React.useEffect(() => {
    let totalBonus = wallet.bonus ? wallet.bonus : 0;
    freeBets.forEach((fb) => {
      totalBonus += fb.count * (fb.amount_small === 1 ? fb.amount / 100 : fb.amount);
    });

    if (totalBonus > 0) {
      setState((v: any) => ({
        ...v,
        currentStep: 'bonus-active',
        lastStep: v.currentStep,
        totalBonus: totalBonus,
      }));
    } else {
      setState((v: any) => ({
        ...v,
        totalBonus: totalBonus,
      }));
    }
  }, [wallet, freeBets]);

  React.useEffect(() => {
    if (state.currentStep === 'taxes' && taxes == 0) {
      acceptTaxes();
    }
  }, [state.currentStep]);

  React.useEffect(() => {
    setState((v: any) => ({
      ...v,
      error: errorCode !== null ? errorCode : '',
    }));
  }, [errorCode]);

  React.useEffect(() => {
    if (state.error !== '') {
      if (state.error) {
        setState((v: any) => ({
          ...v,
          currentStep: 'error',
          lastStep: v.currentStep,
          loading: false,
        }));
      } else {
        setState((v: any) => ({
          ...v,
          currentStep: v.currentStep === 'add-bank-transfer' ? 'bank-transfer' : 'success',
          lastStep: v.currentStep,
          loading: false,
          error: '',
        }));
      }
    }
  }, [state.error]);

  React.useEffect(() => {
    if (transactionId) {
      if (!errorCode) {
        setState((v: any) => ({
          ...v,
          currentStep: 'success',
          lastStep: v.currentStep,
          loading: false,
        }));
      }
    }
  }, [transactionId]);

  const init = () => {
    if (state.initialized) {
      return;
    }
    setState((v: any) => ({
      ...v,
      initialized: true,
    }));
    if (profile?.verified) {
      dispatch(requestDocuments());
      dispatch(getPaymentMethods());
    } else {
      setState((v: any) => ({
        ...v,
        currentStep: 'kyc',
        lastStep: v.currentStep,
      }));
    }
  };

  const acceptTaxes = () => {
    const st = {
      selectedWithdrawType: state.paymentMethods[state.withdrawType]?.details?.[0]?.reference,
    };
    // @ts-ignore
    switch (parseInt(state.withdrawType, 10)) {
      case PaymentProviderWithdraw.viva:
        setState((v: any) => ({
          ...v,
          ...st,
          currentStep: 'viva',
          lastStep: v.currentStep,
          modalOpen: false,
        }));
        break;
      case PaymentProviderWithdraw.iban:
        setState((v: any) => ({
          ...v,
          ...st,
          currentStep: 'bank-transfer',
          lastStep: v.currentStep,
          modalOpen: false,
        }));
        break;
      case PaymentProviderWithdraw.skrill:
        setState((v: any) => ({
          ...v,
          ...st,
          currentStep: 'skrill',
          lastStep: v.currentStep,
          modalOpen: false,
        }));
        break;
      default:
        break;
    }
  };

  const onAmountButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const el = e.currentTarget ?? (e.currentTarget as HTMLButtonElement);
    // get data-value from button
    const value = el.getAttribute('data-value');
    if (value) {
      withdrawValueChangeFunction(value);
    }
  };

  const withdrawValueChangeFunction = (value: any) => {
    value = parseInt(value, 10);
    if (isNaN(value)) {
      value = 0;
    }
    setState((v: any) => ({
      ...v,
      withdrawValue: value,
      limitError: getLimitError(value),
    }));
    dispatch(getTax(value, 1)); // 1 is withdrawal type
  };

  const getLimitError = (value: number) => {
    const min = window.config.currency_settings?.DEFAULT?.WITHDRAW_LIMITS?.RON?.min ?? 0;

    const max = getMaxLimit();
    if (state.maxWithdrawLimit !== max) {
      setState((v: any) => ({
        ...v,
        maxWithdrawLimit: max,
      }));
    }

    // @ts-ignore
    return value < min ? ERRORS.MINIMUM_LIMIT : value > max ? `${ERRORS.MAXIMUM_LIMIT}` : false;
  };

  const onChangeWithdrawValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const el = e.currentTarget ?? (e.currentTarget as HTMLInputElement);
    const value = el.value;
    withdrawValueChangeFunction(value);
  };

  const getButtonValues = () => {
    return [
      {
        value: 20,
        valueRendered: `20 ${currency}`,
        id: 1,
        onClickHandler: onAmountButtonClick,
        active: state.withdrawValue === 20,
        className: 'buttons-grid-3',
        description: '',
      },
      {
        value: 50,
        valueRendered: `50 ${currency}`,
        id: 3,
        onClickHandler: onAmountButtonClick,
        active: state.withdrawValue === 50,
        className: 'buttons-grid-3',
        description: '',
      },
      {
        value: 100,
        valueRendered: `100 ${currency}`,
        id: 4,
        onClickHandler: onAmountButtonClick,
        active: state.withdrawValue === 100,
        className: 'buttons-grid-3',
        description: '',
      },
      {
        value: 200,
        valueRendered: `200 ${currency}`,
        id: 5,
        onClickHandler: onAmountButtonClick,
        active: state.withdrawValue === 200,
        className: 'buttons-grid-3',
        description: '',
      },
      {
        value: 300,
        valueRendered: `300 ${currency}`,
        id: 6,
        onClickHandler: onAmountButtonClick,
        active: state.withdrawValue === 300,
        className: 'buttons-grid-3',
        description: '',
      },
      {
        value: 500,
        valueRendered: `500 ${currency}`,
        id: 7,
        onClickHandler: onAmountButtonClick,
        active: state.withdrawValue === 500,
        className: 'buttons-grid-3',
        description: '',
      },
    ];
  };

  const changePaymentProvider = (withdrawType: number) => {
    if (state.withdrawType == withdrawType) {
      setState({
        ...state,
        isOpen: !state.isOpen,
      });
      return;
    }
    setState((v: any) => ({
      ...v,
      withdrawType: withdrawType,
      isOpen: true,
    }));
  };

  const confirmValue = () => {
    setState((v: any) => ({
      ...v,
      currentStep: 'taxes',
      lastStep: v.currentStep,
    }));
  };

  const onWithdraw = () => {
    setState((v: any) => ({
      ...v,
      loading: true,
    }));
    if (state.currentStep === 'add-bank-transfer') {
      // iban without spaces
      const iban = state.iban?.details?.iban?.replace(/\s/g, '') ?? '';

      dispatch(linkIban(iban, state.iban.details.beneficiaryName, state.iban.details.friendlyName));
    } else {
      dispatch(withdraw(state.withdrawValue, state.withdrawType, state.selectedWithdrawType));
    }
  };

  const getPaymentProviderDetails = () => {
    const data: any = [];
    // @ts-ignore
    const type = parseInt(state.withdrawType, 10);
    state.paymentMethods[type]?.details?.forEach((item: any) => {
      switch (type) {
        case PaymentProviderWithdraw.viva:
          let cardNumber = item.cardNumber;
          // format cardNumber to be displayed as **** **** **** 1234
          const cardNumberRaw = cardNumber?.replace(/\s/g, '');
          if (cardNumberRaw?.length === 16) {
            // replace first chars with *
            cardNumber = cardNumberRaw?.replace(/.{12}/, '**** **** **** ') ?? '';
          }

          if (cardNumber === '') {
            cardNumber = '**** **** **** ****';
          }

          data.push({
            name: cardNumber,
            reference: item.reference,
            description: item.cardIssuingBank,
            verified: item.verified,
            selected: state.selectedWithdrawType === item.reference,
            onClick: () => {
              setState((v: any) => ({
                ...v,
                selectedWithdrawType: item.reference,
              }));
            },
          });
          break;
        case PaymentProviderWithdraw.iban:
          data.push({
            name: item.friendlyName,
            reference: item.reference,
            description: item.iban,
            selected: state.selectedWithdrawType === item.reference,
            onClick: () => {
              setState((v: any) => ({
                ...v,
                selectedWithdrawType: item.reference,
              }));
            },
          });
          break;
        case PaymentProviderWithdraw.skrill:
          data.push({
            name: item.email,
            reference: item.reference,
            description: '',
            selected: state.selectedWithdrawType === item.reference,
            onClick: () => {
              setState((v: any) => ({
                ...v,
                selectedWithdrawType: item.reference,
              }));
            },
          });
          break;
      }
    });

    return data;
  };
  let buttonDisabled = state.withdrawValue === 0 || state.error !== '' || state.limitError;
  if (state.currentStep !== 'default') {
    buttonDisabled = state.selectedWithdrawType === null;

    switch (state.currentStep) {
      case 'bank-transfer':
        buttonDisabled = buttonDisabled || !state.paymentMethods[state.withdrawType]?.details?.[0]?.reference;
        break;
      case 'add-bank-transfer':
        buttonDisabled =
          buttonDisabled ||
          !state.iban.details.iban ||
          !state.iban.details.beneficiaryName ||
          state.iban.errors.iban ||
          state.iban.errors.beneficiaryName;
        break;
      default:
        break;
    }
  }
  const contextValue = {
    ...state,
    walletAmount: wallet?.main,
    totalBonusAmount: state.totalBonus + ` ${currency}`,
    currency: currency,
    taxAmount: taxes ? taxes + ` ${currency}` : false,
    onChangeWithdrawValue: onChangeWithdrawValue,
    modalOpen: true,

    onBackHandler: () => {
      let backStep: string;
      switch (state.currentStep) {
        case 'add-bank-transfer':
          backStep = 'bank-transfer';
          break;
        default:
          backStep = 'default';
          break;
      }

      setState((v: any) => ({
        ...v,
        currentStep: backStep,
        lastStep: v.currentStep,
      }));
    },

    acceptTaxesHandler: () => {
      acceptTaxes();
    },
    onToggleEmptyFn: () => {
      // empty function
    },
    paymentProviderList: state.paymentMethods.map((provider) => {
      return {
        id: provider.paymentProviderId,
        currentStep: `amount-${paymentProviderNameByWithdrawId[provider.type]?.toLowerCase()}`,
        buttons: getButtonValues(),
        selectedBonusInfo: null,
        bonusCounter: 0,
        taxValue: taxes,
        currency: currency,
        value: state.withdrawValue,
        error: state.limitError ?? state.error,
        changePaymentProvider: () => {
          changePaymentProvider(provider.type);
        },
        isOpen: state.withdrawType == provider.type && state.isOpen,
        onValueChange: onChangeWithdrawValue,
        confirmButton: {
          state: buttonDisabled || state.loading ? 'disabled' : 'default',
          colorMapping: buttonDisabled ? 'inverted' : 'primary',
          buttonType: 'filled',
          size: 'small',
          style: 'text',
          shadow: buttonDisabled ? 'none' : 'depth_2',
          loading: state.loading,
          ctaText: 'Deposit',
          icon: '',
          onClick: buttonDisabled ? () => 0 : confirmValue,
        },
      };
    }),
    paymentProviderDetails: getPaymentProviderDetails(),
    onWithdraw: buttonDisabled ? () => 0 : onWithdraw,
    addNewIban: () => {
      setState((v: any) => ({
        ...v,
        currentStep: 'add-bank-transfer',
        lastStep: v.currentStep,
      }));
    },
    iban: state.iban.details.iban,
    beneficiaryName: state.iban.details.beneficiaryName,
    friendlyName: state.iban.details.friendlyName,
    ibanError: state.iban.errors.iban,
    beneficiaryNameError: state.iban.errors.beneficiaryName,
    friendlyNameError: state.iban.errors.friendlyName,
    onBeneficiaryNameChange: (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event?.target?.value;
      // check if value is from 2 words. minimum 2 characters each. only letters
      if (!/^[a-zA-Z]{2,} [a-zA-Z]{2,}$/.test(value)) {
        setState((v: any) => ({
          ...v,
          iban: {
            ...v.iban,
            errors: {
              ...v.iban.errors,
              beneficiaryName: 'IBAN_INVALID_BENEFICIARY_NAME',
              iban: v.iban.details.iban ? v.iban.errors.iban : false,
            },
            details: {
              ...v.iban.details,
              beneficiaryName: value,
            },
          },
        }));
      } else {
        setState((v: any) => ({
          ...v,
          iban: {
            ...v.iban,
            errors: {
              ...v.iban.errors,
              beneficiaryName: false,
              iban: v.iban.details.iban ? v.iban.errors.iban : false,
            },
            details: {
              ...v.iban.details,
              beneficiaryName: value,
            },
          },
        }));
      }
    },
    onFriendlyNameChange: (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event?.target?.value;
      // check if value is from  minimum 2 characters
      if (!/^.{2,}$/.test(value)) {
        setState((v: any) => ({
          ...v,
          iban: {
            ...v.iban,
            errors: {
              ...v.iban.errors,
              friendlyName: 'IBAN_INVALID_FRIENDLY_NAME',
            },
          },
        }));
      }
    },
    onIbanChange: (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event?.target?.value?.replace(/[^a-zA-Z0-9]/g, '');
      if (!IBAN.isValid(value)) {
        setState((v: any) => ({
          ...v,
          iban: {
            ...v.iban,
            details: {
              ...v.iban.details,
              iban: value,
            },
            errors: {
              ...v.iban.errors,
              iban: 'INVALID_IBAN',
              beneficiaryName: v.iban.details.beneficiaryName ? v.iban.errors.beneficiaryName : false,
            },
          },
        }));
      } else {
        setState((v: any) => ({
          ...v,
          iban: {
            ...v.iban,
            details: {
              ...v.iban.details,
              iban: IBAN.printFormat(value),
            },
            errors: {
              ...v.iban.errors,
              iban: false,
            },
          },
        }));
      }
    },
    addIbanButton: {
      state: buttonDisabled ? 'disabled' : 'default',
      colorMapping: buttonDisabled ? 'inverted' : 'primary',
      buttonType: 'filled',
      size: 'small',
      style: 'text',
      shadow: buttonDisabled ? 'none' : 'depth_2',
      loading: state.loading,
      ctaText: 'Add IBAN',
      icon: '',
      onClick: buttonDisabled ? () => 0 : onWithdraw,
    },

    confirmButton: {
      state: buttonDisabled || state.loading ? 'disabled' : 'default',
      colorMapping: buttonDisabled ? 'inverted' : 'primary',
      buttonType: 'filled',
      size: 'small',
      style: 'text',
      shadow: buttonDisabled ? 'none' : 'depth_2',
      loading: state.loading,
      ctaText: 'Deposit',
      icon: '',
      onClick: buttonDisabled ? () => 0 : onWithdraw,
    },
    retryFn: () => {
      dispatch(receivedWithdraw({ reset: true }));
      setState((v: any) => ({
        ...v,
        currentStep: v.lastStep !== 'error' && v.lastStep !== 'success' ? v.lastStep : 'default',
        lastStep: v.currentStep,
        loading: false,
      }));
    },
    translatePlaceholders: {
      MAX: `${state.maxWithdrawLimit} ${currency}`,
    },
  };

  return (
    <ModuleElementDiv className={props.className ?? ''} $styleText={props.styleText}>
      <DataElementContext.Provider value={contextValue}>{children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default Withdraw;

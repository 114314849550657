import React, { useMemo } from 'react';
import styled from 'styled-components';
import { DataElementContext } from '../../../../page-components/common/DataElementContext';
import { useMediaQuery } from '../../../utils/useQueryMedia';
import EmojiPicker, { EmojiClickData, EmojiStyle } from 'emoji-picker-react';

import { createPortal } from 'react-dom';

type SocialMessageInputProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: {
    dsType: string;
  };
};

type SocialMessageInputContext = {
  onSendMessage: (messageText: string) => void;
  inputMaxLength: number;
  inputPlaceholder: string;
  emojiPickerHeight: string;
  emojiPickerLazyLoadEmojis: boolean;
  emojiPickerShowPreview: boolean;
  replyingTo: { commentId: string; name: string; message: string };
  editingComment: { commentId: string; message: string };
  onCancelClick: () => void;
};

const defaultProps = {
  className: '',
  styleText: '',
  properties: {
    dsType: '',
  },
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const SocialMessageInput = (componentProps: SocialMessageInputProps) => {
  const tmpProps = { ...defaultProps, ...componentProps };
  delete tmpProps.children;
  const props = JSON.parse(JSON.stringify(tmpProps));
  const { children } = componentProps;

  const dataElementContext: SocialMessageInputContext = React.useContext(DataElementContext);

  const onSendMessage = dataElementContext.onSendMessage;
  const inputMaxLength = dataElementContext.inputMaxLength || 250;
  const inputPlaceholder = dataElementContext.inputPlaceholder || '';
  const emojiPickerHeight = dataElementContext.emojiPickerHeight || '236px';
  const emojiPickerLazyLoadEmojis = dataElementContext.emojiPickerLazyLoadEmojis || true;
  const emojiPickerShowPreview = dataElementContext.emojiPickerShowPreview || false;
  const replyingToName = dataElementContext.replyingTo?.name || '';
  const replyingToMessage = dataElementContext.replyingTo?.message || '';
  const editingMessage = dataElementContext.editingComment?.message || '';
  const onInputCancelClick = dataElementContext.onCancelClick;

  const messageInputRef = React.useRef<HTMLTextAreaElement>(null);
  const [messageText, setMessageText] = React.useState<string>('');
  const [isEmojiPickerOpen, setIsEmojiPickerOpen] = React.useState<boolean>(false);
  const isInputFocused = React.useMemo(() => document.activeElement === messageInputRef.current, []);

  // Recalculate message box height
  React.useEffect(() => {
    if (!messageInputRef.current) return;

    // Dynamically resize the textarea based on the text length, up to 4 lines
    messageInputRef.current.style.height = '';
    const lineHeight = parseFloat(window.getComputedStyle(messageInputRef.current).lineHeight);
    messageInputRef.current.style.height = Math.min(messageInputRef.current.scrollHeight, lineHeight * 4) + 'px';
  }, [messageText]);

  React.useEffect(() => {
    if (replyingToMessage) messageInputRef.current?.focus();
  }, [replyingToMessage]);

  React.useEffect(() => {
    if (!editingMessage) return;

    messageInputRef.current?.focus();
    setMessageText(editingMessage);
  }, [editingMessage]);

  //# EVENT: input change
  const onInputChange = React.useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessageText(e.target.value);
    setIsEmojiPickerOpen(false);
  }, []);

  //# EVENT: emoji picker toggler click
  const onEmojiPickerTogglerClick = React.useCallback(() => {
    setIsEmojiPickerOpen((isEmojiPickerOpen) => !isEmojiPickerOpen);
    messageInputRef.current?.focus();
  }, []);

  //# EVENT: emoji select
  const onEmojiSelect = React.useCallback((emojiData: EmojiClickData) => {
    if (!messageInputRef.current) return;

    const { selectionStart, selectionEnd } = messageInputRef.current;

    if (messageInputRef.current.value.length + emojiData.emoji.length <= 250)
      messageInputRef.current.setRangeText(emojiData.emoji, selectionStart, selectionEnd, 'end');

    setMessageText(messageInputRef.current.value);
    // setIsEmojiPickerOpen(false);

    messageInputRef.current.focus();
  }, []);

  //# EVENT: message submit
  const onSubmitButtonClick = React.useCallback(() => {
    if (!messageText.trim()) return;

    // Trim the message white spaces and replace any new lines with spaces
    // TODO: do this on the backend
    const msg = messageText.replace(/\n/g, ' ').trim();

    onSendMessage(msg);

    setMessageText('');
    setIsEmojiPickerOpen(false);

    if (messageInputRef.current) messageInputRef.current.style.height = '';
  }, [messageText, onSendMessage]);

  //# EVENT: input keydown
  const onInputKeyDown = React.useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === 'Enter') {
        onSubmitButtonClick();
        e.preventDefault();
      }
    },
    [onSubmitButtonClick],
  );

  //! TEMP TEXTAREA
  const [inputContainer, setInputContainer] = React.useState<HTMLElement>();
  React.useEffect(() => {
    const input = document.querySelector('[data-replacetextarea]') as HTMLInputElement;
    if (!input || !input.parentElement) return;
    setInputContainer(input.parentElement);
    input.style.display = 'none';
  }, []);

  //--------------------------- END ---------------------------

  const contextValue = React.useMemo(() => {
    return {
      messageText,
      isInputFocused,
      isEmojiPickerOpen,

      onInputChange,
      onInputKeyDown,
      onSubmitButtonClick,
      onEmojiPickerTogglerClick,
      onEmojiSelect,

      replyingToName,
      replyingToMessage,
      editingMessage,
      onReplyCancelClick: () => {
        onInputCancelClick();
        setMessageText('');
      },
    };
  }, [
    messageText,
    isInputFocused,
    isEmojiPickerOpen,
    onInputChange,
    onInputKeyDown,
    onSubmitButtonClick,
    onEmojiPickerTogglerClick,
    onEmojiSelect,
    replyingToName,
    replyingToMessage,
    editingMessage,
    onInputCancelClick,
  ]);
  // console.log('SocialHubMessageInput[contextValue]', contextValue);

  return (
    <>
      <EmojiPicker
        open={isEmojiPickerOpen}
        width="100%"
        height={replyingToMessage ? parseInt(emojiPickerHeight) + 50 + 'px' : emojiPickerHeight}
        previewConfig={{ showPreview: emojiPickerShowPreview }}
        onEmojiClick={onEmojiSelect}
        skinTonesDisabled={true}
        emojiStyle={EmojiStyle.NATIVE}
        lazyLoadEmojis={emojiPickerLazyLoadEmojis}
        autoFocusSearch={false}
        style={{
          position: 'absolute',
          bottom: `${messageInputRef.current ? parseFloat(window.getComputedStyle(messageInputRef.current).height) + 36 : 0}px`,
          left: 0,
          right: 0,
        }}
      />

      <ModuleElementDiv className={props.className ?? ''} $styleText={props.styleText}>
        <DataElementContext.Provider value={contextValue}>{children}</DataElementContext.Provider>

        {inputContainer &&
          createPortal(
            <textarea
              ref={messageInputRef}
              placeholder={inputPlaceholder}
              onChange={onInputChange}
              onKeyDown={onInputKeyDown}
              value={messageText}
              rows={1}
              maxLength={inputMaxLength}
            ></textarea>,
            inputContainer,
          )}
      </ModuleElementDiv>
    </>
  );
};

export default SocialMessageInput;

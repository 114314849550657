import {
  Card,
  PaymentProviderInterface,
} from '@/components/classes/PaymentProviders/Deposit/Interfaces/PaymentProviderInterface';
import Utils from '@/modules/casino/utils/Utils';
import { paymentCheckout } from '@/modules/casino/ClientAPI/paths';
import VivaAPI from '@/modules/casino/ClientAPI/VivaAPI';
import PaymentProvider from '@/components/classes/PaymentProviders/Deposit/PaymentProvider';
import { PaymentProvider as PaymentProviderConstant } from '@/constants/paymentProvider';
import {
  requestVivaCardToken,
  requestVivaChargeTokenWithCard,
  requestVivaToken,
  sendVivaChargeToken,
} from '@/modules/casino/store/actions/paymentCheckout';
import ClientAPI from '@/modules/casino/ClientAPI/ClientAPI';
import PlayerAbuseChecker from '@/modules/casino/utils/PlayerAbuseChecker';
import VivaApplePay from '@/components/classes/PaymentProviders/Deposit/Viva/VivaApplePay';

class Viva extends PaymentProvider implements PaymentProviderInterface {
  private applePay: VivaApplePay | undefined;
  injectjQuery(): void {
    Utils.injectScript('https://code.jquery.com/jquery-1.12.4.min.js', 'viva-jquery', this.jqueryInserted, {
      integrity: 'sha256-ZosEbRLbNQzLpnKIkEdrPv7lOy9C27hHQ+Xp8a4MxAQ=',
      crossorigin: 'anonymous',
    });
  }

  jqueryInserted = (): void => {
    // implement in the future if needed;
  };
  injectScript(): void {
    setTimeout(() => {
      Utils.injectScript(
        window.config.vivaUrl + paymentCheckout.JS_VIVA_PAYMENT,
        'viva-script',
        this.vivaScriptInserted,
        {
          async: true,
        },
      );
    }, 3500);
  }

  init(data: any): void {
    super.init(data);
    // add div for 3ds
    let div = document.getElementById('validation3ds');
    if (!div) {
      div = document.createElement('div');
      div.setAttribute('id', 'validation3ds');
      document.body.appendChild(div);
    }

    this.injectjQuery();
    this.injectScript();

    this.setType(PaymentProviderConstant.viva);
    this.applePay = new VivaApplePay({
      ...data,
      triggerSuccess: this.triggerSuccess,
      triggerError: this.triggerError,
      dispatch: this.dispatch,
      bonusId: this.paymentDetails?.bonusId ?? null,
    });
  }

  setAmount(amount: number): void {
    super.setAmount(amount);
    this.applePay?.setAmount(amount);
  }

  confirmDepositValue() {
    this.dispatch(requestVivaToken()); // auth token
    this.dispatch(requestVivaCardToken()); // get cards
  }

  setCards(cards: any) {
    // check if cards is of type Cards
    let foundDefault = false;
    const cardsList: Card[] =
      cards.length > 0 && cards[0].is_default === undefined
        ? cards
        : cards.map((card: any): Card | undefined => {
            const c = this.createCard(card);

            if (card.is_default) {
              c.selected = true;
              foundDefault = true;
              this.setPaymentDetails({
                ...this.paymentDetails,
                card: c,
              });
            }

            return c;
          });
    if (!foundDefault && cardsList.length > 0 && cards?.[0]?.is_default !== undefined) {
      cardsList[0].selected = true;
      this.setPaymentDetails({
        ...this.paymentDetails,
        card: cardsList[0],
      });
    }
    super.setCards(cardsList);
  }

  createCard(card: any): Card {
    const c = super.createCard(card);
    let cardNumber = c.number;
    // format cardNumber to be displayed as **** **** **** 1234
    const cardNumberRaw = cardNumber?.replace(/\s/g, '');
    if (cardNumberRaw?.length === 16) {
      // replace first chars with *
      cardNumber = cardNumberRaw?.replace(/.{12}/, '**** **** **** ') ?? '';
    }
    switch (c.type) {
      case '0':
        c.type = 'Visa';
        break;
      case '1':
        c.type = 'MasterCard';
        break;
      case '6':
        c.type = 'Maestro';
        break;
      default:
      // do nothing
    }

    return { ...c, number: cardNumber };
  }

  vivaScriptInserted = () => {
    const token = VivaAPI.getToken();

    if (!token) {
      // @ts-ignore
      setTimeout(this.vivaScriptInserted, 1000);
      return;
    }

    const setupParams = {
      authToken: token,
      baseUrl: window.config.vivaApiUrl,
      cardHolderAuthOptions: {
        cardHolderAuthPlaceholderId: 'validation3ds',
        cardHolderAuthInitiated: function () {
          // @ts-ignore
          document.getElementById('validation3ds')?.setAttribute('class', 'show');
        },
        cardHolderAuthFinished: function () {
          // @ts-ignore
          document.getElementById('validation3ds')?.setAttribute('class', '');
        },
      },
    };
    // @ts-ignore
    window.VivaPayments.cards.setup(setupParams);
    // @ts-ignore
    window.VivaPayments.setBaseURL(window.config.vivaApiUrl);
  };

  setPaymentDetails(paymentDetails: any, setPaymentDetailsCallBack?: () => void): any {
    this.applePay?.setAmount(paymentDetails?.amount ?? 0);
    this.applePay?.setBonusId(paymentDetails?.bonusId ?? null);

    return super.setPaymentDetails(paymentDetails ?? null, setPaymentDetailsCallBack);
  }

  setBonusId(bonusId: number | null): void {
    super.setBonusId(bonusId);
    this.applePay?.setBonusId(bonusId);
  }

  requestChargeToken(): void {
    if (this.paymentDetails?.card?.token) {
      this.showLoader();
      this.dispatch(
        requestVivaChargeTokenWithCard(this.paymentDetails.card.token, {
          amount: this.paymentDetails.amount,
          bonusId: this.paymentDetails.bonusId,
        }),
      );
    } else {
      this.requestNewChargeToken();
    }
  }

  requestNewChargeToken(): void {
    try {
      const paymentDetails = this.paymentDetails;
      const dispatch = this.dispatch;
      const triggerError = this.triggerError;
      // @ts-ignore
      window.VivaPayments.cards
        .requestToken({
          amount: paymentDetails.amount * 100,
        })
        .done(function (data: any) {
          dispatch(
            sendVivaChargeToken(data, paymentDetails.amount, paymentDetails.bonusId, paymentDetails.holderName ?? ''),
          );
        })
        .fail(function (error: any) {
          triggerError();
          console.error(error);
        });
    } catch (e) {}
  }
  confirmPayment(): void {
    try {
      this.requestChargeToken();
      // choose one of the following
      // this.dispatch(sendVivaChargeToken(this.paymentDetails.amount * 100, this.paymentDetails.cardToken));
      // this.dispatch(requestVivaChargeTokenWithCard(this.paymentDetails.cardToken, this.paymentDetails.amount * 100));
    } catch (e) {}
  }

  sendConfirmation(info: any) {
    const axios = ClientAPI.getInstance();
    const pac = PlayerAbuseChecker.getInfo();
    const data = {
      chargeToken: info.data.chargeToken,
      amount: info.amount,
      bonusId: info.bonusId,
      ...pac,
    };

    axios({
      url: '/api/pay-checkout/charge-token',
      method: 'post',
      data: data,
    })
      .then((response) => {
        // @ts-ignore
        if (response?.result?.ResponseCode) {
          this.triggerError();
        } else {
          // @ts-ignore
          if (response?.status === 'OK') {
            setTimeout(this.triggerSuccess, 500);
          } else {
            this.triggerError();
          }
        }
      })
      .catch(() => {
        this.triggerError();
      });
  }

  getApplePay(): VivaApplePay | undefined {
    return this.applePay;
  }
}

export default Viva;

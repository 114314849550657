import React from 'react';

import styled from 'styled-components';
import { useAppSelector, useAppDispatch } from '../../../store';
import { DataElementContext } from '../../../page-components/common/DataElementContext';
import { getEligibleBonuses, getBonusCalculator } from '../../../modules/casino/store/actions/application';
import { requestWallet } from '@/modules/casino/store/actions/wallet';
import Bonus from '@/components/classes/Bonus/Bonus';
import { getTax, requestLimits } from '@/modules/casino/store/actions';
import Bridger from '@/components/classes/PaymentProviders/Deposit/Bridger/Bridger';
import { AmountButtonInterface } from '@/components/classes/PaymentProviders/Interfaces/AmountButtonInterface';
import { PaymentProvider, paymentProviderNameById } from '@/constants/paymentProvider';
import {
  PaymentDetailsInterface,
  PaymentProviderInterface,
} from '@/components/classes/PaymentProviders/Deposit/Interfaces/PaymentProviderInterface';
import Viva from '@/components/classes/PaymentProviders/Deposit/Viva/Viva';
import Safecharge from '@/components/classes/PaymentProviders/Deposit/Safecharge/Safecharge';
import Okto from '@/components/classes/PaymentProviders/Deposit/Okto/Okto';
import Abon from '@/components/classes/PaymentProviders/Deposit/Abon/Abon';
import { useLocation, useParams } from 'react-router-dom';
import { formatCurrency } from '@/modules/bets/utils/formatters';
import { receivedVivaChargeToken } from '@/modules/casino/store/actions/paymentCheckout';
import { useTranslation } from 'react-i18next';
import './index.scss';

type DepositProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: {
    dsType: string;
  };
};

type ModuleStateProps = {
  depositValue: number;
  depositValueWithoutTax: number;
  taxes: number | null;
  selectedBonus: any;
  currentStep: string;
  selectedBonusInfo: any;
  selectedCard: any;
  bonuses: any;
  loading: boolean;
  bonusLoading: boolean;
  cardsLoading: boolean;
  initialized: boolean;
  paymentProvider: number;
  isOpen: boolean;
  paymentProviderObject: PaymentProviderInterface | null;
  tcAccepted: boolean;
  privacyAccepted: boolean;
  maxLimit: number | boolean;
  oldValues: {
    [key: number]: PaymentDetailsInterface;
  };
  bonusCategories?: any;
  oldPaymentProvider?: number;
  cardInfo?: {
    number: string | undefined;
    name: string | undefined;
    expiry: {
      month: string | undefined;
      year: string | undefined;
      formatted: string | undefined;
    };
    cvv: string | undefined;
  };
};

const defaultProps = {
  className: '',
  styleText: '',
  properties: {
    dsType: '',
  },
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const Deposit = (componentProps: DepositProps) => {
  const tmpProps = { ...defaultProps, ...componentProps };
  delete tmpProps.children;
  const props = JSON.parse(JSON.stringify(tmpProps));
  const { children } = componentProps;
  const injectScriptID = 'bridger-pay-script';
  const { t } = useTranslation();

  const activePaymentProviders: { paymentProviderId: number }[] = [];

  if (props.properties.vivaPayments || window?.config?.vivaPaymentsEnabled === '1') {
    activePaymentProviders.push({
      paymentProviderId: PaymentProvider.viva,
    });
  }
  if (props.properties.skrillSafecharge || window?.config?.skrillSafechargeEnabled === '1') {
    activePaymentProviders.push({
      paymentProviderId: PaymentProvider.skrill,
    });
  }
  if (props.properties.oktoCash || window?.config?.oktoCashEnabled === '1') {
    activePaymentProviders.push({
      paymentProviderId: PaymentProvider.okto,
    });
  }
  if (props.properties.abon || window?.config?.abonEnabled === '1') {
    activePaymentProviders.push({
      paymentProviderId: PaymentProvider.abon,
    });
  }
  if (props.properties.bridger || window?.config?.bridgerEnabled === '1') {
    activePaymentProviders.push({
      paymentProviderId: PaymentProvider.bridger,
    });
  }
  // if (props.properties.safecharge) { // old implementation of paysafe
  //   activePaymentProviders.push({
  //     paymentProviderId: PaymentProvider.safecharge,
  //   });
  // }

  const defaultPaymentProvider = activePaymentProviders?.[0]?.paymentProviderId;

  const dispatch = useAppDispatch();
  const { depositStatus } = useParams(); // /deposit/:depositStatus? (/deposit/error, /deposit/success, /deposit/pending, /deposit/cancel)
  const location = useLocation(); // used with new URLSearchParams() (/deposit?param=value)
  const profile = useAppSelector((state) => state.profile);
  const deposit = useAppSelector((state) => state.deposit);
  const { currentCurrency } = useAppSelector((state) => state.currencies);
  const bonuses = useAppSelector((state) => state.application.eligibleBonuses);
  const bonusAward = useAppSelector((state) => state.application.bonusAward);
  const { taxes } = useAppSelector((state) => state.withdrawWizard);

  const initialState = {
    selectedBonus: false,
    selectedBonusInfo: {},
    depositValue: 0,
    depositValueWithoutTax: 0,
    selectedCard: null,
    bonuses: null,
    taxes: null,
    initialized: false,
    currentStep: 'amount',
    // currentStep: 'error',
    loading: false,
    bonusLoading: true,
    cardsLoading: false,
    oldPaymentProvider: 0,
    paymentProvider: defaultPaymentProvider,
    paymentProviderObject: null,
    tcAccepted: true,
    privacyAccepted: true,
    oldValues: {},
    bonusCategories: {},
    isOpen: false,
    cardInfo: {
      number: '',
      name: '',
      expiry: {
        month: '',
        formatted: '',
        year: '',
      },
      cvv: '',
    },
    maxLimit: false,
  };

  const [state, setState] = React.useState<ModuleStateProps>(initialState);

  const triggerError = React.useCallback(() => {
    setState((v) => ({
      ...v,
      currentStep: 'error',
      loading: false,
    }));
  }, [state]);

  const triggerSuccess = React.useCallback(() => {
    setState((v) => ({
      ...v,
      currentStep: v.paymentProviderObject?.getType() === PaymentProvider.okto ? 'success-okto' : 'success',
      loading: false,
    }));
    // call api to get wallet
    setTimeout(() => {
      dispatch(requestWallet());
    }, 500);
  }, [state]);

  const triggerPending = React.useCallback(() => {
    setState((v) => ({
      ...v,
      currentStep: 'pending',
      loading: false,
    }));
  }, [state]);

  const showLoader = React.useCallback(() => {
    setState((v) => ({
      ...v,
      loading: true,
    }));
  }, [state]);

  const hideLoader = React.useCallback(() => {
    setState((v) => ({
      ...v,
      loading: false,
    }));
  }, [state]);

  React.useEffect(() => {
    if (profile?.client_player_id) {
      initComponent();
    }
    dispatch(requestLimits());

    if (location.search) {
      // store the path to the game we're coming from so we can return to it after deposit
      const params = new URLSearchParams(location.search);
      const fromGame = params.get('from_game');
      if (fromGame != null) {
        localStorage.setItem('fgm', fromGame);
      }
    }

    return () => {
      state.paymentProviderObject?.destroy();
    };
  }, []);

  React.useEffect(() => {
    if (state.selectedBonus && bonusAward?.bonusId == state.selectedBonus) {
      const bonus = new Bonus(bonuses[state.selectedBonus], bonusAward.awardAmount ?? 0);
      const selectedBonusInfo = getBonusInfo(bonus, true, state.paymentProvider, bonusAward.awardAmount ?? 0);

      setState((v) => ({
        ...v,
        selectedBonusInfo: selectedBonusInfo,
      }));
    }
  }, [bonusAward]);

  React.useEffect(() => {
    setState((v) => {
      let depositWithoutTax = v.depositValue - (taxes ?? 0);
      if (depositWithoutTax < 0) {
        depositWithoutTax = 0;
      }
      return {
        ...v,
        depositValueWithoutTax: depositWithoutTax,
        taxes: taxes,
      };
    });
  }, [taxes]);

  React.useEffect(() => {
    // loop through limits and get max limit
    const MAX_LIMIT = 999999;
    let maxLimit = MAX_LIMIT;
    if (profile.limits) {
      Object.keys(profile.limits).map((key) => {
        if (profile.limits[key].limit_amount) {
          const diff = profile.limits[key].limit_amount - profile.limits[key].accumulated_amount;
          if (diff < maxLimit) maxLimit = diff;
        }
      });
    }
    setState((v) => ({
      ...v,
      maxLimit: maxLimit === MAX_LIMIT ? false : maxLimit,
    }));
  }, [profile.limits]);

  React.useEffect(() => {
    initComponent();
  }, [profile.client_player_id]);

  React.useEffect(() => {
    initPaymentProvider();
  }, [state.paymentProviderObject]);

  React.useEffect(() => {
    state.paymentProviderObject?.setBonusId(state.selectedBonus ? parseInt(state.selectedBonus) : null);
    // changePaymentProvider(state.oldPaymentProvider ?? state.paymentProvider);
  }, [state.selectedBonus]);

  const refreshBonusInfo = React.useCallback(() => {
    setState((v) => ({
      ...v,
      selectedBonusInfo: getBonusInfo(new Bonus(v.selectedBonus), true, v.paymentProvider),
    }));
  }, [state]);

  React.useEffect(() => {
    if ((state.currentStep === 'amount' || state.currentStep === 'final-step') && state.depositValue > 0) {
      state.paymentProviderObject?.setAmount(state.depositValue);
      selectBonus(state.selectedBonus, state.paymentProvider);
    }
  }, [state.depositValue]);

  // this is just for bridger
  React.useEffect(() => {
    initPaymentProvider();
  }, [deposit.bridger.cashierKey, deposit.bridger.cashierToken]);

  // this is just for paysafe/skrill
  React.useEffect(() => {
    if (deposit.paysafe.iframeUrl) {
      state.paymentProviderObject?.redirect(deposit.paysafe.iframeUrl);
    }
  }, [deposit.paysafe.iframeUrl]);

  React.useEffect(() => {
    if (deposit.paysafe.error) {
      triggerError();
    }
  }, [deposit.paysafe.error]);

  // this is just for aircash
  React.useEffect(() => {
    if (deposit.aircash.iframeUrl) {
      state.paymentProviderObject?.redirect(deposit.aircash.iframeUrl);
    }
  }, [deposit.aircash.iframeUrl]);

  React.useEffect(() => {
    if (deposit.aircash.error) {
      triggerError();
    }
  }, [deposit.aircash.error]);

  // this is just for viva
  React.useEffect(() => {
    if (state.paymentProvider === PaymentProvider.viva) {
      if (deposit.viva.tokenList && state.currentStep === 'deposit-viva') {
        state.paymentProviderObject?.setCards(deposit.viva.tokenList);

        setState((v) => ({
          ...v,
          currentStep: deposit.viva.tokenList?.length ? 'deposit-viva' : 'deposit-viva-card',
          cardsLoading: deposit.viva.loading ?? false,
        }));
      }
    }
  }, [deposit.viva]);

  // this is just for viva
  React.useEffect(() => {
    if (deposit.viva.serverResponse) {
      if (state.currentStep === 'deposit-viva' || state.currentStep === 'deposit-viva-card') {
        if (deposit.viva.serverResponse === 'success') {
          triggerSuccess();
        } else {
          triggerError();
        }
      }
    }
  }, [deposit.viva.serverResponse]);

  // this is just for okto
  React.useEffect(() => {
    if (state.paymentProvider === PaymentProvider.okto) {
      setState((v) => ({
        ...v,
        tcAccepted: deposit.okto.customer.okto_terms_accepted,
        privacyAccepted: deposit.okto.customer.okto_terms_accepted, // privacy is terms
      }));
    }
  }, [deposit.okto.customer]);

  React.useEffect(() => {
    if (state.currentStep === 'deposit-okto') {
      if (deposit.okto.paymentCode) {
        triggerSuccess();
      } else {
        if (deposit.okto.error) {
          triggerError();
        }
      }
    }
  }, [deposit.okto]);

  React.useEffect(() => {
    if (state.selectedBonus && state.depositValue >= state.selectedBonusInfo?.deposit?.min) {
      dispatch(getBonusCalculator(state.selectedBonus, state.depositValue, currentCurrency));
    } else {
      if (state.selectedBonus && bonusAward?.bonusId == state.selectedBonus) {
        const bonus = new Bonus(bonuses[state.selectedBonus]);
        const selectedBonusInfo = getBonusInfo(bonus, true, state.paymentProvider);

        setState((v) => ({
          ...v,
          selectedBonusInfo: selectedBonusInfo,
        }));
      }
    }
    if (state.depositValue > 0) {
      dispatch(getTax(state.depositValue, 2)); // 2 = deposit
    }
  }, [state.depositValue, state.selectedBonus]);

  React.useEffect(() => {
    createBonusCategories();
    preselectBonus();
  }, [bonuses]);

  React.useEffect(() => {
    // check if bonus categories is empty
    if (state.bonusCategories && Object.keys(state.bonusCategories).length) {
      selectBonus(state.selectedBonus, state.paymentProvider);
    }
  }, [state.bonusCategories]);

  React.useEffect(() => {
    if (state.currentStep === 'final-step') {
      showLoader();

      // init payment provider based on url
      const tempString = window.localStorage.getItem('deposit');
      window.localStorage.removeItem('deposit');

      const tempObject = tempString ? JSON.parse(tempString) : [];
      const paymentProvider = tempObject?.paymentProvider ?? false;
      if (paymentProvider) {
        changePaymentProvider(parseInt(paymentProvider));
        setState((v) => ({
          ...v,
          depositValue: tempObject?.amount ? tempObject.amount / 100 : v.depositValue,
          loading: false,
        }));
        // init send confirmation for viva if exists maybe this needs to be called in the same useEffect as all the above
        if (parseInt(paymentProvider) === PaymentProvider.viva) {
          if (tempObject?.data?.chargeToken) {
            state.paymentProviderObject?.sendConfirmation(tempObject);
          }
        }
      }
    }
  }, [state.currentStep]);

  const init = () => {
    if (state.initialized) {
      return;
    }

    setState((v) => ({
      ...v,
      initialized: true,
    }));

    if (getPreselectedBonusId()) {
      showLoader();
    } else {
      checkDepositStatus();
    }
  };

  const getPreselectedBonusId = () => {
    // check if bonusId exists in url as parameter
    const params = new URLSearchParams(location.search);
    return params.get('bonusId');
  };

  React.useEffect(() => {
    // in cordova app the url is dynamically changed so
    // we need to check if depositStatus has changed
    if (window.config.cordova && depositStatus) {
      checkDepositStatus();
    }
  }, [depositStatus]);

  const checkDepositStatus = () => {
    // get info from url, localstorage, and default values
    const tempString = window.localStorage.getItem('deposit');

    const tempObject = tempString ? JSON.parse(tempString) : [];
    const paymentProvider = tempObject?.paymentProvider ?? defaultPaymentProvider;
    let step = depositStatus ?? tempObject?.step ?? state.currentStep;
    let amount = tempObject?.amount ? tempObject.amount / 100 : 20;

    if (step === 'cancel') {
      step = 'amount';
    }
    // replace amount from url
    const urlParams = new URLSearchParams(location.search);
    if (urlParams.get('totalAmount')) {
      amount = parseInt(urlParams.get('totalAmount') ?? '0');
    }
    // depositStatus is the most important;
    // fallback: localstorage > state > default
    switch (step) {
      case 'success':
      case 'pending':
      case 'error':
      case 'final-step':
        // setTimeout(() => {
        setState((v) => ({
          ...v,
          currentStep: step,
          depositValue: amount,
          taxes: null,
        }));
        // }, 1000);
        break;

      default:
        getBonuses();
        setState((v) => ({
          ...v,
          currentStep: 'amount',
          depositValue: amount,
          taxes: null,
        }));
        break;
    }

    setTimeout(() => {
      // remove deposit from localstorage
      window.localStorage.removeItem('deposit');
    }, 3000);
  };

  const initComponent = () => {
    dispatch(receivedVivaChargeToken({ serverResponse: false })); // reset vivacharge token
    // init payment provider based on data from url/localstorage/state/defaultValues
    init();
  };

  const getBonuses = () => {
    resetBonus();
    dispatch(getEligibleBonuses()); // get bonuses
  };

  const toBonuses = React.useCallback(() => {
    setState((v: any) => ({
      ...v,
      currentStep: 'bonuses',
      loading: false,
    }));
  }, [state, bonuses]);

  const toAmount = React.useCallback(() => {
    setState((v) => ({
      ...v,
      currentStep: 'amount',
      depositValue: v.depositValue ?? 20,
      loading: false,
    }));
  }, [state]);

  const getBonusInfo = React.useCallback(
    (bonusObj: Bonus, selected: boolean, paymentProvider: any, st?: any) => {
      if (!st) {
        st = state;
      }
      let bonus = bonusObj;
      const paymentProviders = bonus.getBonusPaymentMethodsRaw();
      // check if paymentProviders have payment provider
      if (paymentProviders.length && !paymentProviders.includes(paymentProvider)) {
        bonus = new Bonus(0);
      }

      const bonusId = bonus.getBonusId();
      const selectedBonusInfo = {
        name: bonus.getBonusName(),
        products: bonus.getBonusProducts(),
        primary_message: bonus.getBonusAmountDisplay(),
        secondary_message: bonus.getBonusDescription(),
        end_date: bonus.getBonusEndDate(),
        show_date: bonus.getBonusShowDate(),
        terms_url: bonus.getBonusTermsUrl(),
        cta_text: bonus.getBonusCtaText(),
        onClickCTA: toBonuses,
        image: bonus.getBonusImage(),
        icon: bonus.getBonusIcon(),
        bonus_conflicts: bonus.getBonusConflicts(),
        canBeSelected: !bonus.haveConflicts(),
        buttonInfo: !bonus.haveConflicts()
          ? {
              state: 'default',
              colorMapping: 'accent',
              buttonType: 'filled',
              size: 'large',
              style: 'text',
              shadow: 'depth_3',
              iconLeft: false,
              iconRight: false,
              ctaText: 'Claim Bonus',
              loading: false,
              icon: '',
              onClick: () => {
                if (bonusId === 0) {
                } else {
                  // @ts-ignore
                  selectBonus(bonusId, paymentProvider);
                }
              },
            }
          : {
              state: 'disabled',
              colorMapping: 'inverted',
              buttonType: 'filled',
              size: 'large',
              style: 'text',
              shadow: 'none',
              iconLeft: false,
              iconRight: false,
              ctaText: 'Claim Bonus',
              loading: false,
              icon: '',
            },
        id: bonusId,
        bonusId: bonusId,
        deposit: bonus.getBonusDeposit(),
        bonusAward: bonus.getBonusAmountDisplay(
          bonusAward?.bonusId == bonusId ? bonusAward ?? 0 : undefined,
          st.depositValue,
        ),
      };

      if (bonusId === 0) {
        if (st.bonusCategories?.[st.paymentProvider]?.length) {
          selectedBonusInfo.name = `<span style="color:#47B96A">${
            st.bonusCategories[st.paymentProvider].length
          } ${t('bonuses available')}</span>`;
        } else {
          selectedBonusInfo.name = state.bonusLoading ? 'skeleton' : t('No bonuses available');
        }
      }

      if (selected) {
        const depositMin = selectedBonusInfo?.deposit?.min ?? 0;

        if (depositMin > st.depositValue) {
          selectedBonusInfo.bonus_conflicts = [
            {
              message: `${t('Minimum deposit value is')} ${selectedBonusInfo.deposit.min} ${formatCurrency(currentCurrency)}`,
            },
          ];
          selectedBonusInfo.canBeSelected = false;
        } else {
          const award = 0;

          selectedBonusInfo.secondary_message = award + ' <small>' + formatCurrency(currentCurrency) + '</small>';
        }
      }

      return selectedBonusInfo;
    },
    [state, bonusAward],
  );

  const resetBonus = () => {
    if (!state.selectedBonus && !state.selectedBonusInfo) {
      // this is an init call
      const bonus = new Bonus(false);
      const bonusInfo = getBonusInfo(bonus, false, state.paymentProvider);

      setState((v) => ({
        ...v,
        selectedBonus: false,
        selectedBonusInfo: bonusInfo,
      }));
    }
  };

  const preselectBonus = () => {
    // based on bonusId parameter from url
    const bonusId = getPreselectedBonusId();

    if (bonusId && bonuses?.[bonusId]) {
      // select bonus if exists
      const bonus = new Bonus(bonuses[bonusId]);
      let pm: number[] = bonus.getBonusPaymentMethodsRaw();
      const ids: number[] = activePaymentProviders.map((provider) => provider.paymentProviderId);
      if (!pm.length) {
        pm = ids;
      } else {
        pm = pm.filter((id) => ids.includes(id));
      }
      const oldValues = {};
      const bonusInfo = getBonusInfo(bonus, true, state.paymentProvider);
      const depositValue = bonusInfo.deposit.min;
      pm.forEach((paymentMethod: any) => {
        // @ts-ignore
        oldValues[paymentMethod] = {
          selectedBonus: bonusId,
          selectedBonusInfo: bonusInfo,
          depositValue: depositValue,
        };
      });

      // @ts-ignore
      setState((v) => ({
        ...v,
        selectedBonus: bonusId,
        // currentStep: 'amount',
        depositValue: depositValue,
        loading: false,
        taxes: null,
        oldValues: {
          ...v.oldValues,
          ...oldValues,
        },
      }));
      changePaymentProvider(pm[0]);
    } else {
      resetBonus();
      setState((v) => ({
        ...v,
        // currentStep: 'bonuses',
        loading: false,
        depositValue: v.paymentProviderObject?.getLimits().min ?? 0,
        taxes: null,
      }));
    }
  };

  const createBonusCategories = () => {
    if (!bonuses) {
      return;
    }
    const bonusCategories: any = [];
    const ids: number[] = [];
    activePaymentProviders.map((provider) => {
      ids.push(provider.paymentProviderId);
      bonusCategories[provider.paymentProviderId] = [];
    });
    Object.keys(bonuses).map((id: any) => {
      const bonus: Bonus = new Bonus(bonuses[id]);
      let pm: number[] = bonus.getBonusPaymentMethodsRaw();
      if (!pm.length) {
        pm = ids;
      }
      pm.map((paymentMethod: any) => {
        // @ts-ignore
        if (bonusCategories[paymentMethod]) {
          // @ts-ignore
          bonusCategories[paymentMethod].push(getBonusInfo(bonus, false, paymentMethod));
        }
      });
    });
    if (state.bonusCategories !== bonusCategories) {
      setState((v) => ({
        ...v,
        bonusCategories: bonusCategories,
        bonuses: bonuses,
        bonusLoading: false,
      }));
    }
  };

  const selectCard = React.useCallback(
    (c: any) => {
      setState((v) => ({
        ...v,
        selectedCard: c,
      }));
    },
    [state],
  );

  const changePaymentProvider = React.useCallback(
    (paymentProvider: number) => {
      const oldType = state.paymentProviderObject?.getType() ?? false;

      if (oldType == paymentProvider) {
        // setState((v) => ({
        //   ...v,
        //   isOpen: !v.isOpen,
        // }));
        return;
      }
      const paymentDetails = state.paymentProviderObject?.getPaymentDetails();
      state.paymentProviderObject?.destroy();
      // @ts-ignore
      const oldValues = state.oldValues?.[paymentProvider] ?? state.oldValues?.[oldType] ?? {};
      const selectedBonus = oldValues?.selectedBonus ?? oldValues?.selectedBonus ?? 0;

      const selectedBonusInfo = getBonusInfo(new Bonus(bonuses?.[selectedBonus]), true, paymentProvider);
      // TODO: verificare schimbare provider abon - bonus;
      selectedBonusInfo.products;

      const defaultState = {
        isOpen: true,
        tcAccepted: true,
        privacyAccepted: true,
        paymentProvider: paymentProvider,
        oldPaymentProvider: oldType,
        selectedBonus: selectedBonus,
        selectedBonusInfo: selectedBonusInfo,
        depositValue: oldValues?.amount ?? state.depositValue,
        oldValues: oldType
          ? {
              ...state.oldValues,
              [oldType]: {
                ...paymentDetails,
                selectedBonus: state.selectedBonus,
                selectedBonusInfo: selectedBonusInfo,
                depositValue: state.depositValue,
              },
            }
          : {},
      };

      switch (paymentProvider) {
        case PaymentProvider.bridger:
          // @ts-ignore
          setState((v) => ({
            ...v,
            ...defaultState,
            paymentProviderObject: new Bridger({
              triggerSuccess: triggerSuccess,
              triggerPending: triggerPending,
              triggerError: triggerError,
              hideLoader: hideLoader,
              showLoader: showLoader,
              dispatch: dispatch,
              paymentDetails: state.oldValues?.[paymentProvider] ?? { ...paymentDetails, bonusId: null },
            }),
          }));
          break;
        case PaymentProvider.viva:
          // @ts-ignore
          setState((v) => ({
            ...v,
            ...defaultState,
            paymentProviderObject: new Viva({
              triggerSuccess: triggerSuccess,
              triggerPending: triggerPending,
              triggerError: triggerError,
              selectCard: selectCard,
              hideLoader: hideLoader,
              showLoader: showLoader,
              dispatch: dispatch,
              paymentDetails: paymentDetails,
            }),
          }));
          break;
        case PaymentProvider.safecharge:
        case PaymentProvider.skrill:
          // @ts-ignore
          setState((v) => ({
            ...v,
            ...defaultState,
            paymentProviderObject: new Safecharge({
              triggerSuccess: triggerSuccess,
              triggerPending: triggerPending,
              triggerError: triggerError,
              hideLoader: hideLoader,
              showLoader: showLoader,
              dispatch: dispatch,
              paymentDetails: paymentDetails,
              paymentProvider: paymentProvider,
            }),
          }));
          break;
        case PaymentProvider.okto:
          // @ts-ignore
          setState((v) => ({
            ...v,
            ...defaultState,
            tcAccepted: deposit?.okto?.customer?.okto_terms_accepted ?? false,
            privacyAccepted: deposit?.okto?.customer?.okto_terms_accepted ?? false,
            paymentProviderObject: new Okto({
              triggerSuccess: triggerSuccess,
              triggerPending: triggerPending,
              triggerError: triggerError,
              hideLoader: hideLoader,
              showLoader: showLoader,
              dispatch: dispatch,
              paymentDetails: paymentDetails,
            }),
          }));
          break;
        case PaymentProvider.abon:
          // @ts-ignore
          setState((v) => ({
            ...v,
            ...defaultState,
            depositValue: 25,
            taxes: null,
            paymentProviderObject: new Abon({
              triggerSuccess: triggerSuccess,
              triggerPending: triggerPending,
              triggerError: triggerError,
              hideLoader: hideLoader,
              showLoader: showLoader,
              dispatch: dispatch,
              paymentDetails: paymentDetails,
            }),
          }));
          break;
        default:
          console.warn(`${paymentProvider} ${t('unknown payment provider')}`);
          break;
      }

      initPaymentProvider();
      resetBonus();
    },
    [state, bonuses],
  );

  const initPaymentProvider = () => {
    // reset bonus:
    switch (state.paymentProvider) {
      case PaymentProvider.bridger:
        state.paymentProviderObject?.init({
          currency: currentCurrency,
          cashierKey: deposit.bridger.cashierKey,
          cashierToken: deposit.bridger.cashierToken,
          injectScriptID: injectScriptID,
        });
        break;
      case PaymentProvider.viva:
      case PaymentProvider.safecharge:
      case PaymentProvider.skrill:
      case PaymentProvider.abon:
        state.paymentProviderObject?.init({
          currency: currentCurrency,
          paymentProvider: state.paymentProvider,
        });
        break;
      case PaymentProvider.okto:
        state.paymentProviderObject?.init({
          currency: currentCurrency,
          paymentProvider: state.paymentProvider,
          getCustomer: deposit.okto.customer.okto_terms_accepted === null,
        });
        break;
      default:
        console.warn(`${state.paymentProvider} ${t('unknown payment provider')}`);
        break;
    }
  };

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value === '' ? 0 : Number(e.target.value) || state.depositValue || 0;
    if (newValue === state.depositValue) {
      return;
    }
    // @ts-ignore
    setState((v) => ({
      ...v,
      depositValue: e.target.value === '' ? 0 : Number(e.target.value) || state.depositValue || 0,
      taxes: null,
    }));
  };

  const selectBonus = React.useCallback(
    (e: any, paymentProvider?: any) => {
      if (e === null) return;

      let value = 0;
      if (typeof e !== 'object') {
        value = e;
      } else {
        const el = e.currentTarget ?? (e.currentTarget as HTMLButtonElement);
        // get data-bonus-id from parent
        value = el?.parentElement?.getAttribute('data-bonus-id');
      }

      setState((v) => {
        const provider = paymentProvider ?? v.oldPaymentProvider ?? v.paymentProvider ?? defaultPaymentProvider;
        const bonus = new Bonus(value ? v.bonuses?.[value] : false);
        const bonusInfo = getBonusInfo(bonus, true, provider, v);
        let amountToSelect = v.depositValue;
        if (v.selectedBonus !== value) {
          if (v.selectedBonusInfo?.deposit?.min === v.depositValue || v.depositValue === 0 || v.depositValue === 20) {
            amountToSelect = bonusInfo?.deposit?.min ?? 20;
          }
        }
        return {
          ...v,
          currentStep: value === 0 ? v.currentStep : 'amount',
          // paymentProvider: provider,
          selectedBonus: value,
          selectedBonusInfo: bonusInfo,
          depositValue: amountToSelect,
          oldValues: {
            ...v.oldValues,
            [provider]: {
              ...v.oldValues?.[provider],
              selectedBonus: value,
              selectedBonusInfo: bonusInfo,
              depositValue: v.depositValue,
            },
          },
        };
      });
    },
    [state],
  );

  const confirmDepositValue = () => {
    if (state.depositValue > 0 && error == '') {
      switch (state.paymentProvider) {
        case PaymentProvider.bridger:
        case PaymentProvider.safecharge:
        case PaymentProvider.skrill:
          setState((v) => ({
            ...v,
            currentStep: 'deposit',
            loading: true,
          }));
          break;
        case PaymentProvider.viva:
          if (state.currentStep === 'deposit-viva') {
            state.paymentProviderObject?.confirmPayment();
            return;
          } else if (state.currentStep === 'deposit-viva-card') {
            state.paymentProviderObject?.setPaymentDetails({
              ...state.paymentProviderObject?.getPaymentDetails(),
              card: true,
              cardInfo: {
                holderName: state.cardInfo?.name ?? '',
                cardNumber: state.cardInfo?.number ?? '',
                expiryMonth: state.cardInfo?.expiry?.month,
                expiryYear: state.cardInfo?.expiry?.year,
                cvv: state.cardInfo?.cvv ?? '',
              },
            });
            state.paymentProviderObject?.confirmPayment();
            showLoader();
            return;
          } else {
            setState((v) => ({
              ...v,
              currentStep: 'deposit-viva',
              cardsLoading: true,
            }));
          }
          break;
        case PaymentProvider.okto:
          setState((v) => ({
            ...v,
            currentStep: 'deposit-okto',
            loading: true,
          }));
          break;
        case PaymentProvider.abon:
          setState((v) => ({
            ...v,
            currentStep: 'deposit-abon',
            loading: true,
          }));
          break;
        default:
          console.warn(`${state.paymentProvider} ${t('unknown payment provider')}`);
          break;
      }

      state.paymentProviderObject?.confirmDepositValue();
    }
  };

  const onDepositButtonClick = React.useCallback(
    (e: any) => {
      const el = e.currentTarget ?? (e.currentTarget as HTMLButtonElement);
      // get data-value from button
      const value = el.getAttribute('data-value');

      if (value) {
        setState((v: any) => ({
          ...v,
          depositValue: Number(value),
          taxes: null,
        }));
      }
    },
    [state],
  );

  const getDepositButtons = () => {
    if (!state.paymentProviderObject) {
      changePaymentProvider(state.paymentProvider);
    }
    const buttons: AmountButtonInterface[] = state.paymentProviderObject?.getAmountButtons(state, {
      currentCurrency: currentCurrency,
      setStateCallback: setState,
      onDepositButtonClick: onDepositButtonClick,
    });

    return buttons;
  };

  const onToggleLoading = () => {
    setState((v) => ({
      ...v,
      loading: !v.loading,
    }));
  };

  const onTCChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setState((v) => ({
        ...v,
        tcAccepted: event.target.checked,
      }));
    },
    [state],
  );

  const onPrivacyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState((v) => ({
      ...v,
      privacyAccepted: event.target.checked,
    }));
  };

  const getDateOfBirth = (nin: string) => {
    if (!nin || nin.length < 13) {
      return '';
    }
    const year = nin.substr(1, 2);
    const month = nin.substr(3, 2);
    const day = nin.substr(5, 2);
    return `${day}.${month}.19${year}`;
  };

  // @ts-ignore
  let error: string = deposit && deposit.error ? deposit.error : '';

  if (state.depositValue > 0) {
    const limits = state.paymentProviderObject?.getLimits() ?? { min: 0, max: 0 };
    // @ts-ignore
    if (state.maxLimit !== false && state.maxLimit < limits.max) {
      // @ts-ignore
      limits.max = state.maxLimit;
    }
    if (state.depositValue < limits.min) {
      error = `${t('Minimum deposit amount is')} ${limits.min} ${formatCurrency(currentCurrency)}`;
    } else if (state.depositValue > limits.max) {
      error = `${t('Maximum deposit amount is')} ${limits.max} ${formatCurrency(currentCurrency)}`;
      // props.properties.text = `Maximum deposit amount is {{MAX}} {{CURRENCY}}`;
      // props.properties.placeholders = { MAX: limits.max, CURRENCY: formatCurrency(currentCurrency) };
    }
  }

  let depositCode = '';
  if (state.paymentProviderObject?.getType() == PaymentProvider.okto) {
    depositCode = deposit.okto?.paymentCode;
  }
  const skeletonCard = {
    token: '',
    type: 'skeleton',
    number: '',
    expiration_date: 0,
    verified: 0,
    selected: false,
    selectCard: () => null,
  };
  // @ts-ignore
  const contextValue = {
    ...state,
    bonuses: state.bonusCategories?.[state.paymentProvider],
    depositButtons: getDepositButtons(),
    taxValue: state.taxes,
    selectBonus: selectBonus,
    confirmDepositValue: confirmDepositValue,
    currency: formatCurrency(currentCurrency),
    onDepositValueChange: onInputChange,
    injectScriptID: injectScriptID,
    error: error,
    onToggleLoading: onToggleLoading,
    minimumDeposit: state.paymentProviderObject?.getLimits().min,
    amountButtonInfo:
      error == '' && state.depositValue > 0
        ? {
            state: 'default',
            colorMapping: 'accent',
            buttonType: 'filled',
            size: 'large',
            style: 'text',
            shadow: 'depth_3',
            iconLeft: false,
            iconRight: false,
            ctaText: 'Deposit',
            icon: '',
          }
        : {
            state: 'disabled',
            colorMapping: 'inverted',
            buttonType: 'filled',
            size: 'large',
            style: 'text',
            shadow: 'none',
            iconLeft: false,
            iconRight: false,
            ctaText: 'Deposit',
            icon: '',
          },
    paymentProviderList: activePaymentProviders.map((provider) => {
      let depositDisabled = state.depositValue === 0 || error !== '';
      if (!state.privacyAccepted || !state.tcAccepted) {
        depositDisabled = true;
      }

      let hideTC = provider.paymentProviderId !== PaymentProvider.okto;
      if (provider.paymentProviderId == PaymentProvider.okto) {
        hideTC = !!deposit.okto?.customer?.okto_terms_accepted;
      }
      const applePay = {
        hide: true,
        onClick: () => 0,
      };

      if (
        provider.paymentProviderId === PaymentProvider.viva &&
        (props.properties.vivaPaymentsApplePay || window.config.applePayEnabled === '1')
      ) {
        // @ts-ignore applePay is available for this provider
        const ap = state.paymentProviderObject?.getApplePay ? state.paymentProviderObject?.getApplePay() : false;
        if (ap) {
          applePay.hide = !ap.isApplePayAvailable();
          applePay.onClick = ap.onApplePayButtonClicked;
        }
      }

      return {
        id: provider.paymentProviderId,
        currentStep: `amount-${paymentProviderNameById[provider.paymentProviderId]?.toLowerCase()}`,
        buttons: getDepositButtons(),
        selectedBonusInfo: state.selectedBonusInfo ?? getBonusInfo(new Bonus(false), false, provider.paymentProviderId),
        bonusCounter: state.bonusCategories?.[provider.paymentProviderId]?.length ?? 0,
        taxValue: state.taxes,
        currency: formatCurrency(currentCurrency),
        value: state.depositValue,
        error: error,
        onValueChange: onInputChange,
        changePaymentProvider: () => {
          // asd needs to be fixed?
          changePaymentProvider(provider.paymentProviderId);
        },
        isOpen: state.paymentProvider == provider.paymentProviderId && state.isOpen,
        inputAmountDisabled:
          provider.paymentProviderId == PaymentProvider.okto || provider.paymentProviderId == PaymentProvider.abon,
        onTCChange: onTCChange,
        onPrivacyChange: onPrivacyChange,
        hideTC: hideTC,
        confirmButton: {
          state: depositDisabled ? 'disabled' : 'default',
          colorMapping: depositDisabled ? 'inverted' : 'primary',
          buttonType: 'filled',
          size: 'small',
          style: 'text',
          shadow: depositDisabled ? 'none' : 'depth_2',
          loading: state.loading,
          iconLeft: false,
          iconRight: false,
          ctaText: 'Deposit',
          icon: '',
          onClick: depositDisabled ? () => 0 : confirmDepositValue,
        },
        applePayButton: applePay,
      };
    }),
    providerWithCard: {
      cards: state.cardsLoading
        ? [
            skeletonCard,
            skeletonCard,
            skeletonCard,
            skeletonCard,
            skeletonCard,
            skeletonCard,
            skeletonCard,
            skeletonCard,
          ]
        : state.paymentProviderObject?.getCards() ?? [],
      isSelectedCard: state.paymentProviderObject?.getPaymentDetails()?.card ?? false,
      emptyFn: () => 0,
      useNewCard: () => {
        // asd needs to be fixed?
        state.paymentProviderObject?.setPaymentDetails({
          ...state.paymentProviderObject?.getPaymentDetails(),
          card: false,
        });
        setState((v) => ({
          ...v,
          currentStep: 'deposit-viva-card',
        }));
      },
      onCardNumberChange: (e: React.ChangeEvent<HTMLInputElement>) => {
        // asd needs to be fixed?
        const oldValue = state.cardInfo?.number ?? '';
        const value = e.target.value;
        let newValue = oldValue;
        if (value.match(/^[0-9\s]*$/) && value.length <= 19) {
          newValue = value
            .replace(/\D/g, '')
            .replace(/(\d{4})(\d)/, '$1 $2')
            .replace(/(\d{4})(\d)/, '$1 $2')
            .replace(/(\d{4})(\d)/, '$1 $2');
        }
        if (newValue !== oldValue) {
          // @ts-ignore
          setState((v) => ({
            ...v,
            cardInfo: {
              ...v.cardInfo,
              number: newValue,
            },
          }));
        }
      },
      onNameChange: (e: React.ChangeEvent<HTMLInputElement>) => {
        // asd needs to be fixed?
        const oldValue = state.cardInfo?.name ?? '';
        const value = e.target.value;
        let newValue = oldValue;
        if (value.length <= 50) {
          if (value.match(/^[a-zA-Z-\s]*$/)) {
            newValue = value;
          } else {
            if (value.length === 1) {
              newValue = '';
            }
          }
        }
        // @ts-ignore
        if (newValue !== oldValue) {
          // @ts-ignore
          setState((v) => ({
            ...v,
            cardInfo: {
              ...v.cardInfo,
              name: newValue,
            },
          }));
        }
      },
      onExpiryDateChange: (e: React.ChangeEvent<HTMLInputElement>) => {
        // asd needs to be fixed?
        let value = e.target.value?.replace(/\D/g, '');
        let month = '';
        let year = '';
        switch (value.length) {
          case 1:
            if (value.length === 1 && parseInt(value) > 1) {
              value = '0' + value;
            }
            month = value;
            break;
          case 2:
            if (parseInt(value[0]) === 1 && parseInt(value[1]) > 2) {
              month = '0' + value[0];
              year = value[1];
              value = '0' + value[0] + '/' + value[1];
            } else {
              month = value;
            }
            break;
          case 3:
            month = value[0] + value[1];
            year = value[2];
            value = value[0] + value[1] + '/' + value[2];
            break;
          case 4:
            month = value[0] + value[1];
            year = value[2] + value[3];
            value = value[0] + value[1] + '/' + value[2] + value[3];
            break;
          default:
            month = state.cardInfo?.expiry?.month ?? '';
            value = state.cardInfo?.expiry?.formatted ?? '';
            year = state.cardInfo?.expiry?.year ?? '';
            break;
        }
        // @ts-ignore
        setState((v) => ({
          ...v,
          cardInfo: {
            ...v.cardInfo,
            expiry: {
              month: e.target.value.split('/')[0],
              year: e.target.value.split('/')[1],
              formatted: value,
            },
          },
        }));
      },
      onSecurityCodeChange: (e: React.ChangeEvent<HTMLInputElement>) => {
        // asd needs to be fixed?
        let value = state.cardInfo?.cvv ?? '';
        if (e.target?.value.match(/^[0-9]*$/) && e.target?.value?.length <= 4) {
          value = e.target.value;
        } else {
          value = state.cardInfo?.cvv ?? '';
        }

        // @ts-ignore
        setState((v) => ({
          ...v,
          cardInfo: {
            ...v.cardInfo,
            cvv: value,
          },
        }));
      },
      cardNumber: state.cardInfo?.number ?? '',
      name: state.cardInfo?.name ?? '',
      expiryDate: state.cardInfo?.expiry?.formatted ?? '',
      securityCode: state.cardInfo?.cvv ?? '',
      year: state.cardInfo?.expiry?.year ?? '',
      month: state.cardInfo?.expiry?.month ?? '',
    },
    toAmount: toAmount,
    toBonuses: toBonuses,
    depositCode: depositCode,
    playerName: profile?.first_name + ' ' + profile?.last_name,
    playerNin: profile?.nin,
    playerDateOfBirth: getDateOfBirth(profile?.nin),
  };

  return (
    <ModuleElementDiv className={props.className ?? ''} $styleText={props.styleText}>
      <DataElementContext.Provider value={contextValue}>{children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default Deposit;

import { PaymentProviderInterface } from '@/components/classes/PaymentProviders/Deposit/Interfaces/PaymentProviderInterface';
import PaymentProvider from '@/components/classes/PaymentProviders/Deposit/PaymentProvider';
import { initiateBridgerPayDeposit } from '@/modules/casino/store/actions/deposit';
import { PaymentProvider as PaymentProviderConstant } from '@/constants/paymentProvider';

interface BridgerIframe {
  injectScriptID: string;
  cashierKey: string;
  cashierToken: string;
}

class Bridger extends PaymentProvider implements PaymentProviderInterface {
  injectScript(data: BridgerIframe): void {
    if (data.cashierKey && data.cashierToken) {
      // inject script to #inject-bp-script
      const script = document.createElement('script');
      script.src = 'https://checkout.bridgerpay.com/v2/loader';
      script.id = data.injectScriptID + '-' + 'script';

      const attributes: {
        [key: string]: string;
      } = {
        'data-cashier-key': data.cashierKey,
        'data-cashier-token': data.cashierToken,

        // 'data-single-payment-method': 'credit_card', // force one
        // 'data-single-payment-provider': '',
        // 'data-currency-lock': 'true',
        // 'data-amount-lock': 'true',
        // 'data-direct-payment-method': 'credit_card', // suggest one

        'data-theme': 'transparent',
        'data-hide-header': 'true',
        'data-splash-screen-image-url': '',
      };

      // set all atrributes
      Object.keys(attributes).forEach((key: any) => {
        script.setAttribute(key, attributes[key]);
      });

      script.async = true;
      const el = document.getElementById(data.injectScriptID);
      if (el) {
        // remove all children
        while (el.firstChild) {
          el.removeChild(el.firstChild);
        }
        el.appendChild(script);
      }
    }
  }

  init(data: BridgerIframe): void {
    super.init(data);
    this.injectScript(data);
    window.addEventListener('[bp]:all', this.processBridgerPayMessages, false);
    this.setType(PaymentProviderConstant.bridger);
  }

  destroy(): void {
    window.removeEventListener('[bp]:all', this.processBridgerPayMessages, false);
  }
  /**
   * cazuri de erori depistate la deposit primite din iframe, pentru care nu stiu ce sa fac pt moment:
   *
   * - init diferit de success ([bp]:init)
   * - failure la create session ([bp]:create-session)
   * - eroare ([bp]:error)
   * - redirect fara sa am un url ([bp]:redirect)
   *
   * Posibil sa fie si altele. Plus posibila eroare de la core la generarea token-ului.
   * @param e
   */
  processBridgerPayMessages = (e: any) => {
    const { event, type, url } = e.detail;

    switch (event) {
      case '[bp]:init':
      case '[bp]:create-session':
        // [bp]:create-session => type: 'success' | 'failure',
        if (type !== 'success') {
          this.triggerError();
        }
        break;
      case '[bp]:contentRendered':
        this.hideLoader();
        break;
      case '[bp]:processingDeposit':
        // loading
        // showLoading();
        break;
      case '[bp]:error':
        // {error: Session is closed}
        this.hideLoader();
        break;
      case '[bp]:loadingPSP':
        if (type === 'first-psp') {
          this.hideLoader();
        }
        break;
      case '[bp]:processingPSP':
        // showLoading();
        break;
      case '[bp]:deposit':
        // type: 'approved' | 'declined' | 'pending'
        if (type === 'success' || type === 'approved') {
          // success
          this.triggerSuccess();
        } else if (type === 'pending') {
          // pending
          this.triggerPending();
        } else {
          // error
          this.triggerError();
        }
        break;
      case '[bp]:redirect':
        if (url) {
          window.location.href = url;
        } else {
          this.triggerPending();
        }
        break;
      default:
        break;
    }
  };

  confirmPayment(): void {
    this.dispatch(
      initiateBridgerPayDeposit({
        amount: this.paymentDetails?.amount ?? 0,
        currencyCode: this.paymentDetails?.currency ?? '',
        theme: 'transparent',
        bonusId: this.paymentDetails?.bonusId ?? null,
      }),
    );
  }
}

export default Bridger;

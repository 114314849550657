import React from 'react';

function easeOutElastic(x) {
  const c4 = (2 * Math.PI) / 3;
  return x === 0
    ? 0
    : x === 1
      ? 1
      : Math.pow(2, -10 * x) * Math.sin((x * 10 - 0.75) * c4) + 1;
}

const useCountRandom = (props) => {
  const { begin, duration, delay } = props;

  const [state, setState] = React.useState({
    duration: duration,
    delay: delay,
    current: 0,
  });

  React.useEffect(() => {
    let startTime;
    let rafId;
    let elapsed;
    let progress;
    let current;

    const step = (timestamp) => {
      if (!startTime) {
        startTime = timestamp;
      }

      elapsed = timestamp - startTime;
      progress = Math.min(elapsed / duration, 1);
      current = easeOutElastic(progress);

      setState((prevState) => ({
        ...prevState,
        current: current,
      }));

      if (progress < 1) {
        rafId = requestAnimationFrame(step);
      }
    };

    if (begin) {
      if (state.current < 1) {
        if (delay > 0) {
          setTimeout(() => {
            rafId = requestAnimationFrame(step);
          }, delay);
        } else {
          rafId = requestAnimationFrame(step);
        }
      }
    }

    return () => {
      cancelAnimationFrame(rafId);
    };
  }, [begin]); // eslint-disable-line

  React.useEffect(() => {
    if (!begin) {
      setState({
        duration: duration,
        delay: delay,
        current: 0,
      });
    }
  }, [begin, duration, delay]);

  return state.current;
};

export default useCountRandom;

import React from 'react';
import styled from 'styled-components';

import { DataElementContext } from '../../../../page-components/common/DataElementContext';
import evBus from '../../../../utils/evbus';

type SocialHubTogglerProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: {
    dsType: string;
  };
};

const defaultProps = {
  className: '',
  styleText: '',
  properties: {
    dsType: '',
  },
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const SocialHubToggler = (componentProps: SocialHubTogglerProps) => {
  const tmpProps = { ...defaultProps, ...componentProps };
  delete tmpProps.children;
  const props = JSON.parse(JSON.stringify(tmpProps));
  const { children } = componentProps;

  const hashParams = window.location.hash.substring(1);
  const params = new URLSearchParams(hashParams);
  const hashPostId = params.get('postId');

  const [state, setState] = React.useState({
    open: hashPostId ? true : false,
    defaultTabOpened: '',
  });
  const eventBusType = props.properties?.eventBusType;

  const onToggle = (data: string) => {
    setState((prevState) => {
      const newState = {
        ...prevState,
        open: !prevState.open,
      };

      if (data && typeof data === 'string') {
        newState.defaultTabOpened = data;
      }

      return newState;
    });
  };

  React.useEffect(() => {
    if (eventBusType) {
      evBus.on(eventBusType, onToggle);
    }

    return () => {
      if (eventBusType) {
        evBus.remove(eventBusType, onToggle);
      }
    };
  }, [eventBusType]);

  const contextValue = React.useMemo(() => {
    return {
      open: state.open,
      defaultTabOpened: state.defaultTabOpened,
      onToggle: onToggle,
    };
  }, [state, onToggle, eventBusType]);

  return (
    <ModuleElementDiv className={props.className ?? ''} $styleText={props.styleText}>
      <DataElementContext.Provider value={contextValue}>{children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default SocialHubToggler;

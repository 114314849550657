import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { useAppSelector, useAppDispatch } from '../../../store';
import { DataElementContext } from '../../../page-components/common/DataElementContext';
import { changeLanguage, changeAccountLanguage } from '../../../modules/casino/store/actions/application';

import './index.scss';

type LanguageSwitcherProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: {
    dsType: string;
  };
};

const defaultProps = {
  className: '',
  styleText: '',
  properties: {
    dsType: '',
  },
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const LanguageSwitcher = (componentProps: LanguageSwitcherProps) => {
  const tmpProps = { ...defaultProps, ...componentProps };
  delete tmpProps.children;
  const props = JSON.parse(JSON.stringify(tmpProps));
  const { children } = componentProps;

  const { i18n } = useTranslation();

  const dispatch = useAppDispatch();
  const currentLanguage = useAppSelector((state) => state.application.language);

  React.useEffect(() => {
    if (currentLanguage && currentLanguage != i18n.language) {
      i18n.changeLanguage(currentLanguage);
    }
  }, []);

  const onChangeLanguage = (e: React.MouseEvent<HTMLElement>) => {
    if (e.currentTarget.dataset.language) {
      const language = e.currentTarget.dataset.language;

      dispatch(changeLanguage(language));
      dispatch(changeAccountLanguage(language));
      i18n.changeLanguage(language);
    }
  };

  const cl = currentLanguage ? currentLanguage : i18n.language;

  const contextValue = {
    currentLanguage: cl,
    languages: [
      { id: 'en', isSelected: cl === 'en', onChangeLanguage },
      { id: 'ro', isSelected: cl === 'ro', onChangeLanguage },
    ],
    onChangeLanguage: onChangeLanguage,
  };

  return (
    <ModuleElementDiv className={props.className ?? ''} $styleText={props.styleText}>
      <DataElementContext.Provider value={contextValue}>{children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default LanguageSwitcher;

// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { endpoints } from '../../api/endpoints';
import { RootState } from '../index';

enum ItemDisplayType {
  INDIVIDUAL_ITEM = 'INDIVIDUAL_ITEM',
  STACK = 'STACK',
}

type CampaignItem = {
  item_id: string;
  item_name: string;
  item_display_type: ItemDisplayType;
  item_available_count?: number;
  item_collected_count?: number;
  item_value: string;
  item_art_bundle: {
    logo: string;
  };
};

type Collected = {
  collect_id: string;
  collect_time: string;
  collect_item_id: string;
};

type Campaign = {
  campaign_id: string;
  campaign_name: string;
  campaign_start_date: string;
  campaign_end_date: string;
  campaign_status: string;
  campaign_art_bundle: {
    inline_logo: string;
    logo: string;
  };
  items: CampaignItem[];
  total_available: number;
  total_collected: number;
  campaign_value: string;
  campaign_currency: string;
};

type FetchCampaignResult = {
  data: any;
  success: boolean;
};

type FetchCampaignError = {
  rejectValue: {
    error: string;
  };
};

let lastCampaignRequest = 0;

export const fetchCampaign = createAsyncThunk<FetchCampaignResult, void, FetchCampaignError>(
  'airdrop/status',
  async (_, { rejectWithValue, getState }) => {
    try {
      const state: RootState = getState() as RootState;

      if (new Date().getTime() - lastCampaignRequest < 5000) {
        return { data: null, success: false };
      }
      lastCampaignRequest = new Date().getTime();

      const response = await axios.get(endpoints.airdrop.status, {
        headers: {
          Authorization: 'Bearer ' + state.authentication.access_token,
        },
      });

      if (response.data) {
        const data = response.data;
        if (data?.campaign?.campaign_art_bundle?.length) {
          data.campaign.campaign_art_bundle = data.campaign.campaign_art_bundle[0];
        }

        if (data?.campaign?.items?.length) {
          data.campaign.items = data.campaign.items.map((item: any) => {
            if (item?.item_art_bundle?.length) {
              item.item_art_bundle = item.item_art_bundle[0];
            }
            return item;
          });
        }

        if (data?.collected?.item_art_bundle?.length) {
          data.collected.item_art_bundle = data.collected.item_art_bundle[0];
        }

        return { data: data, success: true };
      }

      return rejectWithValue({
        error: "Couldn't fetch airdrop campaign",
      });
    } catch (err: any) {
      const errResp = { error: err.toString() };
      return rejectWithValue(errResp);
    }
  },
);

type CollectedItem = {
  collect: Collected;
  campaign?: Campaign;
  item?: CampaignItem;
  loaded?: boolean;
};

type CollectedItems = {
  [id: string]: CollectedItem | null;
};

type FetchCollectedProps = {
  ids: string[];
};

type FetchCollectedResult = {
  requested_ids: string[];
  data: CollectedItem[] | null;
  success: boolean;
};

type FetchCollectedError = {
  rejectValue: {
    error: string;
  };
};

const lastCollectedRequest: { [id: string]: number } = {};

export const fetchCollected = createAsyncThunk<FetchCollectedResult, FetchCollectedProps, FetchCollectedError>(
  'airdrop/collected-items',
  async ({ ids }, { rejectWithValue, getState }) => {
    try {
      const state: RootState = getState() as RootState;

      if (!ids.length) {
        return { requested_ids: ids, data: null, success: true };
      }

      // rate limit requests
      const idsStr = ids.join(',');
      if (lastCollectedRequest[idsStr] && new Date().getTime() - lastCollectedRequest[idsStr] < 5000) {
        return { requested_ids: ids, data: null, success: false };
      }
      lastCollectedRequest[idsStr] = new Date().getTime();

      const response = await axios.post(
        endpoints.airdrop.getCollected,
        { ids: ids },
        {
          headers: {
            Authorization: 'Bearer ' + state.authentication.access_token,
          },
        },
      );

      if (response.data) {
        const data = response.data;
        response.data?.forEach?.((ci: CollectedItem) => {
          // @ts-ignore
          if (ci?.campaign?.campaign_art_bundle?.length) {
            // @ts-ignore
            ci.campaign.campaign_art_bundle = ci.campaign.campaign_art_bundle[0];
          }

          // @ts-ignore
          if (ci?.item?.item_art_bundle?.length) {
            // @ts-ignore
            ci.item.item_art_bundle = ci.item.item_art_bundle[0];
          }
        });
        return { requested_ids: ids, data: data, success: true };
      }

      return rejectWithValue({
        error: "Couldn't fetch airdrop collected items",
      });
    } catch (err: any) {
      const errResp = { error: err.toString() };
      return rejectWithValue(errResp);
    }
  },
);

type FetchCollectProps = {
  id: string;
};

type FetchCollectResult = {
  data: CollectedItem | null;
  success: boolean;
};

export const collectItem = createAsyncThunk<FetchCollectResult, FetchCollectProps, FetchCollectedError>(
  'airdrop/collect-item',
  async ({ id }, { rejectWithValue, getState }) => {
    try {
      const state: RootState = getState() as RootState;

      if (!id) {
        return { data: null, success: true };
      }

      const response = await axios.get(`${endpoints.airdrop.collect}/${id}`, {
        headers: {
          Authorization: 'Bearer ' + state.authentication.access_token,
        },
      });

      if (response.data) {
        const data = response.data;

        if (data?.campaign?.campaign_art_bundle?.length) {
          data.campaign.campaign_art_bundle = data.campaign.campaign_art_bundle[0];
        }
        if (data?.item?.item_art_bundle?.length) {
          data.item.item_art_bundle = data.item.item_art_bundle[0];
        }

        return { data: response.data, success: true };
      }

      return rejectWithValue({
        error: "Couldn't fetch airdrop collected items",
      });
    } catch (err: any) {
      const errResp = { error: err.toString() };
      return rejectWithValue(errResp);
    }
  },
);

export interface AirdropState {
  campaign: Campaign | null;
  collectedItems: CollectedItems;
  loading: boolean;
  error: any;
}

export const airdropSlice = createSlice({
  name: 'airdrop',
  initialState: <AirdropState>{
    campaign: null,
    collectedItems: {},
    loading: false,
    error: null,
  },
  reducers: {
    updateAirdropCampaign: (state, action) => {
      if (action.payload?.campaign_art_bundle?.length) {
        action.payload.campaign_art_bundle = action.payload.campaign_art_bundle[0];
      }
      if (action.payload?.items?.length) {
        action.payload.items = action.payload.items.map((item: any) => {
          if (item?.item_art_bundle?.length) {
            item.item_art_bundle = item.item_art_bundle[0];
          }
          return item;
        });
      }

      state.campaign = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCampaign.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload?.success) {
          state.campaign = action.payload.data.campaign ?? null;

          if (action.payload.data.collected?.collect?.collect_id && state.campaign?.campaign_id) {
            const collected = action.payload.data.collected;

            if (collected?.collect?.collect_id && state.collectedItems) {
              if (!state.collectedItems[state.campaign?.campaign_id]) {
                state.collectedItems[state.campaign?.campaign_id] = collected;
              } else {
                // @ts-ignore
                state.collectedItems[state.campaign?.campaign_id] = collected;
              }
            }
          }
        }
        console.log('fetchCampaign.fulfilled', action.payload, state.campaign);
      })
      .addCase(fetchCampaign.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCampaign.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.error;
        console.log('fetchCampaign.rejected', action.payload);
      })
      .addCase(fetchCollected.fulfilled, (state, action) => {
        console.log('fetchCollected.fulfilled', action.payload);
        if (action.payload.success) {
          const rids: { [id: string]: boolean } = {};
          for (const id of action.payload.requested_ids) {
            rids[id] = true;
          }
          if (action.payload.data && state.collectedItems) {
            for (const ci of action.payload.data) {
              if (ci?.campaign?.campaign_id && state.collectedItems[ci?.campaign?.campaign_id] != null) {
                state.collectedItems[ci?.campaign?.campaign_id] = { ...ci, loaded: true };
                delete rids[ci?.campaign?.campaign_id];
              }
            }
          }
          if (state.collectedItems) {
            for (const id in rids) {
              delete state.collectedItems[id];
            }
          }
        }
      })
      .addCase(fetchCollected.rejected, (state, action) => {
        state.error = action.payload?.error;
        console.log('fetchCollected.rejected', action.payload);
      })
      .addCase(collectItem.fulfilled, (state, action) => {
        console.log('collectItem.fulfilled', action);
        if (action.payload.success) {
          if (action.payload.data) {
            const ci = action.payload.data;
            if (ci?.campaign?.campaign_id) {
              state.collectedItems[ci?.campaign?.campaign_id] = { ...ci, loaded: true };
            }
          }
        }
      })
      .addCase(collectItem.pending, (state, ...rest) => {
        console.log('collectItem.pending', rest);
      })
      .addCase(collectItem.rejected, (state, action) => {
        state.error = action.payload?.error;
        console.log('collectItem.rejected', action.payload);
      });
  },
});

export const { updateAirdropCampaign } = airdropSlice.actions;

export default airdropSlice.reducer;

import { PaymentProviderInterface } from '@/components/classes/PaymentProviders/Deposit/Interfaces/PaymentProviderInterface';
import PaymentProvider from '@/components/classes/PaymentProviders/Deposit/PaymentProvider';
import { PaymentProvider as PaymentProviderConstant } from '@/constants/paymentProvider';
import { requestPaymentLink } from '@/modules/casino/store/actions/paymentCheckout';

/**
 * Safecharge class
 * @extends PaymentProvider
 * @implements PaymentProviderInterface
 * this class is used to handle Safecharge AND Skrill payment providers
 */
class Safecharge extends PaymentProvider implements PaymentProviderInterface {
  init(data: any): void {
    super.init(data);
    this.setType(data.paymentProvider);
  }
  confirmPayment(): void {
    this.dispatch(
      requestPaymentLink(
        this.paymentDetails?.amount ?? 0,
        this.paymentDetails?.bonusId ?? null,
        this.getType() === PaymentProviderConstant.skrill,
      ),
    );
  }
}

export default Safecharge;

// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { RootState } from '../index';

const DEBUG = false;

type FetchConfigProps = {
  id: string;
};

type FetchConfigResult = {
  data: {
    data: any[];
  };
  success: boolean;
};

type FetchConfigError = {
  rejectValue: {
    error: string;
  };
};

const apiUrl = window.config.dataSourceApiUrl;

export const fetchWheelsDataSource = createAsyncThunk<FetchConfigResult, FetchConfigProps, FetchConfigError>(
  'wheelsDataSource/list',
  async ({ id }, { rejectWithValue, getState }) => {
    try {
      const state: RootState = getState() as RootState;

      const response = await axios.post(
        `${apiUrl}/resolve/sources`,
        {
          ids: [id],
        },
        {
          headers: {
            Authorization: 'Bearer ' + state.authentication.access_token,
          },
        },
      );

      //console.log('fetchWheelsDataSource', response.data);

      if (response.data?.[id]) {
        return { data: response.data?.[id], success: true };
      }

      return rejectWithValue({
        error: "Couldn't fetch wheels data source",
      });
    } catch (err: any) {
      const errResp = { error: err.toString() };
      return rejectWithValue(errResp);
    }
  },
);

export interface ConfigReducer {
  wheels: any;
  loading: boolean;
  error: any;
}

export const wheelsDataSourceSlice = createSlice({
  name: 'wheelsDataSource',
  initialState: <ConfigReducer>{
    wheels: {},
    loading: false,
    error: null,
  },
  reducers: {
    clearWheelsDataSource: (state) => {
      state.wheels = {};
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchWheelsDataSource.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload.success) {
          //console.log('fetchWheelsDataSource.fulfilled', action.payload);
          if (action.payload.data && action.payload.data.data) {
            const data: any = action.payload.data.data;
            const wheels: any = {};

            data?.forEach?.((item: any) => {
              if (item?.id) {
                wheels[item?.id] = item;
              }
            });

            state.wheels = wheels;
          }
        }
        DEBUG && console.log('fetchWheelsDataSource.fulfilled', action.payload);
      })
      .addCase(fetchWheelsDataSource.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchWheelsDataSource.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.error;
        DEBUG && console.log('fetchWheelsDataSource.rejected', action.payload);
      });
  },
});

export const { clearWheelsDataSource } = wheelsDataSourceSlice.actions;

export default wheelsDataSourceSlice.reducer;

import React from 'react';
import styled from 'styled-components';
import { useMatches } from 'react-router-dom';

import './index.scss';

import { useAppSelector, useAppDispatch } from '../../../../store';
import { DataElementContext } from '../../../../page-components/common/DataElementContext';
import { useTicketUpdater } from '../utils/useTicketUpdater';
import { generateRandomInRange } from '../utils/functions';
import { useMediaQuery } from '../../../utils/useQueryMedia';
import moment from 'moment';
import evBus from '../../../../utils/evbus';

import FavNumbersDialog from '../../../../modules/lotto/components/FavNumbers';
import { setFavNumbers } from '../../../../modules/lotto/store/actions/fav-numbers';

// @ts-ignore
import { gsap, TimelineMax, TweenMax, Power3, Power2, Linear, Draggable } from 'gsap/all';

gsap.registerPlugin(Draggable, TweenMax, TimelineMax, Power2, Power3, Linear);

type LottoEventDetailsProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: {
    dsType: string;
  };
};

const defaultProps = {
  className: '',
  styleText: '',
  properties: {
    dsType: '',
  },
};

const getInitialData = (bsTicket: any, systemId: string) => {
  if (bsTicket && bsTicket.event?.system_id === systemId && bsTicket.numbers) {
    const now = moment().format('YYYY-MM-DD HH:mm:ss');
    const selectedEventsDates = bsTicket.selectedEvents.map((e: any) => e.event_date).filter((e: any) => e >= now);
    let selectedNextEvents = (bsTicket.allSelectedEvents?.length ?? 0) - (bsTicket.selectedEvents?.length ?? 0);
    selectedNextEvents = selectedNextEvents > 0 ? selectedNextEvents : 0;

    const selectedBalls: any = {};
    bsTicket.numbers.forEach((ball: any) => {
      selectedBalls[ball] = true;
    });

    let selectedLuckyBet = null;
    if (bsTicket.extra?.luckyNumber) {
      selectedLuckyBet = bsTicket.extra.luckyNumber.toString();
    }

    return {
      selectedEventsDates: selectedEventsDates ?? [],
      selectedNextEvents: selectedNextEvents ?? 0,
      selectedBalls: selectedBalls ?? {},
      selectedLuckyBet,
    };
  }

  return {};
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const LottoEventDetails = (componentProps: LottoEventDetailsProps) => {
  const tmpProps = { ...defaultProps, ...componentProps };
  delete tmpProps.children;
  const props = JSON.parse(JSON.stringify(tmpProps));
  const { children } = componentProps;

  const matches = useMatches();
  const lottoEvents = useAppSelector((state) => state.lotto.lottoEvents.items);
  const lottoSystems = useAppSelector((state) => state.lotto.lottoEvents.systems);
  const [openFavDialog, setOpenFavDialog] = React.useState(false);

  // try to initialize systemId from the properties object
  let systemId = props.properties?.systemId;
  let eventId = props.properties?.eventId;

  // try to get systemId from the path if targetIdFromPath and pathParamKey are set
  if (props.properties && props.properties.targetIdFromPath && props.properties.pathParamKey) {
    if (matches && matches.length) {
      // there is no gameId set as props; maybe were in a route that includes a :pathParamKey param
      const match = matches[0];
      let paramKey = props.properties.pathParamKey;
      if (paramKey?.[0] === ':') {
        paramKey = paramKey.slice(1);
      }
      if (match.params && match.params[paramKey] != null) {
        systemId = match.params[paramKey];
      }
    }
  }

  if (props.properties?.paramSystemId) {
    if (matches && matches.length) {
      // there is no gameId set as props; maybe were in a route that includes a :pathParamKey param
      const match = matches[0];
      let paramKey = props.properties?.paramSystemId;
      if (paramKey?.[0] === ':') {
        paramKey = paramKey.slice(1);
      }
      if (match.params && match.params[paramKey] != null) {
        systemId = match.params[paramKey];
      }
    }
  }

  if (props.properties?.paramEventId) {
    if (matches && matches.length) {
      // there is no gameId set as props; maybe were in a route that includes a :pathParamKey param
      const match = matches[0];
      let paramKey = props.properties?.paramEventId;
      if (paramKey?.[0] === ':') {
        paramKey = paramKey.slice(1);
      }
      if (match.params && match.params[paramKey] != null) {
        eventId = match.params[paramKey];
      }
    }
  }

  let event = lottoEvents.find((event: any) => {
    if (systemId && eventId) {
      return event.system_id === systemId && event.event_id === eventId;
    } else if (systemId) {
      return event.system_id === systemId;
    }
    return false;
  });
  if (!event) {
    event = lottoEvents.find((event: any) => event.system_id === systemId);
  }

  const expiredLottoEvents = useAppSelector((state) => state.lotto.lottoEvents.expiredItems);
  const initialized = useAppSelector((state) => state.lotto.lottoEvents.initialized);
  const loading = useAppSelector((state) => state.lotto.lottoEvents.loading);
  const bonuses = useAppSelector((state) => state.lotto.bonuses.items);
  const bsTicket = useAppSelector((state) => state.lotto.betsSlip.lottoTicket);

  const initialData = getInitialData(bsTicket, systemId);

  const [selectedEventsDates, setSelectedEventDates] = React.useState<string[]>(
    initialData['selectedEventsDates'] ?? [],
  );
  const [selectedNextEvents, setSelectedNextEvents] = React.useState(initialData['selectedNextEvents'] ?? 0);
  const [selectedLuckyBet, setSelectedLuckyBet] = React.useState<string | null>(
    initialData['selectedLuckyBet'] ?? null,
  );
  const [selectedBalls, setSelectedBalls] = React.useState<{ [id: string]: boolean }>(
    initialData['selectedBalls'] ?? {},
  );
  const [zoomed, setZoomed] = React.useState(false);
  const [showRandom, setShowRandom] = React.useState(false);
  const [changeLuckyBet, setChangeLuckyBet] = React.useState(false);
  const isDesktop = useMediaQuery('(min-width:900px)');
  const dispatch = useAppDispatch();

  let eligibleLuckyNumber = false;
  if (bonuses?.length) {
    bonuses.find((bonus: any) => {
      if (bonus.lottery_systems.indexOf(systemId) > -1) {
        eligibleLuckyNumber = true;
        return true;
      }
      return false;
    });
  }

  const lottoTicket = useTicketUpdater({
    event,
    selectedBalls,
    selectedEventsDates,
    selectedNextEvents,
    luckyNumber: selectedLuckyBet,
    isDesktop,
  });

  const handleFavDialogOpen = () => {
    setOpenFavDialog(true);
  };
  const handleFavDialogClose = () => {
    setOpenFavDialog(false);
  };
  const handleAddToFavorite = React.useCallback(() => {
    if (event) {
      const numbers: number[] = [];
      Object.keys(selectedBalls).forEach((key: any) => {
        if (selectedBalls[key]) {
          numbers.push(parseInt(key, 10));
        }
      });

      if (numbers.length) {
        dispatch(setFavNumbers(event?.event_name, numbers));
        animateFavBalls(numbers);
      }
    }
  }, [event, selectedBalls]);

  const animateFavBalls = (selectedNumbers: any[]) => {
    const balls: any = [];

    const bottomSection = document.querySelector('.lotto-bottom-section');
    const destinationElem = document.querySelector('.lotto-fav-icon');
    if (!bottomSection || !destinationElem) return;
    const bsRect = bottomSection.getBoundingClientRect();
    const dest = destinationElem.getBoundingClientRect();

    selectedNumbers.forEach((n) => {
      const ball = document.querySelector(`.button_${n}`);
      if (ball) {
        const ballRect = ball.getBoundingClientRect();
        const top = ballRect.top - bsRect.top;
        const left = ballRect.left - bsRect.left;

        const newBall: any = ball.cloneNode(true);
        newBall.classList.add('floating-ball', `floating-ball-${n}`);
        newBall.style.top = `${top}px`;
        newBall.style.left = `${left}px`;

        balls.push(newBall);
      }
    });

    balls.forEach((ball: any) => {
      bottomSection.appendChild(ball);
    });

    const tl = gsap.timeline({
      onCompleteParams: [[...balls]],
      onComplete: (balls: any[]) => {
        tl.kill();
        balls.forEach((ball: any) => {
          ball.parentNode.removeChild(ball);
        });
      },
    });

    const dest_top = dest.top - bsRect.top;
    const dest_left = dest.left - bsRect.left;

    setTimeout(() => {
      tl.to('.floating-ball', {
        top: dest_top,
        left: dest_left,
        duration: 0.2,
        stagger: 0.03,
        ease: 'power4',
      });
    }, 0);
  };

  // switch lotto balls table to zoomed view and back
  const onToggleZoom = React.useCallback(() => {
    setZoomed((v) => !v);
  }, []);

  // show random selection UI
  const onToggleRandom = React.useCallback(() => {
    setShowRandom((v) => !v);
  }, []);

  // choose a lotto event date (from the first 5 visible ones)
  const onEventDateClick = React.useCallback((e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    if (e?.currentTarget?.dataset?.date) {
      const date = e.currentTarget.dataset.date;
      setSelectedEventDates((v) => {
        if (v.includes(date)) {
          return v.filter((d) => d !== date);
        }
        return [...v, date];
      });
    }
  }, []);

  const onToggleChangeLuckyBet = React.useCallback(() => {
    if (eligibleLuckyNumber && Object.keys(selectedBalls).length) {
      setChangeLuckyBet((v) => !v);
    }
  }, [eligibleLuckyNumber, selectedLuckyBet, selectedBalls]);

  // choose how many lotto events to select after the first 5 visible ones
  const onSelectedNextEventRange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    if (e?.target?.value != null) {
      setSelectedNextEvents(parseInt(e.target.value, 10));
    }
  }, []);

  // select a lotto ball number
  const onToggleBallNumber = React.useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      if (e?.currentTarget?.dataset?.ball) {
        const ball = e.currentTarget.dataset.ball;

        if (!changeLuckyBet) {
          if (eligibleLuckyNumber && !selectedLuckyBet) {
            setSelectedLuckyBet(ball);
          } else if (eligibleLuckyNumber && selectedBalls[ball] && selectedLuckyBet?.toString() === ball?.toString()) {
            setSelectedLuckyBet(null);
          }

          setSelectedBalls((v) => {
            let count = 0;
            Object.keys(v).forEach((key) => {
              if (v[key]) count++;
            });

            if (count === event?.k && !v[ball]) {
              return v;
            }

            if (v[ball]) {
              return { ...v, [ball]: false };
            }
            return { ...v, [ball]: true };
          });
        } else if (ball && selectedBalls[ball]) {
          setSelectedLuckyBet(ball);
          setChangeLuckyBet(false);
        }
      }
    },
    [selectedBalls, eligibleLuckyNumber, selectedLuckyBet, changeLuckyBet],
  );

  // generate random balls selection with a maximum number of event.k balls
  const onToggleRandomBalls = React.useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      if (e?.currentTarget?.dataset?.balls) {
        const balls = parseInt(e.currentTarget.dataset.balls, 10);

        if (balls === 0) {
          setSelectedBalls({});
          return;
        }

        const generatedBalls = generateRandomInRange(balls, 0, event?.n ?? 0);

        const newBalls: any = {};
        generatedBalls.forEach((ball) => {
          newBalls[ball] = true;
        });

        setSelectedBalls(newBalls);
        if (eligibleLuckyNumber) {
          setSelectedLuckyBet(generatedBalls[0].toString());
        } else {
          setSelectedLuckyBet(null);
        }
      } else {
        setSelectedBalls({});
        setSelectedLuckyBet(null);
      }
    },
    [eligibleLuckyNumber],
  );

  const handleFavNumbersSelect = (numbers: any) => {
    const newNumbers: any = {};
    numbers.forEach((n: any) => {
      newNumbers[n] = true;
    });
    setSelectedBalls(newNumbers);
    handleFavDialogClose();
  };

  React.useEffect(() => {
    // TODO: disable this on desktop
    if (selectedEventsDates.length) {
      const now = moment().format('YYYY-MM-DD HH:mm:ss');
      const events = lottoEvents.filter((event: any) => event.system_id === systemId && event.event_date >= now);
      if (events.length) {
        // if there are selected dates, filter them to only include dates that are
        // after the first event date
        const event_date = events[0].event_date;
        const remaining = selectedEventsDates.filter((date) => date >= event_date);

        if (remaining.length !== selectedEventsDates.length) {
          if (remaining.length === 0) {
            remaining.push(event_date);
          }

          setSelectedEventDates(remaining);
        }
      }
    }
  }, [isDesktop, systemId, selectedEventsDates, lottoEvents]);

  const handleEvent = () => {
    setSelectedBalls({});
    setSelectedLuckyBet(null);
    setSelectedNextEvents(0);
    setSelectedEventDates([]);
    setDefaultEvent(systemId, eventId, lottoEvents);
  };

  const setDefaultEvent = (systemId: any, eventId: any, lottoEvents: any[]) => {
    if (systemId && eventId) {
      const now = moment().format('YYYY-MM-DD HH:mm:ss');

      let found = false;
      let events = lottoEvents;

      if (eventId) {
        events = lottoEvents.filter((event: any) => {
          if (event.event_id === eventId) {
            found = true;
          }
          return found;
        });
      }

      if (!found) {
        events = lottoEvents;
      }

      events = events.filter((event) => event.system_id === systemId && event.event_date >= now);

      if (events.length) {
        if (selectedEventsDates.length === 0) {
          setSelectedEventDates([events[0]?.event_date ?? '']);
        } else {
          const eventDatesHash: any = {};
          events.slice(0, 5).forEach((event) => {
            eventDatesHash[event.event_date] = true;
          });

          const remainingDates: any = [];
          selectedEventsDates.forEach((date) => {
            if (eventDatesHash[date]) {
              remainingDates.push(date);
            }
          });

          if (remainingDates.length) {
            setSelectedEventDates(remainingDates);
          } else {
            setSelectedEventDates([events[0]?.event_date ?? '']);
          }
        }
      }
    }
  };

  React.useEffect(() => {
    setDefaultEvent(systemId, eventId, lottoEvents);
  }, [systemId, eventId]);

  React.useEffect(() => {
    let defaultSelectedNextEvents = 0;
    let defaultSelectedEventsDates: string[] = [];
    if (lottoTicket?.src === 'betslip') {
      if (lottoTicket?.selectedEvents?.length) {
        defaultSelectedEventsDates = lottoTicket?.selectedEvents.map((event: any) => event.event_date);
      }
      if (lottoTicket?.additionalEvents != null && lottoTicket?.additionalEvents > 0) {
        defaultSelectedNextEvents = lottoTicket?.additionalEvents;
      }

      setSelectedEventDates(defaultSelectedEventsDates);
      setSelectedNextEvents(defaultSelectedNextEvents);
    }
  }, [bsTicket]);

  React.useEffect(() => {
    evBus.on('CLEAR-LOTTO-BETS', handleEvent);

    return () => {
      evBus.remove('CLEAR-LOTTO-BETS', handleEvent);
    };
  }, []);

  const contextValue = React.useMemo(() => {
    const now = moment().format('YYYY-MM-DD HH:mm:ss');
    const system = lottoSystems.find((s: any) => s.system_id === systemId);

    let found = false;
    let events = lottoEvents;

    if (eventId) {
      events = lottoEvents.filter((event: any) => {
        if (event.event_id === eventId) {
          found = true;
        }
        return found;
      });
    }

    if (!found) {
      events = lottoEvents;
    }
    events = events.filter((event: any) => event.system_id === systemId && event.event_date >= now);

    const event = events.length ? JSON.parse(JSON.stringify(events[0])) : null;

    const visibleEvents = events.splice(0, 5);
    const startDate = events[0]?.event_date ?? '';
    let endDate = events[events.length - 1]?.event_date ?? '';
    if (events.length > 45) {
      endDate = events[45].event_date;
    }

    return {
      system,
      visibleEvents: visibleEvents,
      remainingStartDate: startDate,
      remainingEndDate: endDate,
      totalRemaining: events.length > 45 ? 45 : events.length,
      selectedEventsDates,
      selectedNextEvents,
      totalDraws: selectedEventsDates?.length + selectedNextEvents ?? 0,
      onEventDateClick,
      onSelectedNextEventRange,
      n: event?.n ?? 0,
      k: event?.k ?? 0,
      m: event?.m ?? 0,
      r: event?.r ?? 0,
      eventName: event?.event_name ?? '',
      zoomed,
      onToggleZoom,
      eligibleLuckyNumber,
      bonuses,
      selectedBalls,
      onToggleBallNumber,
      selectedLuckyBet,
      showRandom,
      onToggleRandom,
      onToggleRandomBalls,
      lottoTicket,
      changeLuckyBet,
      onToggleChangeLuckyBet,
      isDesktop,
      handleFavDialogOpen,
      handleAddToFavorite,
    };
  }, [
    componentProps,
    lottoTicket,
    lottoEvents,
    lottoSystems,
    expiredLottoEvents,
    initialized,
    loading,
    bonuses,
    onEventDateClick,
    onSelectedNextEventRange,
    selectedEventsDates,
    selectedNextEvents,
    zoomed,
    onToggleZoom,
    selectedBalls,
    selectedLuckyBet,
    onToggleBallNumber,
    showRandom,
    onToggleRandom,
    onToggleRandomBalls,
    eligibleLuckyNumber,
    changeLuckyBet,
    onToggleChangeLuckyBet,
    isDesktop,
    handleFavDialogOpen,
    handleAddToFavorite,
    eventId,
  ]);

  //console.log('LottoEventDetails[contextValue]', contextValue);

  return (
    <ModuleElementDiv className={props.className ?? ''} $styleText={props.styleText}>
      <DataElementContext.Provider value={contextValue}>{children}</DataElementContext.Provider>
      {openFavDialog && event && (
        <FavNumbersDialog
          open={openFavDialog}
          eventName={event.event_name}
          onClose={handleFavDialogClose}
          onSelect={handleFavNumbersSelect}
        />
      )}
    </ModuleElementDiv>
  );
};

export default LottoEventDetails;

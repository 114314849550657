import React from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

import { useAppSelector, useAppDispatch } from '../../../../store';
import { DataElementContext } from '../../../../page-components/common/DataElementContext';
import { digitainConfig } from '@/modules/bets/api/config/digitain';
import { ticketOpenedListRequest } from '../../../../modules/bets/store/actions/tickets';
import {
  cashoutSubscribe,
  cashoutUnsubscribe,
  cashoutStartProcess,
  cashoutStopProcess,
  cashoutTicket,
  cashoutClearState,
} from '../../../../modules/bets/store/actions/cashout';
import { buildTicketLists } from '../utils/functions';

import './index.scss';

type BetsOpenedProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: {
    dsType: string;
  };
};

const defaultProps = {
  className: '',
  styleText: '',
  properties: {
    dsType: '',
  },
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const BetsOpened = (componentProps: BetsOpenedProps) => {
  const tmpProps = { ...defaultProps, ...componentProps };
  delete tmpProps.children;
  const props = JSON.parse(JSON.stringify(tmpProps));
  const { children } = componentProps;

  const dispatch = useAppDispatch();
  const opened = useAppSelector<any[]>((state) => state.bets.tickets.ticketsOpened);
  const inProgress = useAppSelector((state) => state.bets.tickets.requestInProgress.opened);
  const cashoutState = useAppSelector((state) => state.bets.cashout);
  const liveMatches = useAppSelector((state) => state.bets.live.matches);
  const [subscribeToTickets, setSubscribeToTickets] = React.useState<any[]>([]);
  const [extended, setExtended] = React.useState<any>({});
  const [localCashout, setLocalCashout] = React.useState<any>({
    show: false,
    amount: null,
    hash: null,
  });
  const [agree, setAgree] = React.useState(false);
  const { i18n, t } = useTranslation();
  const [isShareOpened, setIsShareOpened] = React.useState<any>({});
  const [shareStatus, setShareStatus] = React.useState<any>('default');
  const [canShare, setCanShare] = React.useState<boolean>(false);
  const socialCapabilities = useAppSelector((state) => state.social.flags?.social_capabilities);
  const authenticationToken = useAppSelector((state) => state.authentication.access_token);

  React.useEffect(() => {
    setCanShare(socialCapabilities?.includes('FEED_CAN_SHARE_SB_TICKET') ? true : false);
  }, [socialCapabilities]);

  const toggleAgree = () => {
    setAgree((v) => !v);
  };

  const onExtend = React.useCallback((e: React.MouseEvent<HTMLElement>) => {
    if (e.currentTarget.dataset.hash != null && e.currentTarget.dataset.hash !== '') {
      const hash = e.currentTarget.dataset.hash;
      setExtended((prevState: any) => ({
        ...prevState,
        [hash]: prevState[hash] != null ? !prevState[hash] : true,
      }));
    }
  }, []);

  const onCashout = React.useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      if (e.currentTarget.dataset.hash != null && e.currentTarget.dataset.hash !== '') {
        const hash = e.currentTarget.dataset.hash;
        console.log('onCashout: ', hash);

        const ticket = opened.find((t) => t.idHash === hash);
        if (ticket != null) {
          const tickets: any = cashoutState?.tickets ?? null;
          const cashout: any = tickets?.[hash];

          setLocalCashout((prevState: any) => ({
            ...prevState,
            show: true,
            amount: cashout?.cashoutAmountAfterTax,
            hash: hash,
          }));
        }
      }
    },
    [opened, cashoutState],
  );

  const hideCashout = React.useCallback((e: React.MouseEvent<HTMLElement>) => {
    dispatch(cashoutClearState());
    setLocalCashout({
      show: false,
      amount: null,
      hash: null,
    });
  }, []);

  const hideCashoutSuccess = React.useCallback((e: React.MouseEvent<HTMLElement>) => {
    dispatch(cashoutClearState());
    dispatch(ticketOpenedListRequest({ ticketsFetchMeta: true }));
    setLocalCashout({
      show: false,
      amount: null,
      hash: null,
    });
  }, []);

  const doCashout = React.useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      if (e.currentTarget.dataset.hash != null && e.currentTarget.dataset.hash !== '' && !cashoutState?.inProgress) {
        const hash = e.currentTarget.dataset.hash;
        console.log('onCashout: ', hash);

        const ticket = opened.find((t) => t.idHash === hash);

        if (ticket != null) {
          const tickets: any = cashoutState?.tickets ?? null;
          const cashout: any = tickets?.[hash];

          if (cashout?.available && cashout?.cashoutAmountAfterTax) {
            dispatch(
              cashoutTicket({
                provider: ticket.provider,
                ticket: hash,
                amount: cashout?.cashoutAmountAfterTax,
                isWinnerFun: false,
                ticketType: ticket.format,
                agreeChanges: agree,
              }),
            );
          }
        }
      }
    },
    [opened, cashoutState, agree],
  );

  React.useEffect(() => {
    dispatch(ticketOpenedListRequest({ ticketsFetchMeta: true }));
  }, []); //eslint-disable-line

  React.useEffect(() => {
    const toSubscribe: any[] = [];

    opened.forEach((t) => {
      if (t && t.format === 'live') {
        toSubscribe.push({ ticketHash: t.idHash, provider: t.provider });
      }
    });

    let isChanged = false;
    if (toSubscribe.length !== subscribeToTickets.length) {
      isChanged = true;
    } else {
      toSubscribe.forEach((t) => {
        if (!subscribeToTickets.find((s) => s.ticketHash === t.ticketHash)) {
          isChanged = true;
        }
      });
    }

    if (isChanged) {
      setSubscribeToTickets(toSubscribe);
    }
  }, [opened]);

  React.useEffect(() => {
    if (subscribeToTickets.length) {
      dispatch(cashoutStartProcess());
      dispatch(cashoutSubscribe({ tickets: subscribeToTickets, isWinnerFun: false, type: 'live' }));
    }

    return () => {
      if (subscribeToTickets.length) {
        dispatch(cashoutUnsubscribe({ tickets: subscribeToTickets, isWinnerFun: false, type: 'live' }));
        dispatch(cashoutStopProcess());
      }
    };
  }, [subscribeToTickets]);

  const toggleShareDialog = React.useCallback((e: React.MouseEvent<HTMLElement>) => {
    if (e.currentTarget.dataset.hash != null && e.currentTarget.dataset.hash !== '') {
      const hash = e.currentTarget.dataset.hash;
      setIsShareOpened((prevState: any) => {
        if (prevState[hash] != null) {
          return {};
        }
        return {
          ...prevState,
          [hash]: true,
        };
      });
    }
  }, []);

  React.useEffect(() => {
    if (Object.keys(isShareOpened).length === 0) {
      setShareStatus('default');
    }
  }, [isShareOpened]);

  const onStartShare = React.useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      if (e.currentTarget.dataset.hash != null && e.currentTarget.dataset.hash !== '' && authenticationToken) {
        const hash = e.currentTarget.dataset.hash;

        // TODO start sharing using ticket hash

        setShareStatus('pending');

        const shareTicket = async () => {
          const digitainConfigData = digitainConfig();

          const headers: any = {};
          if (authenticationToken) {
            headers['Authorization'] = `Bearer ${authenticationToken}`;
          }
          const postData = {
            ticket_number: hash,
            post_text: 'Share ticket', // TODO: add custom player message
          };

          let response;

          try {
            response = await axios.post(digitainConfigData.ticketsUrl + '/tickets/share', postData, {
              headers: headers,
            });
          } catch (e) {
            console.error('shareTicket[error]: ', e);
            setShareStatus('error');
            return;
          }

          if (response?.data?.success === true) {
            setShareStatus('success');
          } else {
            setShareStatus('error');
          }
        };
        shareTicket();
      }
    },
    [authenticationToken],
  );

  const onViewSocialFeed = React.useCallback((e: React.MouseEvent<HTMLElement>) => {
    if (e.currentTarget.dataset.hash != null && e.currentTarget.dataset.hash !== '') {
      const hash = e.currentTarget.dataset.hash;
    }
  }, []);

  const contextValue = React.useMemo(() => {
    let cashoutData: any = null;
    const cashoutTickets: any = cashoutState?.tickets ?? null;
    if (localCashout.show && localCashout.hash != null && cashoutTickets?.[localCashout.hash] != null) {
      cashoutData = cashoutTickets[localCashout.hash];
    }

    const tickets = buildTicketLists(opened, extended, i18n.language, t, false, cashoutState, liveMatches);

    tickets.forEach((t) => {
      t.isShareOpened = isShareOpened[t.hash] ?? false;
      t.toggleShareDialog = toggleShareDialog;
      t.shareStatus = shareStatus;
      t.onStartShare = onStartShare;
      t.onViewSocialFeed = onViewSocialFeed;
      t.canShare = canShare;
      //t.sharedTicket
    });

    const contextValue = {
      tickets: tickets,
      inProgress,
      onExtend,
      onCashout,
      showCashoutModal: localCashout.show,
      hideCashoutModal: hideCashout,
      hideCashoutSuccessModal: hideCashoutSuccess,
      cashoutHash: localCashout.hash,
      cashoutAmount: localCashout.amount,
      cashoutCurrency: 'Lei',
      cashoutInProgress: cashoutState?.inProgress ?? false,
      cashoutData: cashoutData,
      cashoutResult: cashoutState?.cashoutResult ?? null,
      doCashout,
      agree,
      toggleAgree,
    };

    return contextValue;
  }, [
    opened,
    cashoutState,
    localCashout,
    inProgress,
    extended,
    onExtend,
    onCashout,
    hideCashout,
    hideCashoutSuccess,
    doCashout,
    liveMatches,
    agree,
    toggleAgree,
    i18n.language,
    t,
    toggleShareDialog,
    isShareOpened,
    shareStatus,
    onStartShare,
    onViewSocialFeed,
    canShare,
  ]);

  console.log('BetsOpened[contextValue]: ', contextValue, isShareOpened, shareStatus);

  return (
    <ModuleElementDiv className={props.className ?? ''} $styleText={props.styleText}>
      <DataElementContext.Provider value={contextValue}>{children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default BetsOpened;
